import React from 'react';
import { useApp } from '../../hooks/AppContext';
import {
    SidebarContainer, Icon, CloseIcon,
    SidebarWrapper, SidebarMenu, SidebarLink, SidebarBtnWrap,
    CheckInBtn, BookingBtn
} from './SidebarItems';

const Sidebar = (props) => {
    const { setShowModal, setShowMdCheckIn, showSidebar, setShowSidebar } = props
    const { isActive, setIsActive } = useApp()

    const handleClickSidebarLink = (pageName) => {
        setShowSidebar(false)
        setIsActive({ ...isActive, pageName: pageName })
    }

    return (
        <SidebarContainer showSidebar={showSidebar}>    {/** onClick={setShowSidebar} */}
            <Icon onClick={() => setShowSidebar(false)}><CloseIcon /></Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/' onClick={() => handleClickSidebarLink('')}>Home</SidebarLink>
                    <SidebarLink to='/about' onClick={() => handleClickSidebarLink('about')}>About Us</SidebarLink>
                    <SidebarLink to='/services' onClick={() => handleClickSidebarLink('services')}>Services</SidebarLink>
                    <SidebarLink to='/gallery' onClick={() => handleClickSidebarLink('gallery')}>Gallery</SidebarLink>
                    <SidebarLink to='/rewards' onClick={() => handleClickSidebarLink('rewards')}>Rewards</SidebarLink>
                    <SidebarLink to='/contact' onClick={() => handleClickSidebarLink('contact')}>Contact Us</SidebarLink>
                </SidebarMenu>

                <SidebarBtnWrap>
                    <CheckInBtn onClick={() => {
                        setShowSidebar(false)
                        setShowMdCheckIn(true)
                    }}>
                        Check in
                    </CheckInBtn>
                    <BookingBtn onClick={() => {
                        setShowSidebar(false)
                        setShowModal(true)
                    }}>
                        Book now
                    </BookingBtn>
                </SidebarBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
}

export default Sidebar;