import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 300px;
    background-color: #c0c0c0;
    position: relative;
`
export const CoverImg = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    opacity: 0.65;
`
export const Title = styled.h1`
    color: white;
    font-size: 65px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    text-align: center;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);

    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    
    @media screen and (max-width: 480px) {
        font-size: 52px;
    }
`