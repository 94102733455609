import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button } from 'primereact/button';
import { Wrapper, Header, Title, CloseButton, Body, Footer, NextButton, ConfirmButton, LoadingIcon } from './MainPanelItems';
import { Step1DateTime, Step2Services, Step3Technicians, Step4CustomerInfo, Step5Confirm, Step6Success } from '../steps';
import { useBooking } from '../BookingContext';
import { bookAppointment } from '../../../services/BookingApi';

let renderTime = 0

const MainPanel = (props) => {
    const { setShowModal } = props
    const { step, setStep, preStep, setPreStep, validStep, setValidStep,
        appointment, setAppointment } = useBooking()
    const [isSuccessful, setIsSuccessful] = useState(false);
    const selectedTime = appointment?.dateTime

    const animation = useSpring({
        config: { duration: 150 },
        opacity: selectedTime ? 1 : 0,  // animation chỉ hiện khi ở step đầu tiên
        transform: selectedTime ? 'translateY(0%)' : 'translateY(50%)'
    })

    const currentStep = useRef(step)

    useEffect(() => {
        if (currentStep.current !== step) {
            renderTime++;
            currentStep.current = step
        }
    }, [step]);

    const closeAndResetModal = () => {
        setShowModal(false)
        setAppointment({})
        setStep(1)
    }

    const showStep = (step) => {
        switch (step) {
            case 1:
                return <Step1DateTime />
            case 2:
                return <Step2Services />
            case 3:
                return <Step3Technicians />
            case 4:
                return <Step4CustomerInfo />
            case 5:
                return <Step5Confirm />
            case 6:
                return <Step6Success isSuccessful={isSuccessful} />

            default:
                break;
        }
    }

    const showTitleByStep = () => {
        switch (step) {
            case 1:
                return `When can we meet?`;
            // return `Please pick a date ${appointment?.date ? 'and time' : ''}`;
            case 2:
                return `What services you want to have?`;
            case 3:
                return `Pick a technician`;
            // return `Pick your favorite technician`;
            // return `Additional requirements`;
            case 4:
                return `How can we say hello? (^_^)`;
            // return `Please fill your contact info`;
            case 5:
                return `Last step =D`;

            default:
                break;
        }
    }

    const sendBookingRequest = () => {
        // All info of the appointment
        // console.log('Book the appointment:', appointment);
        // setLoading(true)

        const appointmentData = {
            name: appointment?.name,
            phone: appointment?.phone,
            email: appointment?.email,
            time: appointment?.time,
            options: appointment?.options,
            technicianId: appointment?.technician?.id,
            note: appointment?.note
        }

        // console.log('Guest - time: ', appointment?.time);

        bookAppointment(appointmentData).then(res => {
            // console.log('book xong goi, kq la ', res);
            setIsSuccessful(true)
            // setLoading(false)
        }).catch((error) => {
            if (error.response) {
                console.log('Error at Booking step 5 - sendBookingRequest.')
                // history.push({
                //     pathname: '/errors',
                //     state: { error: error.response.status },
                // })
            }
        })
        setStep(6)
        setAppointment({})
    }

    const showFooterByStep = (step) => {
        switch (step) {
            case 1:
                // Điều kiện hiện btnNext ở bước 1 cũng chính là điều kiện để trigger animation rồi nên ở đây ko cần
                return (
                    <>
                        <div></div>
                        <NextButton label="Next" icon="pi pi-angle-double-right" iconPos="right"
                            onClick={() => {
                                setStep(2)
                                setPreStep(1)
                                if (validStep <= 2) {
                                    setValidStep(2)
                                }
                            }} />
                    </>
                )
            case 2:
                return (
                    <>
                        <Button label="Back" icon="pi pi-angle-double-left" className="p-button-text p-button-secondary"
                            onClick={() => {
                                setStep(1)
                                setPreStep(2)
                            }} />
                        {/** Điều kiện hiện btnNext */}
                        {appointment?.serviceGroups?.length > 0 &&
                            <NextButton label="Next" icon="pi pi-angle-double-right" iconPos="right"
                                onClick={() => {
                                    setStep(3)
                                    setPreStep(2)
                                    if (validStep <= 3) {
                                        setValidStep(3)
                                    }
                                }} />
                        }
                    </>
                )
            case 3:
                return (
                    <>
                        <Button label="Back" icon="pi pi-angle-double-left" className="p-button-text p-button-secondary"
                            onClick={() => {
                                setStep(2)
                                setPreStep(3)
                            }} />
                        {/** Step này optional nên ko có điều kiện hiện btnNext */}
                        <NextButton label="Next" icon="pi pi-angle-double-right" iconPos="right"
                            onClick={() => {
                                setStep(4)
                                setPreStep(3)
                                if (validStep <= 4) {
                                    setValidStep(4)
                                }
                            }} />
                    </>
                )
            case 4:
                return (
                    <>
                        <Button label="Back" icon="pi pi-angle-double-left" className="p-button-text p-button-secondary"
                            onClick={() => {
                                setStep(3)
                                setPreStep(4)
                            }} />
                        {/** Điều kiện hiện btnNext */}
                        {/* {console.log('main panel - step 4: appointment?.email = ', appointment?.email)} */}
                        {appointment?.name && appointment?.phone && (appointment?.email !== null) &&
                            < NextButton label="Next" icon="pi pi-angle-double-right" iconPos="right"
                                onClick={() => {
                                    setStep(5)
                                    setPreStep(4)
                                    if (validStep <= 5) {
                                        setValidStep(5)
                                    }
                                }} />
                        }
                    </>
                )
            case 5:
                return (
                    <>
                        <Button label="Back" icon="pi pi-angle-double-left" className="p-button-text p-button-secondary"
                            onClick={() => {
                                setStep(4)
                                setPreStep(5)
                            }} />
                        {appointment?.serviceGroups?.length > 0 ? (
                            <ConfirmButton label="Confirm &amp; Book" icon="pi pi-check" onClick={sendBookingRequest} />
                        ) : (
                            <Button label="Back to Services" className="p-button-text p-button-secondary"
                                icon="pi pi-arrow-left" onClick={() => {
                                    setStep(2)
                                    setPreStep(5)
                                }} />
                        )}
                    </>
                )
            case 6:
                return (
                    <>
                        <div></div>
                        <Button label="Close" onClick={closeAndResetModal} className="p-button-secondary" />
                    </>
                )

            default:
                break;
        }
    }

    // useEffect(() => {
    //     if (loading === false) {
    //         setStep(6)
    //     }
    // }, [loading])

    // if (loading) {
    //     return <LoadingIcon className="pi pi-spin pi-spinner" />
    // }

    // console.log(preStep, '-', step, preStep !== step);

    return (
        <Wrapper step={step}>
            <Header>
                <Title key={renderTime} isChanged={preStep !== step}>
                    {showTitleByStep()}
                </Title>
                <CloseButton onClick={closeAndResetModal} />
            </Header>
            <Body dateTime={selectedTime}>
                {showStep(step)}
            </Body>
            <animated.div style={animation}>
                <Footer>
                    {showFooterByStep(step)}
                </Footer>
            </animated.div>
        </Wrapper>
    );
}

export default MainPanel;