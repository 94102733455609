import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'

import { FaRegSmileWink } from 'react-icons/fa'
import { classNames } from 'primereact/utils'
import { InputText } from "primereact/inputtext"
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
// import { CascadeSelect } from 'primereact/cascadeselect'

import {
  FormContainer, NoticeMsg, FormWrapper,
  ButtonWrapper, BtnNext
} from './FormNewCustomerInfoItems'
import { getCustomerDetail, requestCheckIn } from '../../../../services/CheckInApi'
import { useCheckIn } from '../../hooks/CheckInContext'
import { parseDateToString } from '../../../../utils/DateTime'

const DatePicker = (props) => {
  const { id, value, onChange, field } = props

  const monthNavigator = (e) => (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      style={{ lineHeight: 1 }} />
  )
  const yearNavigator = (e) => (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      className="p-ml-2"
      style={{ lineHeight: 1 }} />
  )

  const thisYear = moment().year()

  return (
    <Calendar id={id} {...field}
      value={value}
      onChange={onChange}
      dateFormat="M d, yy"
      // placeholder="mm/dd/yy"
      readOnlyInput
      showOtherMonths
      monthNavigator yearNavigator
      yearRange={`1900:${thisYear}`}
      yearNavigatorTemplate={yearNavigator}
      monthNavigatorTemplate={monthNavigator}
      className="p-column-filter"
    // onClearButtonClick={onDateRangeFilterClear}
    />
  )
}

// const AddressPicker = (id, field) => {
//     // Định dùng CascadeSelect để cho chọn State name & City name
//     // Chỉ cần 2 cái này thôi để làm thống kê
// }

function FormNewCustomerInfo({ style }) {
  const {
    setStep, checkInData, onChangeCheckInData,
    setMessage, setCustomerInfo
  } = useCheckIn()
  const { email, birthdate, address } = checkInData

  const [loading, setLoading] = useState(false)

  const defaultValues = { email, birthdate, address }

  const { control, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  })

  const handleRequestCheckIn = () => {
    setLoading(true)

    const data = {
      ...checkInData,
      email: email || null,
      birthdate: birthdate ? parseDateToString(birthdate, 'YYYY-MM-DD') : null,
      address: address || null,
      spa: 'deluxe'
    }

    requestCheckIn(data).then(resCheckIn => {
      getCustomerDetail(checkInData?.phone).then(resDetail => {
        const customerObj = resDetail?.list?.[0]

        setCustomerInfo({
          ...resCheckIn,
          currentPoints: +customerObj?.totalPoint - +customerObj?.discount
          // currentPoints: calSumCurrentPoints(resDetail)
        })
      })
    })
      .catch(err => {
        if (err?.message?.includes('status code 400')) {
          setMessage(
            'You had requested already. Please wait until our staff approved your request.'
          )
        }
        else {
          setMessage('Something went wrong! Please try again later :(')
          console.log('Error at handleRequestCheckIn() in Rewards: ', JSON.parse(JSON.stringify(err)))
        }
      })
      .finally(() => {
        reset()
        setStep(3)
        setLoading(false)
      })
  }

  const getFormErrorMessage = fieldName => {
    return errors[fieldName] && (
      <small className="p-error" style={{ marginLeft: '2.85rem' }}>
        {errors[fieldName].message}
      </small>
    )
  }

  return (
    <FormContainer style={style}>
      <NoticeMsg>
        If you don't mind, please tell us some more information about you!
        <FaRegSmileWink style={{ marginLeft: 6, marginTop: 4, fontSize: 20 }} />
        {/* We will really happy if you pleased to provide additional information */}
      </NoticeMsg>

      <FormWrapper onSubmit={event => event.preventDefault()}>
        {/* Email */}
        <div className="p-field p-col-12 reward-form__email">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-envelope" />
            </span>
            <span className="p-float-label">
              <Controller name="email" control={control}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address. E.g. example@email.com'
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field}
                    value={email}
                    onChange={(e) => onChangeCheckInData('email', e?.target?.value)}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  // onBlur={e => handleBlurEmail(e, fieldState.invalid)}
                  />
                )} />
              <label htmlFor="email"
                className={classNames({ 'p-error': !!errors.email })}
              >Your Email (optional)
              </label>
            </span>
          </div>
          {getFormErrorMessage('email')}
        </div>

        {/* DoB */}
        <div className="p-field p-col-12">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-calendar" />
            </span>
            <span className="p-float-label">
              <Controller name="birthdate" control={control} render={({ field }) => (
                <DatePicker id={field.name} field={field}
                  value={birthdate}
                  onChange={(e) => onChangeCheckInData('birthdate', e?.target?.value)}
                />
              )} />
              <label htmlFor="birthdate">Your DoB (optional)</label>
            </span>
          </div>
        </div>

        {/* Address */}
        {/* <div className="p-field p-col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker" />
                        </span>
                        <span className="p-float-label">
                            <Controller name="address" control={control} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    value={address}
                                    onChange={(e) => onChangeCheckInData('address', e?.target?.value)}
                                />
                            )} />
                            <label htmlFor="address">Your Address (optional)</label>
                        </span>
                    </div>
                </div> */}
      </FormWrapper>

      <ButtonWrapper>
        <BtnNext id='btn-send'
          // type='submit'
          label='Check in'
          onClick={handleRequestCheckIn}
          loading={loading}
        />
      </ButtonWrapper>
    </FormContainer>
  )
}

export default FormNewCustomerInfo