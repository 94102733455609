import React, { useEffect, useState } from 'react';
import { Wrapper, LoadingIcon, TechnicianWrapper, Technicians, CardTechnician, TechnicianAvatar, TechnicianName } from './Step3TechniciansItems';
import { useBooking } from '../BookingContext';
// import { getTechnicians } from '../../../services/BookingApi';   // tạm thời khóa lại chưa dùng tới, ko xóa
import { NoImage } from '../../../assets/images'

const Step3Technicians = () => {
    const { appointment, setAppointment, technicians } = useBooking()   //, setTechnicians

    const [selectedStaff, setSelectedStaff] = useState(appointment?.technician ? appointment?.technician : technicians[0]);

    // let loading = true
    // const loadTechnicians = () => {
    //     getTechnicians().then(res => {
    //         if (loading) {
    //             setTechnicians([...technicians, ...res])
    //         }
    //     }).catch(error => {
    //         if (error.response)
    //             console.log('Error at Booking step 3 - getTechnicians.');
    //     })
    // }
    useEffect(() => {
        // if (technicians?.length < 2) {
        //     loadTechnicians()
        // }
        setAppointment({ ...appointment, technician: selectedStaff })
        // return () => {
        //     loading = false
        // };
    }, []);

    // if (technicians?.length < 2) {
    //     return <LoadingIcon className="pi pi-spin pi-spinner" />
    // }

    // const resizeImg = function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    //     var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    //     return { width: srcWidth * ratio, height: srcHeight * ratio };
    // }

    const handleTechnicianChange = (staff) => {
        setSelectedStaff(staff)
        setAppointment({ ...appointment, technician: staff })
    }
    // console.log('step 3 - appointment: ', appointment);

    return (
        <Wrapper>
            <TechnicianWrapper>
                <h5>
                    Do you require a particular technician? <i style={{ fontWeight: 400, color: 'gray' }}>(optional)</i>
                </h5>
                <Technicians>
                    {technicians?.map(staff => (
                        <CardTechnician
                            key={staff?.id}
                            onClick={() => handleTechnicianChange(staff)}
                            selected={(appointment?.technician === staff?.id) || (selectedStaff?.id === staff?.id ? true : false)}
                        >
                            <TechnicianAvatar src={staff?.avatar} onError={(e) => e.target.src = NoImage} alt={`${staff?.name} avatar`} />
                            <TechnicianName>{staff?.name}</TechnicianName>
                        </CardTechnician>
                    ))}
                    {technicians.length % 3 === 1 && (
                        <>
                            <div style={{ width: '120px', height: '140px' }}></div>
                            <div style={{ width: '120px', height: '140px' }}></div>
                        </>
                    )}
                    {technicians.length % 3 === 2 && (
                        <div style={{ width: '120px', height: '140px' }}></div>
                    )}
                </Technicians>
            </TechnicianWrapper>
            {/* <div id="note" style={{ marginTop: '0.75rem' }}>
                <h5 style={{ margin: '0.5rem 0 0 0.5rem' }}>
                    Other requirements <i style={{ fontWeight: 400, color: 'gray' }}>(optional)</i>
                </h5>
                <InputTextarea value={note} onChange={handleNoteChange} rows={7} cols={30}
                    style={{ margin: '0.5rem', width: '97%' }}
                />
            </div> */}
        </Wrapper>
    );
}

export default Step3Technicians;
