// Remove an object in array of object based on its attribute name and value
export function removeItem(array, attributeName, attributeValue) {
    let index = array?.length;
    while (index--) {
        if (array[index]
            && array[index]?.hasOwnProperty(attributeName)
            && (arguments?.length > 2 && array[index][attributeName] === attributeValue)) {
            array.splice(index, 1);
        }
    }
    return array;
}

// 2 object có nhất thiết giống số property ko hay 1 obj có thể có hoặc ko có properties trong obj còn lại cũng đc?
export function isExisted(array, item) {
    // Chú ý: Ở đây PHẢI dùng for thường thì tìm đc còn nếu là for each thì lại ko đc.
    // Chưa rõ lắm cơ chế của for each và dấu ===.
    for (let i = 0; i < array?.length; i++) {
        if (array[i] === item) {
            // console.log('found!!!');
            return true
        }
    }
    return false
}

export function chunkArray(bigArray, chunkSize) {
    let newArray = [];
    for (let i = 0; i < bigArray?.length; i += chunkSize) {
        newArray.push(bigArray?.slice(i, i + chunkSize));
    }
    return newArray;
}

export const sortArrByNumber = (arr) => {
    arr.sort((a, b) => a?.value - b?.value);  // ascending order: ;
    return arr;     // b.age - a.age    // descending
}

// Resources: https://www.javascripttutorial.net/array/javascript-sort-an-array-of-objects/
//            https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/

export const calSumCurrentPoints = cusHistory => {
    const arrPoints = cusHistory?.map(item => item?.reward || 0)    
    return arrPoints?.reduce((sum, point) => sum + point, 0)
}