import styled from 'styled-components'

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
`

export const Row = styled.div`
    width: 100%;
    min-height: 540px;
    margin-top: 3.5rem;
    
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1200px) {
        padding: 0 1.5rem;
    }
    @media screen and (max-width: 900px) {
        margin-top: 2.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
`

export const ColForm = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    padding-right: 4rem;

    @media screen and (max-width: 1100px) {
        padding-right: 3rem;
    }
    @media screen and (max-width: 1024px) {
        padding-right: 2rem;
    }
    @media screen and (max-width: 900px) {
        max-width: 600px;
        padding: 0;
    }
`
export const SubTitle = styled.div`
    font-size: 1.15rem;

    p {
        font-size: 1.15rem;
        line-height: 1.5rem;

        &:first-child {
            margin-bottom: 0.8rem;
        }
    }

    @media screen and (max-width: 1100px) {
        p { 
            font-size: 1rem;
            
            &:first-child {
                margin-bottom: 0.25rem;
            }
        }
    }
`
export const FormWrapper = styled.form`
    width: 100%;
    margin-top: 1.5rem;
    padding: 0;

    .p-field:not(.content-textarea) {
        /* width: 470px; */
        height: 50px;

        span.p-float-label, input.p-inputtext {
            min-height: 40px;
        }
    }

    @media screen and (max-width: 900px) {
        .p-field.content-textarea span textarea {
            height: 145px;
        }
    }
`
export const Row1 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 5fr;

    & > div:last-child {
        margin-left: 2.5rem;
    }

    @media screen and (max-width: 1200px) {
        & > div:last-child {
            margin-left: 2rem;
        }
    }
    @media screen and (max-width: 1024px) {
        & > div:last-child {
            margin-left: 1.5rem;
        }
    }
    @media screen and (max-width: 960px) {
        & > div:last-child {
            margin-left: 1rem;
        }
    }
    @media screen and (max-width: 900px) {
        & > div:last-child {
            margin-left: 2.5rem;
        }
    }
    @media screen and (max-width: 576px) {
        & > div:last-child {
            margin-left: 2rem;
        }
    }
    @media screen and (max-width: 480px) {
        & > div:last-child {
            margin-left: 1.5rem;
        }
    }
    @media screen and (max-width: 440px) {
        display: flex;
        flex-flow: column;
        
        & > div:last-child {
            margin-left: 0;
        }
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    @media screen and (max-width: 1100px) {
        margin-top: 1rem;
    }
    @media screen and (max-width: 900px) {
        margin-top: 1.5rem;
    }
`
export const BtnSend = styled.button`
    color: #171416;
    font-size: 18px;
    font-weight: 500;
    background: #FFC09F;
    
    padding: 12px 30px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        max-width: 480px;
    }
`
export const Message = styled.p`
    font-size: 0.95rem;
    font-style: italic;
    color: ${({ isSuccess }) => (isSuccess ? '#01A601' : '#d32f2f')};
    margin-left: 1rem;
`

export const ColMap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    @media screen and (max-width: 900px) {
        max-width: 600px;
        padding: 0;
    }
`
export const MapWrapper = styled.div`
    width: 600px;
    height: 450px;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    padding: 0;

    iframe {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 3px;
    }

    @media screen and (max-width: 1200px) {
        width: 560px;
        height: 420px;
    }
    @media screen and (max-width: 1024px) {
        width: 500px;
        height: 375px;
    }
    @media screen and (max-width: 900px) {
        width: 100%;
        height: 450px;
        margin: 2rem 0 60px;
    }
`