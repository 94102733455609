import React from 'react';
import { Divider } from 'primereact/divider';
import { Wrapper, Header, Title, Body, Row, SubHeader, Content, ServicesTable, ServiceRow, ServiceInfo, ServiceName, ServiceFlavor, RemoveBtn } from './PreviewPanelItems';
import { useBooking } from '../BookingContext';
import { removeItem } from '../../../utils/Arrays';
import { parseDateToString, getUTCTimeZone } from '../../../utils/DateTime';

const PreviewPanel = () => {
    const { appointment, setAppointment } = useBooking()

    // console.log('Preview: ', appointment);

    // Cần coi lại vì đã sửa cấu trúc của appointment?.services
    const handleRemoveService = (optionId, serviceId, groupId) => {
        let selectedGroups = appointment?.serviceGroups ? [...appointment?.serviceGroups] : []
        let removedGroup = selectedGroups?.find(item => item?.id === groupId)

        let selectedServices = removedGroup?.services ? [...removedGroup?.services] : []
        let removedService = selectedServices?.find(item => item?.id === serviceId)

        let selectedOptions = removedService?.options ? [...removedService?.options] : []

        let indexGroup = selectedGroups.findIndex(item => item?.id === groupId)
        let indexServices = selectedServices.findIndex(item => item?.id === serviceId)

        selectedOptions = [...removeItem(selectedOptions, 'id', optionId)]

        selectedGroups[indexGroup].services[indexServices] = {
            ...selectedGroups[indexGroup]?.services[indexServices],
            options: selectedOptions
        }

        if (selectedOptions.length === 0) {
            selectedGroups[indexGroup].services = [...removeItem(selectedServices, 'id', serviceId)]
            if (selectedGroups[indexGroup].services.length === 0) {
                selectedGroups = [...removeItem(selectedGroups, 'id', groupId)]
            }
        }

        setAppointment({ ...appointment, serviceGroups: selectedGroups })
    }

    const getOptions = (groupId, serviceId, optionName) => {
        if ([1, 2].includes(groupId)) {
            if ([1, 2, 3, 4, 8, 9, 10, 11].includes(serviceId)) {
                return optionName
            }
        }
        return null
    }

    return (
        <Wrapper>
            <Header>
                <Title>Preview —</Title>
            </Header>
            <Body>
                {appointment?.date && (
                    <>
                        <Row>
                            <div>
                                <SubHeader>Date</SubHeader>
                                <Content>{parseDateToString(appointment?.date, 'MMM D, YYYY')}</Content>
                            </div>
                            {appointment?.dateTime && (
                                <div>
                                    <SubHeader>Time</SubHeader>
                                    <Content>
                                        {appointment?.dateTime}
                                        {/* <span style={{ fontSize: '0.9rem', }}> ({getUTCTimeZone(appointment?.date)})</span> */}
                                        <span style={{ fontSize: '0.9rem', }}> ({getUTCTimeZone(appointment?.date)})</span>
                                    </Content>
                                </div>
                            )}
                        </Row>
                        <Divider type="dotted" />
                        {appointment?.serviceGroups && (
                            <ServicesTable>
                                <SubHeader>Selected services</SubHeader>
                                {appointment?.serviceGroups?.map(group => (
                                    <div key={group?.id}>
                                        {group?.services?.map(service => (
                                            <ServiceRow key={service?.id}>
                                                {service?.options?.map(option => (
                                                    <ServiceInfo key={option?.id}>
                                                        <div>
                                                            <ServiceName serviceId={service?.id} standAlone={getOptions(group?.id, service?.id, option?.name) === null}>
                                                                {service?.name}
                                                            </ServiceName>
                                                            {getOptions(group?.id, service?.id, option?.name) &&
                                                                <ServiceFlavor>
                                                                    <span style={{ fontSize: '0.8rem', fontWeight: 600 }}>Flavor: </span>
                                                                    {getOptions(group?.id, service?.id, option?.name)}
                                                                </ServiceFlavor>
                                                            }
                                                        </div>
                                                        <RemoveBtn icon="pi pi-trash"
                                                            onClick={() => handleRemoveService(option?.id, service?.id, group?.id)}
                                                            className="p-button-sm p-button-rounded p-button-secondary p-button-text"
                                                        />
                                                    </ServiceInfo>
                                                ))}
                                            </ServiceRow>
                                        ))}
                                    </div>
                                ))}
                            </ServicesTable>
                        )}
                    </>
                )}
            </Body>
        </Wrapper>
    );
}

export default PreviewPanel;
