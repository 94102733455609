import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    /* padding: 0.5rem 0 0 0.5rem; */
`

export const LoadingIcon = styled.i`
    font-size: 4rem;
    color: gray;
    margin-top: 1.5rem;
`

export const TechnicianWrapper = styled.div`
    width: 100%;
    padding: 0.5rem;
`
export const Technicians = styled.div`
    width: 100%;
    margin-top: 0.6rem;
    padding: 0 1rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
        margin-top: 0.75rem;
        padding: 0;
    }
`
export const CardTechnician = styled.div`
    width: 120px;
    height: 140px;
    margin-bottom: 1.25rem;
    padding-top: 0.8rem;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    border: 2px solid #d0d0d0;
    border-radius: 3px;
    cursor: pointer;

    background-color: ${({ selected }) => selected && '#FFC09F50'};
    border: ${({ selected }) => selected && '3px solid #FFC09F'};
    p { font-weight: ${({ selected }) => selected && '600'};}

    &:hover {
        background-color: #FFC09F50;
        border-color: #FFC09F;

        p { font-weight: 600 }
    }
    
    @media screen and (max-width: 480px) {
        margin-bottom: 0.75rem;
    }
`
export const TechnicianAvatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-bottom: 0.5rem;
    box-shadow: 0px 3px 5px #a0a0a0;
    object-fit: cover;
`
export const TechnicianName = styled.p`
    text-align: center;
    text-transform: capitalize;
`