import { PedicuresImg, ManicuresImg, ArtificialNailsImg, GelNailsImg2, WaxingImg, KidsMenuImg } from '../../assets/images'

export const _checkExistedInArray = (array, searchItem) => {
    return array.some(item => item === searchItem)
}

export const _changeItemPositionInArray = (array, searchItem, toIndex) => {
    const fromIndex = array.findIndex(item => item === searchItem)
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, searchItem)
    return array
}

export const _getOptions = (options, servicename) => {
    let newOptions = []
    let optionName = ''

    options?.map(option => {
        optionName = option?.name

        if (optionName !== servicename) {
            switch (optionName) {
                case 'Lavender & Mint':
                    optionName = 'Lavender & Mint Relaxation'
                    break;
                case 'Green Tea':
                    optionName = 'Green Tea Passion'
                    break;
                case 'Aloe Vera':
                    optionName = 'Aloe Vera Relief'
                    break;
                case 'Peppermint':
                    optionName = 'Peppermint Inspirit'
                    break;
                case 'Pearl':
                    optionName = 'Pearl of Wisdom'
                    break;
                case 'Milk & Honey':
                    optionName = 'Milk & Honey Rejuvenation'
                    break;
                case 'Cucumber':
                    optionName = 'Cucumber Dream'
                    break;
                case 'Citrus':
                    optionName = 'Citrus Sensation'
                    break;

                default:
                    break;
            }

            newOptions.push({
                id: option?.id,
                name: optionName
            })
        }
    })

    return newOptions
}

export const _optimiseMenu = (originalMenu) => {
    let newMenu = []
    let newService = null
    let newOptions = []
    let groupNames = []

    originalMenu.map(service => {
        // console.log('service: ', service?.groupName, service?.name);
        newOptions = []
        newOptions = _getOptions(service?.list, service?.name)

        newService = {
            id: service?.id,
            groupName: service?.groupName,
            name: service?.name,
            description: service?.description,
            note: service?.note,
            price: service?.price,
            isFixedPrice: service?.fixedPrice,
            listOptions: newOptions
        }
        newMenu.push(newService)

        if (!_checkExistedInArray(groupNames, service?.groupName)) {
            groupNames.push(service?.groupName)
        }
    })

    // Cái này hơi cheat
    groupNames = _changeItemPositionInArray(groupNames, 'Gel Nails', 3)

    return { newMenu, groupNames }
}

export const _getServicesByGroup = (menu, groupName) => {
    return menu?.filter(service => service?.groupName === groupName)
}

export const refractorMenu = (originalMenu) => {
    const optimisedMenu = [..._optimiseMenu(originalMenu)?.newMenu]
    const groupNames = [..._optimiseMenu(originalMenu)?.groupNames]
    const imgSrcs = [PedicuresImg, ManicuresImg, ArtificialNailsImg, GelNailsImg2, WaxingImg, KidsMenuImg]
    let newMenu = []

    let i = 1
    groupNames.map(groupName => {
        newMenu.push({
            id: i,
            name: groupName,
            imgSrc: imgSrcs[i - 1],     // này để tạm đây, sau này thêm vô DB sau
            listServices: _getServicesByGroup(optimisedMenu, groupName)
        })
        i++
    })

    console.log('---------------------newMenu---------------------', newMenu);

    return newMenu
}