import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'
import { Helmet } from "react-helmet";
import Layout from '../../layout/Layout';
import { ServicesCoverImg } from '../../assets/images'
import {
    LoadingIcon, BodyWrapper, RowOdd, RowEven, Col, Title,
    ImgRight, BackgroundRight, ImgLeft, BackgroundLeft, ImgSmall, BackgroundSmall,
    ListServices, ServiceWrapper, Part1, Name, NoteTag, Note, Price, Part2, Description, DescriptionPart, Flavors
} from './ServicesItems'
import { getServicesMenu } from '../../services/ServiceApi'
import useResponsive from '../../hooks/useResponsive'
import { refractorMenu } from './prepareData'
import { trimStr } from '../../utils/String';

function Services() {
    const { hash } = useLocation()
    const [isMobileScreen] = useResponsive()
    const [menu, setMenu] = useState(JSON.parse(sessionStorage.getItem('menu')) || null)

    let loading = true
    const getMenu = () => {
        getServicesMenu().then(res => {
            if (loading) {
                const newMenu = refractorMenu(res)
                setMenu(newMenu)
                sessionStorage.setItem('menu', JSON.stringify(newMenu))
                // Lưu session để có thể gọi lại đc API chứ ở localstorage nó cứng ngắc òi
                // localStorage.setItem('menu', JSON.stringify(newMenu))
            }
        }).catch(error => {
            console.log('Error at Services Page: ', error);
        })
    }

    useEffect(() => {
        if (!menu) getMenu()

        if (!hash) window.scrollTo(0, 0)
        else
            scroller.scrollTo(hash.substring(1), {
                duration: 1000,
                delay: 100,
                smooth: true,
                offset: -60,
            })

        return () => {
            loading = false
        }
    }, [])

    const renderDescription = (des) => {
        let parts = des?.split(' \\n')

        if (parts?.length > 1) {
            return <Description>
                {parts?.map((part, index) => (
                    <DescriptionPart className={index === 0 ? 'des-first-part' : ''}>
                        {part}
                    </DescriptionPart>
                ))}
            </Description>
        }

        return <Description>
            {parts?.map(part => (
                <DescriptionPart>{part}</DescriptionPart>
            ))}
        </Description>
    }

    const TimeTag = ({ time, className }) => {
        if (!time) return <></>

        return (
            <NoteTag className={className}>
                <Note className={className}>
                    <i className="pi pi-clock" /> {time}
                </Note>
            </NoteTag>
        )
    }

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>Services | Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="Services Menu of Deluxe Nails & Spa | (972) 304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019 | Services: Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and more!" />
                <meta name="keywords" content="Deluxe, Nails, Spa, Beauty, Nails Salon, Coppell, TX 75019, Texas, Pedicures, Manicure, Acrylic, Gel, Waxing, price" />
                <link rel="canonical" href="https://deluxenailsspacoppell.com/" />
            </Helmet>

            <Layout title="Our Services" coverImgSrc={ServicesCoverImg} isNotFound={false}>
                {menu ? (
                    <BodyWrapper>
                        {menu?.map(group =>
                            (group?.id % 2 !== 0) ? (
                                <RowOdd key={group?.id} id={group?.id} name={trimStr(group?.name)}>
                                    <Col>
                                        <Title>{group?.name}</Title>
                                        <BackgroundSmall />
                                        <ImgSmall src={group?.imgSrc} alt={group?.name} />
                                        <ListServices isArtNails={group?.name?.includes('Artificial Nails')}>
                                            {group?.listServices?.map(service => (
                                                <ServiceWrapper key={service?.id}>
                                                    <Part1>
                                                        <Name>{service?.name}</Name>
                                                        {!isMobileScreen && <TimeTag time={service?.note} />}
                                                        <Price className='odd-row'>
                                                            &#36;{service?.price} {!service?.isFixedPrice && '& up'}
                                                        </Price>
                                                    </Part1>
                                                    {isMobileScreen &&
                                                        <TimeTag time={service?.note} className='outside' />
                                                    }
                                                    <Part2 className='odd-row'>
                                                        {service?.description &&
                                                            renderDescription(service?.description)}

                                                        {service?.listOptions && service?.listOptions?.length > 0 && (
                                                            <Flavors>
                                                                <span>With a scent of your choice:</span>
                                                                <br />
                                                                <ul>
                                                                    {service.listOptions?.map(option => <li>{option?.name}</li>)}
                                                                </ul>
                                                            </Flavors>
                                                        )}
                                                    </Part2>
                                                </ServiceWrapper>
                                            ))}
                                        </ListServices>
                                    </Col>
                                    <Col>
                                        <BackgroundRight />
                                        <ImgRight src={group?.imgSrc} alt={group?.name} />
                                    </Col>
                                </RowOdd>
                            ) : (
                                <RowEven key={group?.id} id={group?.id} name={trimStr(group?.name)}>
                                    <Col>
                                        <BackgroundLeft />
                                        <ImgLeft src={group?.imgSrc} alt={group?.name} />
                                    </Col>
                                    <Col>
                                        <Title className='even-row'>{group?.name}</Title>
                                        <BackgroundSmall />
                                        <ImgSmall isEven src={group?.imgSrc} alt={group?.name} />
                                        <ListServices isEven>
                                            {group?.listServices?.map(service => (
                                                <ServiceWrapper key={service?.id}>
                                                    <Part1>
                                                        <Name>{service?.name}</Name>
                                                        {!isMobileScreen && <TimeTag time={service?.note} />}
                                                        <Price>&#36;{service?.price} {!service?.isFixedPrice && '& up'}</Price>
                                                    </Part1>
                                                    {isMobileScreen &&
                                                        <TimeTag time={service?.note} className='outside' />
                                                    }
                                                    <Part2>
                                                        {service?.description && renderDescription(service?.description)}
                                                        {service?.listOptions && service?.listOptions?.length > 0 && (
                                                            <Flavors>
                                                                <span>With a scent of your choice:</span>
                                                                <br />
                                                                <ul>
                                                                    {service.listOptions?.map(option => <li key={option?.id}>{option?.name}</li>)}
                                                                </ul>
                                                            </Flavors>
                                                        )}
                                                    </Part2>
                                                </ServiceWrapper>
                                            ))}
                                        </ListServices>
                                    </Col>
                                </RowEven>
                            )
                        )}
                    </BodyWrapper>
                ) : (
                    <LoadingIcon className="pi pi-spin pi-spinner" />
                )}
            </Layout>
        </>
    )
}

export default Services
