import { PedicuresImg, ManicuresImg, ArtificialNailsImg, GelNailsImg2, WaxingImg, KidsMenuImg, TimAvatar, KaitlynAvatar, AshleyAvatar, JimmyAvatar, OtherAvatar } from '../../../assets/images'
import { Img1, Img11, Img2, Img3, Img4, Img5, Img6, Img7, Img8 } from '../../../assets/images/gallery'

export const listServiceGroups = [
    {
        id: 1,
        name: 'Pedicures',
        imgSrc: PedicuresImg,
        description: 'The wonderful treatments to rejuvenate your tired legs and feet.',
    },
    {
        id: 2,
        name: 'Manicures',
        imgSrc: ManicuresImg,
        description: 'Pamper your hands after those long hours of work.',
    },
    {
        id: 3,
        name: 'Artificial Nails',
        imgSrc: ArtificialNailsImg,
        description: '',
    },
    {
        id: 4,
        name: 'Gel Nails',
        imgSrc: GelNailsImg2,
        description: '',
    },
    {
        id: 5,
        name: 'Waxing',
        imgSrc: WaxingImg,
        description: 'A way to be more confidence!',
    },
    {
        id: 6,
        name: 'Kids 12 & Under',
        imgSrc: KidsMenuImg,
        description: "It's never too early to start the grooming habit! ^ ^",
    },
]

export const technicians = [
    { id: 1, name: 'Tim Houston', avatar: TimAvatar },
    { id: 2, name: 'Kaitlyn Kristy', avatar: KaitlynAvatar },
    { id: 3, name: 'Crystal Waston', avatar: 'https://qph.fs.quoracdn.net/main-qimg-ba379c87d621ed6e49fb22da9a211fd0.webp' },
    { id: 4, name: 'Ashley Rosa', avatar: AshleyAvatar },
    { id: 5, name: 'Jimmy Coleman', avatar: JimmyAvatar },
    { id: -1, name: 'And many more', avatar: OtherAvatar },
]

export const gallery = [
    { id: 1, src: Img1 },
    { id: 2, src: Img2 },
    { id: 3, src: Img3 },
    { id: 4, src: Img4 },
    { id: 5, src: Img5 },
    { id: 6, src: Img6 },
    { id: 7, src: Img7 },
    { id: 8, src: Img8 },
    { id: 9, src: Img11 },
]

export const feedbacks = [
    {
        id: 7,
        cusName: 'Mark Davis',
        avatar: 'https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_styleguide/514f6997a318/assets/img/default_avatars/user_60_square.png',
        feedback: "Everyone is always very professional and courteous.  I’ve going here for over 5 years and their staff is always well trained.",
        stars: 5,
        reviewFrom: 'Google',
        source: 'https://www.google.com/search?q=deluxe+nails+spa+coppell&rlz=1C1ONGR_enVN952VN952&oq=deluxe+nails+spa+coppell&aqs=chrome..69i57j69i61l3.7949j0j1&sourceid=chrome&ie=UTF-8#lrd=0x864c294dd5e2bead:0xdaa3335db46a5d02,1,,,'
    },
    {
        id: 1,
        cusName: 'Michelle R.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_styleguide/514f6997a318/assets/img/default_avatars/user_60_square.png',
        feedback: "This place really caters to people. From chandeliers to a margarita machine, they have a lot of great options that makes this place desirable. I always get the deluxe pedicure which is amazing. They're a little more pricy than other places but I think it's totally worth it. Overall a great establishment.",
        stars: 4,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
    {
        id: 2,
        cusName: 'Carol W.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/photo/jm3BROwhA-PZ85ec4eyhKg/90s.jpg',
        feedback: 'Just had the best NextGen and deluxe pedicure of my life! Thanks to Jessica and Kimberly, respectively.',
        stars: 5,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
    {
        id: 3,
        cusName: 'Leilani S.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/photo/oI9ySCFajsyHNIbw8gs5ww/90s.jpg',
        feedback: "Loved it. My girlfriend and I went in after a long wait at a previous salon. Everyone was friendly and inviting. Lisa (hilarious) did my NextGen and Kim did my girlfriends pedicure. They saved the night! Very clean, gorgeous, nice service. I'll return!",
        stars: 5,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
    {
        id: 4,
        cusName: 'Kristan H.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_styleguide/514f6997a318/assets/img/default_avatars/user_60_square.png',
        feedback: "Lien does an amazing pedicure. Amazing foot and leg massage. Tina's Nextgen is perfect. I highly recommend Deluxe nails! You won't be disappointed!",
        stars: 5,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
    {
        id: 5,
        cusName: 'Anon A.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_styleguide/514f6997a318/assets/img/default_avatars/user_60_square.png',
        feedback: "The pictures don't do this place justice. This place is beautiful. Michelle, Kevin is so nice. The pedicure chairs, the wax rooms, etc. I can't say enough.",
        stars: 4,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
    {
        id: 6,
        cusName: 'Paige F.',
        avatar: 'https://s3-media0.fl.yelpcdn.com/photo/qN3wPB5WEqBz6JoAk4R21Q/90s.jpg',
        feedback: "This is the one you want to go to. Top notch service and probably the nicest nail salon I've been to. Got brows and a spa pedi for $53. Not a bargain but not outrageous, either.",
        stars: 5,
        reviewFrom: 'Yelp',
        source: 'https://www.yelp.com/biz/deluxe-nails-and-spa-coppell'
    },
]