import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.8rem;
    transition: height 0.3s ease-in-out;

    @media screen and (max-width: 480px) {
        height: 230px;
    }
`
export const Icon = styled.img`
    width: 70px;
    height: 70px;
    margin-bottom: 0.8rem;
`
export const H3 = styled.h3`
    margin-bottom: 1.1rem;
    color: ${({ isSuccessful }) => isSuccessful ? '#01A601' : '#e53935'} ;
`

export const LoadingIcon = styled.i`
    font-size: 4rem;
    color: gray;
    margin-top: 1.5rem;
`