import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { InfoPanel, MainPanel, PreviewPanel } from './panels';
import { Backdrop, ModalContainer, InfoPanelContainer, MainPanelContainer, PreviewPanelContainer } from './BookingModalItems';
import { useBooking } from './BookingContext';

const BookingModal = (props) => {
    const { showModal, setShowModal } = props
    const { appointment, step, setStep } = useBooking()
    const [showPreviewPanel, setShowPreviewPanel] = useState(false);
    const modelRef = useRef()

    useEffect(() => {
        if (showModal)
            document.body.style.overflow = 'hidden'
        else
            document.body.style.overflow = 'auto'
    }, [showModal])

    useEffect(() => {
        if ([2, 3, 4].includes(step) || (step === 1 && appointment?.date)) {
            setShowPreviewPanel(true)
        } else {
            setShowPreviewPanel(false)
        }
    }, [step, appointment?.date]);

    const animationShowModal = useSpring({
        config: { duration: 250 },
        opacity: showModal ? 1 : 0,
        transform: showModal ? 'translateY(0%)' : 'translateY(-100%)'
    })
    // const animationShowPreviewMainPanel = useSpring({
    //     config: { duration: 450 },
    //     width: showPreviewPanel ? '500px' : '560px',
    // })
    // const animationShowPreviewPreviewPanel = useSpring({
    //     config: { duration: 450 },
    //     width: showPreviewPanel ? '340px' : '0px',
    //     opacity: showPreviewPanel ? 1 : 0,
    // })

    // Ko muốn cho close modal khi click backdrop nữa
    // const closeModalOnClickedBackdrop = e => {
    //     if (modelRef.current === e.target) {
    //         if (step === 6) {
    //             setStep(1)
    //         }
    //         setShowModal(false)
    //     }
    // }

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) {
            if (step === 6) {
                setStep(1)
            }
            setShowModal(false)
        }
    }, [showModal, setShowModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress)
        };
    }, [keyPress]);

    // const showPreviewPanel = () => {
    //     if ([2, 3, 4].includes(step))
    //         return true;
    //     else {
    //         if (step === 1 && appointment?.date) {
    //             return true
    //         }
    //     }
    // }

    return (
        <>
            {showModal && (
                <Backdrop ref={modelRef}>   {/* onClick={closeModalOnClickedBackdrop} */}
                    <animated.div style={animationShowModal}>
                        <ModalContainer showPreviewPanel={showPreviewPanel} step={step}>
                            <InfoPanelContainer>
                                <InfoPanel />
                            </InfoPanelContainer>
                            {/* <animated.div style={animationShowPreviewMainPanel}> */}
                            <MainPanelContainer showPreviewPanel={showPreviewPanel}>
                                <MainPanel setShowModal={setShowModal} />
                            </MainPanelContainer>
                            {/* </animated.div> */}
                            {showPreviewPanel && (
                                // <animated.div style={animationShowPreviewPreviewPanel}>
                                <PreviewPanelContainer showPreviewPanel={showPreviewPanel}>
                                    <PreviewPanel />
                                </PreviewPanelContainer>
                                // </animated.div>
                            )}
                        </ModalContainer>
                    </animated.div>
                </Backdrop>
            )}
        </>
    );
}

export default BookingModal;