import styled from 'styled-components';

export const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const ModalContainer = styled.div`
    width: ${({ showPreviewPanel }) => showPreviewPanel ? '1100px' : '820px'};
    height: 610px;
    /* overflow: hidden; */
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #171416;
    display: flex;
    position: relative;
    z-index: 10;
    border-radius: 6px;
    transition: width 450ms ease-in-out, height 0.3s ease-in-out;

    @media screen and (max-width: 960px) {
        width: 760px;
    }
    @media screen and (max-width: 600px) {
        width: 480px;
        /* width: 500px; */
    }
    @media screen and (max-width: 480px) {
        width: calc(100vw - 0.3rem);
        height: ${({ step }) => step === 6 ? '300px' : '78vh'};
        /* width: 418px; */
        /* height: 100%; */
    }
`

export const InfoPanelContainer = styled.div`
    width: 260px;
    border-radius: 6px 0px 0px 6px;
    border-right: 1px solid #D0D0D0;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const MainPanelContainer = styled.div`
    width: ${({ showPreviewPanel }) => showPreviewPanel ? '500px' : '560px'};
    /* height: 100%; */
    /* overflow-y: hidden; */
    transition: width 450ms ease-in-out;

    /* border: 1px solid yellow ; */
    /* border-radius: 6px; */

    @media screen and (max-width: 480px) {
        width: 480px;
        /* height: 94.5vh; // Đúng ko nhỉ??? */
    }
`

export const PreviewPanelContainer = styled.div`
    width: ${({ showPreviewPanel }) => showPreviewPanel ? '340px' : '0px'};
    border-radius: 0px 6px 6px 0px;
    border-left: 1px solid #D0D0D0;
    transition: width 450ms ease-in-out;

    @media screen and (max-width: 960px) {
        display: none;
    }
`