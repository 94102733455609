import styled from 'styled-components'
import { Button } from 'primereact/button'

export const FormContainer = styled.div`
  height: calc(100% - 58px - 24px);
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
export const NoticeMsg = styled.p`
  font-size: 1rem;
  font-weight: normal;
  font-style: italic;
  text-align: center;

  margin: 16px 0 40px;
`
export const FormWrapper = styled.form`
  width: 100%;
  max-width: 420px;
  padding: 0;

  & > * {
    padding-left: 0;
    padding-right: 0;
  }
  & > div[class="p-field p-col-12"] {
    margin-bottom: 0.8rem;

    & span {
      height: 38px;
    }
    & span[class*="p-float-label"] input {
      padding-left: 8px;
      padding-right: 8px;
    }
    & span[class="p-float-label p-input-icon-right"] {
      i {
        right: 10px;
      }
      input {
        padding-right: 30px;
      }
    }
  }
  & > div[class*="field-checkbox"] {
    display: flex;
    justify-content: center;
  }
`
export const SuggestedNameWrapper = styled.div`
  /* width: 100%; */
  margin-top: 6px;
  margin-left: 40px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 6px;
`
export const SuggestedName = styled.div`
  padding: 4px 10px;

  font-size: 0.85rem;
  color: #fff;
  background-color: #F9C892;
  border-radius: 3px;

  &:hover {
    color: #333;
    font-weight: 500;
    cursor: pointer;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`
export const BtnSkip = styled(Button)`
  width: 100%;
  margin: 0;
  padding: 12px 18px;

  font-size: 17px;
  font-weight: 600;
  color: #171416;
  
  background: #F9C892;
  border-radius: 50px;
  border: 2px solid #F9C892;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  
  &:hover {
    color: #171416 !important;
    background: #ffcbaf !important;
    border: 2px solid #ffcbaf !important;
    transition: all 0.2s ease-in-out;
  }
`
export const BtnNext = styled(Button)`
  width: 100%;
  margin: 0;
  padding: 12px 18px;

  font-size: 17px;
  font-weight: 600;
  color: #171416;
  
  background: #F9C892;
  border-radius: 50px;
  border: 2px solid #F9C892;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  
  &:hover {
    color: #171416 !important;
    background: #ffcbaf !important;
    border: 2px solid #ffcbaf !important;
    transition: all 0.2s ease-in-out;
  }
`