import { default as Api } from './Apis'

export async function getListNamesByPhone(phoneNo) {
  const response = await Api.get(`/checkIn-Out/${phoneNo}/names`)
  return response
}

export async function requestCheckIn(data) {
  const response = await Api.post(`/checkIn-Out/request`, data)
  return response
}

export async function getCustomerDetail(phoneNo) {
  const response = await Api.get(`/checkIn-Out?phone=${phoneNo}`)
  return response
}

export async function getHistory(phoneNo) {
  const response = await Api.get(`/checkIn-Out/${phoneNo}/get-history`)
  return response
}