import styled from 'styled-components';

export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const Title = styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 20px;

    span.logo {
        font-size: 40px;
        color: #171416f0;
        font-family: 'Plaza', sans-serif;
    }
`