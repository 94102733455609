import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import Layout from '../../layout/Layout';
import { AboutCoverImg, SalonImg } from '../../assets/images'
import { Img12 } from '../../assets/images/gallery'
import { BodyWrapper, Row, Col, Title, Content, Img, Img1, Img2, Background1, Background2, LastRow, LastContent } from './AboutUsItems'
// import { animateScroll as scroll } from 'react-scroll'

function AboutUs() {
    useEffect(() => {
        // scroll.scrollToTop();
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>About Us | Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="About Deluxe Nails & Spa | (972) 304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019 | Services: Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and more!" />
                <meta name="keywords" content="Deluxe, Nails, Spa, Nails Salon, Coppell, TX 75019, Texas, Neighborhood Favorite, qualified technicians, must visit" />
                <link rel="canonical" href="https://deluxenailsspacoppell.com/" />
            </Helmet>

            <Layout title="About Us" coverImgSrc={AboutCoverImg} isNotFound={false}>
                <BodyWrapper>
                    <Row mainInLeft>
                        <Col>
                            <Title isFirstRow>Welcome to {' '}
                                <span className="logo-deluxe">dELUXE</span>
                                <span className="logo-nails">
                                    nAILS
                                    <span className="logo-and">&amp;</span>
                                    sPA
                                </span>
                            </Title>
                            <Content>
                                Located at a beautiful corner in <b>Coppell, Texas 75019</b>, <strong>Deluxe Nails &amp; Spa</strong> has been received so many compliments and recommendations from customers.
                                <br />
                                <div>
                                    This is because our Nail Salon always puts all of our heart into job.
                                </div>
                                <div>
                                    <strong>Deluxe Nails &amp; Spa</strong> won a <em><a href="https://nextdoor.com/pages/delux-nails-spa-coppell-tx/">Neighborhood Favorite</a></em> in 4 continuous years (2017, 2018, 2019, 2020) in Coppell, TX.
                                </div>
                                <Img src={SalonImg} alt="deluxe nails salon" />
                            </Content>
                        </Col>
                        <Col>
                            <Background1 />
                            <Img1 src={SalonImg} alt="deluxe nails salon" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Background2 />
                            <Img2 src={Img12} alt="nice nails" />
                        </Col>
                        <Col>
                            <Title>Our Mission</Title>
                            <Content>
                                An upscale spa with a relaxing atmosphere is dedicated to your satisfaction!
                                <br /><br />
                                Our qualified technicians will continue to be attuned, personable, and responsive to all of your needs.
                                <br />
                                We've embodied our own signature blend of natural and organic ingredients, maintaining the sanitary needs toward each individual. Making sanitation our number one priority, we are always looking for better ways and products that'll benefit you most!
                            </Content>
                        </Col>
                    </Row>
                    <LastRow>
                        <LastContent style={{ marginTop: '2.5rem', marginBottom: '4rem' }}>
                            <span>Let's come and enjoy the best moment ever!</span>
                            <br />
                            We make sure to give you a relaxing and rejuvenating experience when choosing <strong>Deluxe Nails &amp; Spa</strong> - a must visit nail salon in the Coppell, TX 75019.
                        </LastContent>
                    </LastRow>
                </BodyWrapper>
            </Layout>
        </>
    )
}

export default AboutUs
