import React from 'react'
import { GiftsZone, Background, IconGift } from './GiftsItem'
// import { GiReceiveMoney } from 'react-icons/gi'
import { HappyFace, StickyNote } from '../../../assets/images'

function Gift({ ...props }) {
  return (
    <GiftsZone {...props}>
      <Background src={StickyNote} alt='' />
      <h3><IconGift /> Redemption Gift <IconGift /></h3>
      <ul>
        <li>
          {/* <GiReceiveMoney style={{ width: 22, height: 22, marginRight: 5 }} /> */}
          For each <strong>300 points</strong>, you will receive <strong>$10</strong>
          {/* discount on your bill */}
        </li>
        <li style={{ textAlign: 'center', listStyle: 'none' }}>
          <img src={HappyFace} alt='' />
        </li>
        {/* <li>300 points = $10</li>
        <li>300 points = $10</li>
        <li>300 points = $10</li> */}
      </ul>
    </GiftsZone>
  )
}

export default React.memo(Gift)