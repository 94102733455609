import styled from 'styled-components';

export const Background = styled.div`
    width: 100%;
    height: 100%;
    
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img.bg {
        width: 100%;
        height: 100%;
        object-fit: cover;

        position: relative;
        z-index: 0;
    }
`

export const FrameWrapper = styled.div`
    width: 100%;
    max-width: 420px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
`