import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    const { search, ...rest } = useLocation()
    const history = useHistory()

    const query = React.useMemo(() => new URLSearchParams(search), [search])

    const setQuery = newParams => {
        history.replace({
            ...rest,
            search: `?${new URLSearchParams({ ...newParams }).toString()}`
        })
    }

    return [query, setQuery]
}