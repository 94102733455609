import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    /* position: relative; */
`
export const PageBody = styled.main`
    width: 100%;
    min-height: 300px;
    position: relative;
    /* position: absolute;
    top: 310px; */
`

// fix