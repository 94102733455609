import styled from 'styled-components';

export const Wrapper = styled.div`
    /* width: 100%; */
    height: 100%;
    background-color: #F7F7F7;
    border-radius: 6px 0px 0px 6px;
    padding: 16px;
    overflow: hidden;
`

export const Header = styled.div`
    display: flex;
    justify-content: center;
`
export const StepEmpty = styled.div`
    width: 8px;
    height: 8px;
    border: 2px solid #a0a0a0;
    border-radius: 100%;
    margin: 0.2rem;

    &:hover {
        transform: scale(1.25);
    }
`
export const StepFilled = styled.div`
    width: 8px;
    height: 8px;
    border: 2px solid #a0a0a0;
    border-radius: 100%;
    margin: 0.2rem;
    background-color: #a0a0a0;

    &:hover {
        transform: scale(1.25);
        cursor: pointer;
    }
`

export const Body = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    animation: ${({ isBack }) => isBack
        ? 'slideLeft 750ms cubic-bezier(0.05, -0.28, 0.87, 1.68) forwards'
        : 'slideRight 750ms cubic-bezier(0.05, -0.28, 0.87, 1.68) forwards'
    };
    -webkit-animation: ${({ isBack }) => isBack
        ? 'slideLeft 750ms cubic-bezier(0.05, -0.28, 0.87, 1.68) forwards'
        : 'slideRight 750ms cubic-bezier(0.05, -0.28, 0.87, 1.68) forwards'
    };
    
    @keyframes slideLeft {
        from {
            opacity: 0;
            color: #fff;
            transform: translateX(20%);
        }
        to {
            opacity: 1;
            color: inherit;
            transform: translateX(0%);
        }
    }
    @keyframes slideRight {
        from {
            opacity: 0;
            color: #fff;
            transform: translateX(-20%);
        }
        to {
            opacity: 1;
            color: inherit;
            transform: translateX(0%);
        }
    }
`
export const StepIcon = styled.img`
    width: 120px;
    height: 120px;
    margin-top: -2rem;
`
export const StepPurpose = styled.h2`
    margin: 1rem;
`
export const StepDescription = styled.p`
    font-size: 0.85rem;
    color: gray;
`

export const Footer = styled.div`
    height: 65px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Hotline = styled.h5`
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
`
export const HotlineNumber = styled.p`
    font-size: 0.85rem;
    color: gray;

    a {
        text-decoration: none;
    }
`