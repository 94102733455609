import React from 'react';
import { Container, Wrapper, Heading, CardReasons, CardGroup, CardItem, Icon, Text } from './WhyChooseUsSectionItems';
import { ManyServicesIcon, QualifiedIcon, SatisfiedIcon, TrendIcon } from '../../../../assets/images'

const WhyChooseUsSection = () => {
    return (
        <Container>
            <Wrapper>
                <Heading data-aos="zoom-in">
                    Why clients choose us
                    <span style={{ fontFamily: 'Destain, sans-serif', fontSize: '2.1rem' }}>?</span>
                </Heading>
                <CardReasons>
                    <CardGroup>
                        <CardItem data-aos="fade-up" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom">
                            <Icon src={QualifiedIcon} alt="high qualified technicians icon" />
                            <Text>High qualified technicians</Text>
                        </CardItem>
                        <CardItem data-aos="fade-up" data-aos-delay="500" data-aos-anchor-placement="bottom-bottom">
                            <Icon src={ManyServicesIcon} alt="many good services icon" />
                            <Text>Full range of services and good therapies</Text>
                        </CardItem>
                    </CardGroup>
                    <CardGroup>
                        <CardItem data-aos="fade-up" data-aos-delay="750" data-aos-anchor-placement="bottom-bottom">
                            <Icon src={SatisfiedIcon} alt="satisfied customers icon" />
                            <Text>Hundreds of satisfied customers</Text>
                        </CardItem>
                        <CardItem data-aos="fade-up" data-aos-delay="1000" data-aos-anchor-placement="bottom-bottom">
                            <Icon src={TrendIcon} alt="trendy icon" />
                            <Text>Always up-to-date with trend</Text>
                        </CardItem>
                    </CardGroup>
                </CardReasons>
            </Wrapper>
        </Container>
    );
}

export default WhyChooseUsSection;
