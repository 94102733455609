import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Wrapper, FormWrapper } from './Step4InfoItems';
import { useBooking } from '../BookingContext';

const Step4CustomerInfo = () => {
    const { appointment, setAppointment } = useBooking()
    const defaultValues = {
        name: appointment?.name || '',
        phone: appointment?.phone || '',
        email: appointment?.email || '',
    }
    const typingTimeOutRef = useRef(null)

    const { control, formState: { errors }, getValues } = useForm({    // handleSubmit, reset
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    const [note, setNote] = useState(appointment?.note || '');

    /** Ko hiểu làm sao đó mà phone bị độ trễ (kể cả có ko để debounce), 
     * ko lấy đc đủ số nên tui dùng kết hợp useEffect() cho name còn onBlur() cho phone & email (vì cần phụ thuộc validate) */
    useEffect(() => {
        if (typingTimeOutRef.current) {
            clearTimeout(typingTimeOutRef.current)
        }

        typingTimeOutRef.current = setTimeout(() => {
            setAppointment({
                ...appointment,
                name: getValues('name'),
                // phone: getValues('phone'),
                // email: '',    //getValues('email')
            })
        }, 300);
    }, [getValues('name')]);

    useEffect(() => {
        if (!appointment?.email) {
            setAppointment({ ...appointment, email: '' })
        }
    }, [])

    // Chưa có gì trigger cái onSubmit này cả. Form này chưa cần submit tại step này
    // const onSubmit = (data) => {
    //     setFormData(data);
    //     setShowMessage(true);

    //     reset();
    // };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error" style={{ marginLeft: '2.85rem' }}>{errors[name].message}</small>
    };

    const handlePhoneOnBlur = (e, isInvalid) => {
        // console.log('handlePhoneOnBlur', isInvalid);
        const phoneNo = e.target.value
        if (phoneNo && !isInvalid) {
            // console.log(phoneNo);
            setAppointment({ ...appointment, phone: phoneNo })
        } else {
            setAppointment({ ...appointment, phone: '' })
        }
    }
    const handleEmailOnBlur = (e, isInvalid) => {
        // console.log('handleEmailOnBlur', isInvalid);
        const email = e.target.value
        if (email) {
            // console.log(email);
            setAppointment({ ...appointment, email: email })

            if (isInvalid) {
                setAppointment({ ...appointment, email: null })
            }
        } else {
            setAppointment({ ...appointment, email: '' })
            // console.log('empty, not null');
        }
    }

    const handleNoteChange = (e) => {
        const noteContent = e.target.value;
        setNote(noteContent)
        setAppointment({ ...appointment, note: noteContent })
    }

    return (
        <Wrapper>
            <form className="p-fluid" style={{ width: '100%' }}>    {/** onSubmit={handleSubmit(onSubmit)} */}
                <FormWrapper>
                    <div className="p-field p-col-12 p-md-11">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <span className="p-float-label">
                                <Controller name="name" control={control} rules={{ required: 'Your Name is required.' }} render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name} {...field} autoFocus
                                        // value={contactInfo?.name}
                                        // onChange={handleFormDataChange}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                    />
                                )} />
                                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Your Name*</label>
                            </span>
                        </div>
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="p-field p-col-12 p-md-11">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-phone"></i>
                            </span>
                            <span className="p-float-label">
                                <Controller name="phone" control={control} rules={{ required: 'Phone is required.' }} render={({ field, fieldState }) => (
                                    <InputMask
                                        id={field.phone} {...field}
                                        mask="(999) 999-9999"
                                        // value={contactInfo?.phone}
                                        // onChange={handleFormDataChange}
                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                        onBlur={(e) => handlePhoneOnBlur(e, fieldState.invalid)}
                                    />
                                )} />
                                <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Phone Number*</label>
                            </span>
                        </div>
                        {getFormErrorMessage('phone')}
                    </div>
                    <div className="p-field p-col-12 p-md-11">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                                <Controller name="email" control={control}
                                    rules={{
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address. E.g. example@email.com'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name} {...field}
                                            // value={contactInfo?.email}
                                            // onChange={handleFormDataChange}
                                            aria-describedby="email-help"
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                            onBlur={(e) => handleEmailOnBlur(e, fieldState.invalid)}
                                        />
                                    )} />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                                    Email
                                </label>
                            </span>
                        </div>
                        {errors.email ? getFormErrorMessage('email') : (
                            <small id='email-help' className="p-d-block p-text-italic" style={{ marginLeft: '2.85rem' }}>
                                Please provide a precise email to receive a booking confirmation
                            </small>
                        )}
                    </div>

                    <div className="p-field p-col-12 p-md-11">
                        <span className="p-float-label">
                            {/* <div id="note" style={{ marginTop: '0.75rem' }}> */}
                            {/* <h5 style={{ margin: '0.5rem 0 0 0.5rem' }}>
                                Other requirements <i style={{ fontWeight: 400, color: 'gray' }}>(optional)</i>
                            </h5> */}
                            <InputTextarea name="note" value={note} onChange={handleNoteChange} rows={7} cols={30}
                            // style={{ margin: '0.5rem', width: '97%' }}
                            />
                            {/* </div> */}
                            <label htmlFor="note">Your Note (optional)</label>
                        </span>
                    </div>
                </FormWrapper>
            </form>
        </Wrapper>
    );
}

export default Step4CustomerInfo;