import React, { useEffect, useState } from 'react';
// import { Divider } from 'primereact/divider';
import Layout from '../../layout/Layout';
import { RewardCoverImg } from '../../assets/images'
import { BodyWrapper, Row } from './RewardsItem';
import Gifts from './Gifts';
import RequestForm from './RequestForm';
import HistoryTimeline from './HistoryTimeline';

function Rewards() {
  const [history, setHistory] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* Trang này ko SEO nên ko cần có HELMET */}
      <Layout title="Loyalty Rewards" coverImgSrc={RewardCoverImg} isNotFound={false}>
        <BodyWrapper>
          <Row>
            <RequestForm setHistory={setHistory} />
            <Gifts className="gift-row" />
          </Row>

          <Row>
            <HistoryTimeline data={history} />
            <Gifts className="gift-col" />
          </Row>
        </BodyWrapper>
      </Layout>
    </>
  )
}

export default Rewards;
