// import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { CheckInNailClub, Home, NotFound } from './pages';
import { routerData } from './routers/routerData'
import AppProvider from './hooks/AppContext'
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import './styles/override-nova-theme.css';  // custom default nova-accent/theme.css of the Framework
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

function App() {
  const domain = window.location.hostname
    ?? /:\/\/([^\/]+)/.exec(window.location.href)[1]
    ?? window.location.href
  // get everything from the first :// to the end or the next /

  const getRouteBySubdomain = () => {
    if (domain.includes('nailclub')) {
      return (
        <Switch>
          <Route exact path='/' component={CheckInNailClub} />
        </Switch>
      )
    }

    return (
      <Switch>
        <Route exact path='/' component={Home} />
        {routerData.map(page =>
          <Route path={page?.path} component={page?.component} key={page?.path} />
        )}
        <Route path='/:otherPaths' component={NotFound} />
      </Switch>
    )
  }

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Deluxe Nails & Spa | Coppell, TX 75019</title>
        <meta name="description" content="Best nails spa in Coppell, Texas | Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and many more services! Contact us at (972)304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019" />
        <meta name="keywords" content="Deluxe, Nails, Spa, Beauty, Nails Salon, Coppell, TX 75019, Texas, Pedicures, Manicure, Acrylic, Gel, Waxing, Neighborhood Favorite, qualified technicians" />
        <link rel="canonical" href="https://deluxenailsspacoppell.com/" />

        {/* Open Graph tags for sharing web on social media */}
        <meta property="og:title" content="Deluxe Nails & Spa - A must visit nails salon in Coppell, Texas" />
        <meta property="og:description" content="Best nails spa in Coppell, Texas | Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and many more services!" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/deluxe-nails-spa-96313.appspot.com/o/web-thumbnail.png?alt=media&token=75b71396-9d3b-4b6a-abe6-fbcb7f9741f3" />
        <meta property="og:image:width" content="1500" />
        <meta property="og:image:height" content="912" />
        <meta property="og:url" content="https://deluxenailsspacoppell.com" />
        <meta property="og:type" content="website" />
        {/* Specify tag for Twitter */}
        <meta name="twitter:card" content="summary_large_image" />

        {/* Custom Google search result preview  */}
        <meta name="google" content="notranslate" />
        <meta name="google" content="nositelinkssearchbox" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      </Helmet>

      <AppProvider>
        <Router>
          {getRouteBySubdomain()}

          {/* <Switch>
            <Route exact path='/' component={Home} />
            {routerData.map(page =>
              <Route path={page?.path} component={page?.component} key={page?.path} />
            )}
            <Route path='/:otherPaths' component={NotFound} />
          </Switch> */}
        </Router>
      </AppProvider>

      {/* <MessengerCustomerChat pageId="106532281752495" appId="524069778868775" /> */}
    </>
  );
}

export default App;