import React, { createContext, useContext, useState } from 'react'

const CheckInContext = createContext()

export const useCheckIn = () => useContext(CheckInContext)

const CheckInProvider = ({ children }) => {
  const [step, setStep] = useState(1)
  const [isNewCustomer, setIsNewCustomer] = useState(false)

  const [checkInData, setCheckInData] = useState({
    phone: '',
    name: '',
    email: '',
    birthdate: null,
    address: ''
  })

  const [customerInfo, setCustomerInfo] = useState(null)

  const [message, setMessage] = useState('')

  const stepContent = {
    CHECK_IN: 1,
    CUS_INFO: 2,
    SUCCESS: 3
  }

  const onChangeCheckInData = (key, value) => {
    setCheckInData({
      ...checkInData,
      [key]: value
    })
  }

  return (
    <CheckInContext.Provider value={{
      step, setStep,
      stepContent,
      checkInData, onChangeCheckInData,
      isNewCustomer, setIsNewCustomer,
      message, setMessage,
      customerInfo, setCustomerInfo
    }}>
      {children}
    </CheckInContext.Provider>
  )
}

export default CheckInProvider