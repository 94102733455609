import React, { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import {
    Wrapper, LoadingIcon, CardServiceGroup, GroupIcon, GroupName, GroupQuantity,
    CardServicesWrapper, CardService, ServiceName, FlavorWrapper
} from './Step2ServicesItems';
import { getServicesMenu } from '../../../services/BookingApi';
import { PedicuresIcon, ManicuresIcon, ArtificialNailsIcon, GelNailsIcon, WaxingIcon, KidsMenuIcon } from '../../../assets/images';
import { useBooking } from '../BookingContext';
import { removeItem } from '../../../utils/Arrays';
// import { useTransition, animated } from 'react-spring';

const Step2Services = () => {
    const { appointment, setAppointment, servciesData, setServciesData } = useBooking()

    // This is for animation
    // const transition = useTransition(appointment?.serviceGroups, {
    //     from: { x: 0, y: 0, opacity: 0 },
    //     to: { x: 0, y: 800, opacity: 1 },
    //     // enter: item => (next) => (
    //     //     next({ y: item.y, opacity: 1, delay: item.delay })
    //     // ),
    //     // leave: { x: 100, y: 800, opacity: 0 }
    // })

    let loading = true
    const loadServiceMenu = () => {
        getServicesMenu().then(res => {
            if (loading) {
                // console.log('step 2, res = ', res);
                setServciesData(res)
            }
        }).catch((error) => {
            if (error.response) {
                console.log('Error at Booking step 2 - getServices.')
                // history.push({
                //     pathname: '/errors',
                //     state: { error: error.response.status },
                // })
            }
        })
    }
    useEffect(() => {
        if (!servciesData) {
            loadServiceMenu()
        }
        // if (!appointment?.serviceGroups) {
        //     setAppointment({ ...appointment, serviceGroups: [] })
        // }
        return () => {
            loading = false
        };
    }, []);

    // Mới thêm
    // if (!appointment?.serviceGroups) {
    //     setAppointment({ ...appointment, serviceGroups: [] })
    // }

    if (!servciesData) {
        return <LoadingIcon className="pi pi-spin pi-spinner" />
    }

    const getGroupServiceImage = (groupId) => {
        switch (groupId) {
            case 1:
                return PedicuresIcon;
            case 2:
                return ManicuresIcon;
            case 3:
                return ArtificialNailsIcon;
            case 4:
                return GelNailsIcon;
            case 5:
                return WaxingIcon;
            case 6:
                return KidsMenuIcon;
            default:
                break;
        }
    }

    const customizeServiceName = (serviceId, serviceName) => {
        switch (serviceId) {
            // case 5:
            // case 6:
            // case 7:
            //     return 'Pedicures ' + serviceName;

            case 23:
            case 24:
                return serviceName.substr(0, serviceName.indexOf('W/ Manicure'));
            default:
                return serviceName;
        }
    }

    const handleServiceOptionsChange = (e, option, service, group) => {
        const checked = e.checked
        const { id, name } = option
        option = { id, name } // bỏ hết tất cả các props còn lại

        let selectedGroups = appointment?.serviceGroups ? [...appointment?.serviceGroups] : []
        let indexGroup = selectedGroups.findIndex(item => item?.id === group?.id)
        // let selectedServices = selectedGroups?.services ? [...selectedGroups?.services] : []
        // ==> SAI!!! Để lấy đc services thì phải vào từng item bên trong chứ ko phải là đứng từ cả cái list
        // let selectedOptions = selectedServices?.options ? [...selectedServices?.options] : []
        // ==> Kéo theo cái này cũng ko lấy đc luôn mà luôn = [] mỗi lần gọi tới hàm này

        // ===> FIXED
        let checkedGroup = selectedGroups?.find(item => item?.id === group?.id)

        let selectedServices = checkedGroup?.services ? [...checkedGroup?.services] : []
        let checkedService = selectedServices?.find(item => item?.id === service?.id)

        let selectedOptions = checkedService?.options ? [...checkedService?.options] : []

        if (checked) {
            selectedOptions.push(option);
        } else {
            selectedOptions = selectedOptions.filter(({ id }) => id !== e.value);
        }

        if (indexGroup !== -1) {
            let indexServices = selectedServices.findIndex(item => item?.id === service?.id)

            if (indexServices !== -1) {
                selectedGroups[indexGroup].services[indexServices].options = selectedOptions

                if (!checked) {
                    if (selectedOptions.length === 0) {
                        selectedGroups[indexGroup].services = [...removeItem(selectedServices, 'id', service?.id)]
                        if (selectedGroups[indexGroup]?.services?.length === 0) {
                            selectedGroups = [...removeItem(selectedGroups, 'id', group?.id)]
                        }
                    }
                }
            } else {
                selectedServices.push({
                    id: service?.id,
                    name: service?.name,
                    options: selectedOptions
                })
                selectedGroups[indexGroup].services = selectedServices
            }
        } else {
            selectedServices.push({
                id: service?.id,
                name: service?.name,
                options: selectedOptions
            })
            selectedGroups.push({
                id: group?.id,
                name: group?.name,
                services: selectedServices
            })
        }
        setAppointment({
            ...appointment,
            serviceGroups: selectedGroups
        })
    }

    const checkExisted = (optionId, serviceId, groupId) => {
        let selectedGroups = appointment?.serviceGroups ? [...appointment?.serviceGroups] : []
        let checkedGroup = selectedGroups?.find(item => item?.id === groupId)
        let selectedServices = checkedGroup?.services ? [...checkedGroup?.services] : []
        let checkedService = selectedServices?.find(item => item?.id === serviceId)
        let selectedOptions = checkedService?.options ? [...checkedService?.options] : []

        if (selectedOptions.findIndex(item => item?.id === optionId) !== -1)
            return true
        return false
    }

    return (
        <Wrapper>
            {servciesData?.map(group => (
                <Panel key={group?.id}
                    toggleable
                    collapsed
                    style={{ marginBottom: '1rem' }}
                    headerTemplate={(options) => (
                        <CardServiceGroup
                            onClick={options.onTogglerClick}
                            collapsed={options.collapsed &&     // Đóng mở theo toggleClick
                                (appointment?.serviceGroups
                                    ? appointment?.serviceGroups?.findIndex(item => item?.id === group?.id) === -1
                                    : true
                                ) // Để giữ CSS cho các group có services đc chọn
                            }
                            className="slide-fade-show"
                        >
                            <GroupIcon src={getGroupServiceImage(group?.id)} alt={group?.name} />
                            <GroupName>{group?.name}</GroupName>
                            <div style={{ flexGrow: 1 }}></div>
                            <GroupQuantity>{group?.list.length} services</GroupQuantity>
                            <i className={options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'}
                                style={{ color: 'rgba(0, 0, 0, 0.75)' }}
                            />
                        </CardServiceGroup>
                    )}
                >
                    <CardServicesWrapper groupId={group?.id}>
                        {group?.list?.map(service => (
                            <CardService key={service?.id}>
                                <ServiceName>{customizeServiceName(service?.id, service?.name)}</ServiceName>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <FlavorWrapper className="grid-container" length={service?.list?.length}>
                                        {service?.list?.map(flavor => (
                                            <div className="p-field-checkbox" key={flavor?.id} style={{ marginBottom: '0.4rem' }}>
                                                <Checkbox
                                                    inputId={`flavor${flavor?.id}`}
                                                    name="flavor"
                                                    value={flavor?.id}
                                                    onChange={(e) => handleServiceOptionsChange(e, flavor, service, group)}
                                                    checked={checkExisted(flavor?.id, service?.id, group?.id)}
                                                />
                                                <label htmlFor={`flavor${flavor?.id}`}>{flavor?.name}</label>
                                            </div>
                                        ))}
                                    </FlavorWrapper>
                                </div>
                            </CardService>
                        ))}
                    </CardServicesWrapper>
                </Panel >
            ))}
        </Wrapper >
    );
}

export default Step2Services;