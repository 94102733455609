import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 725px;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        background: url(/assets/images/testimonial-bg.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        /* background-attachment: fixed; */

        position: absolute;
        top: 0;
        left: 0;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    min-height: 775px;
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 3.375rem;
    position: relative;

    /* border: 1px solid blue; */
`

export const Heading = styled.h1`
    color: #333;
    font-size: 45px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;
    /* border: 1px solid black; */

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 10%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2.9rem;

    @media screen and (max-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`
export const FeedbackCard = styled.div`
    /* width: 300px;
    height: 363px; */
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #eeeeee;
    position: relative;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 150px;
        background: lightseagreen;
        /* opacity: 0.7; */
        background: linear-gradient(to bottom right, #ffedea 0%, #ffd3bb 75%, #FFC09F 100%);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    /* border: 1px solid lightseagreen; */
`
export const CurveBg = styled.div`
    width: 100%;
    height: 180px;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 68%;
        height: 100%;
        border-radius: 100% 100%;
        position: absolute;
        background-color: #eeeeee;
        transform: translate(-14%, 67%);
        z-index: 1;
    }
    &::after {
        content: '';
        display: block;
        width: 68%;
        height: 100%;
        border-radius: 80% 80%;
        position: absolute;
        background: linear-gradient(to right, #FFD5BF 0%, #FFC1A1 80%, #FFC0A0 100%);
        transform: translateX(60.5%);
        /* z-index: 1; */
    }
`
export const QuoteSymbol = styled.img`
    width: 75px !important;
    height: 69px !important;

    position: absolute !important;
    top: 30px !important;
    right: 33px !important;
    z-index: 1 !important;
`
export const Note = styled.span`
    color: #848484;
    font-size: 0.85rem;
    font-style: italic;

    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 1;

    & > a {
        text-decoration: none;
        color: #848484;
        margin-left: 0.25rem;
    }
`
export const CustomerInfo = styled.div`
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.6); */
    /* border: 1px solid black; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 85px;
    left: 0;
    z-index: 3;
`
export const Avatar = styled.img`
    width: 100px !important;
    height: 100px !important;
    border: 6px solid #eeeeee !important;
    border-radius: 100% !important;
`
export const Name = styled.h3`
    margin-top: 0.2rem;
`

export const FeedbackContent = styled.div`
    width: 100%;
    padding: 0.5rem 2.75rem 0.2rem;
    font-size: 1rem;
    font-style: italic;
    line-height: 1.5rem;
    text-align: justify;

    position: absolute;
    top: 230px;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1100px) {
        padding: 0.5rem 2rem 0.2rem;

    }
`
export const Rating = styled.img`
    width: 30% !important;
    height: 35px !important;
    object-fit: fill !important;

    position: absolute !important;
    bottom: 6px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
`