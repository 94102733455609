import styled from 'styled-components'

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
`
export const CollectionWrapper = styled.div`
    width: 100%;
`
export const CollectionSelector = styled.div`
    width: 100%;
`
export const ImgWrapper = styled.div`
    width: 100%;
    margin: 3.5rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem; */

    img {
        width: 220px;
        height: 220px;
        margin: 0 0 1rem;
        object-fit: cover;
        -o-object-fit: cover;
    }
`