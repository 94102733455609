import React from 'react'
import { FailedIcon, HelloImg } from '../../../../assets/images'
import { useCheckIn } from '../../hooks/CheckInContext'
import {
  BodyContainer, ImgHelloWrapper, CustomerName, CustomerInfo,
  Message, SalonName, FailureWrapper, IconFailed
} from './Step3Items'

function Step3() {
  const { message, customerInfo } = useCheckIn()

  const SuccessScreen = () => (
    <>
      <ImgHelloWrapper>
        <img src={HelloImg} />
      </ImgHelloWrapper>
      <CustomerName>{customerInfo?.fullName || 'Guest'}</CustomerName>

      <CustomerInfo>
        <div>
          Your ID <br />
          <p className='id'>{customerInfo?.id ?? 0}</p>
        </div>
        <div>
          Current points <br />
          <p className='points'>{customerInfo?.currentPoints ?? 0}</p>
        </div>
      </CustomerInfo>

      <Message>
        We hope you will enjoy your time at
        <SalonName>
          <span className="logo-deluxe">dELUXE</span>
          <span className="logo-nails">
            nAILS
            <span className="logo-and">&amp;</span>
            sPA
          </span>
        </SalonName>
      </Message>
    </>
  )

  const FailureScreen = () => (
    <FailureWrapper>
      <IconFailed src={FailedIcon} alt='' />
      <p>{message?.split('.')[0]}!</p>
      <p>{message?.split('.')[1]}.</p>
    </FailureWrapper>
  )

  return (
    <BodyContainer>
      {!message ? <SuccessScreen /> : <FailureScreen />}
    </BodyContainer>
  )
}

export default Step3