import styled from 'styled-components'
import { BsGiftFill } from 'react-icons/bs'

export const GiftsZone = styled.div`
  min-width: 300px;
  width: 300px;
  height: 275px;
  /* height: 306px; */
  padding: 1rem;

  position: relative;

  /* background-color: #FFC09F50;
  border: 2px solid #F9C892;
  border-radius: 4px; */
  /* background-image: url('assets/images/sticky_note_2.png'); */

  &.gift-col {
    display: none;
  }

  & > h3 {
    /* margin: 0.5rem 0 1.6rem;
    text-align: center; */

    font-family: 'BlackberryJam';
    font-size: 1.3rem;
    font-weight: 500;

    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);

    position: absolute;
    top: 72px;
    left: 45.5px;
    z-index: 2;
  }

  & > ul {
    list-style: '✔  ';
    font-weight: 500;
    line-height: 1.9;

    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);

    position: absolute;
    top: 120px;
    left: 95px;
    right: 40px;
    z-index: 2;

    img {
      width: 24px;
      height: 24px;
      margin-top: 8px;
      margin-left: -24px;
    }
  }

  @media screen and (max-width: 850px) {
    margin-top: 2rem;

    &.gift-row {
      display: none;
    }
    &.gift-col {
      display: block;
      margin-top: 3rem;
    }
  }
`

export const IconGift = styled(BsGiftFill)`
  font-size: 1.2rem;

  &:first-of-type {
    margin-right: 5px;
  }
  &:last-of-type {
    margin-left: 5px;
  }
`

export const Background = styled.img`
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`