import React, { useRef, useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import { ModalContents } from './components'
import { Backdrop } from './CheckInModalItems'
import CheckInProvider from './hooks/CheckInContext'

const CheckInModal = ({ open, setOpen }) => {
  const modelRef = useRef()

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    if (open) {
      document.body.style.overflow = 'hidden'
    }
    else
      document.body.style.overflow = 'auto'
  }, [open])

  const animationShowModal = useSpring({
    config: { duration: 250 },
    opacity: open ? 1 : 0,
    transform: open ? 'translateY(0%)' : 'translateY(-100%)'
  })

  const closeModalOnClickedBackdrop = e => {
    if (modelRef.current === e.target)
      setOpen(false)
  }

  const keyPress = useCallback(e => {
    if (e.key === 'Escape' && open)
      setOpen(false)
  }, [open, setOpen])

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => {
      document.removeEventListener('keydown', keyPress)
    };
  }, [keyPress])

  return (
    <CheckInProvider>
      {open && (
        <Backdrop ref={modelRef} onClick={closeModalOnClickedBackdrop}>
          <animated.div style={animationShowModal}>
            <ModalContents setOpen={setOpen} />
          </animated.div>
        </Backdrop>
      )}
    </CheckInProvider>
  );
}

export default CheckInModal