import styled from 'styled-components';

export const BodyContainer = styled.div`
    width: 100%;
    /* width: 420px; */
    height: 100%;
`
export const ImgHelloWrapper = styled.div`
  width: 100%;
  max-height: 125px;
  
  display: flex;
  justify-content: center;

  & > img {
    height: 100%;
    max-height: 125px;
  }
`
export const CustomerName = styled.h3`
  font-size: 28px;
  text-align: center;
  margin-bottom: 40px;
`

export const CustomerInfo = styled.div`
  width: 100%;
  margin-bottom: 45px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 18px;
  font-weight: 500;

  div {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    p {
      width: fit-content;
      /* margin-left: 5px; */
      margin: 10px 0 0;
      padding: 8px 24px 10px;
  
      border: 2px solid;
      border-radius: 50%;
  
      font-weight: 700;
      font-size: 32px;
      text-align: center;
  
      &.id {
        color: #ffd000;
        background-color: rgba(255, 208, 0, 0.12);
      }
      &.points {
        color: #01A601;
        background-color: rgba(1, 166, 1, 0.12);
      }
    }
  }
`

export const Message = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;

  font-family: 'BlackberryJam';
  font-size: 22px;
  font-weight: 500;
  word-spacing: 5px;
`
export const SalonName = styled.div`
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  
  span {
    color: #171416f0;
    font-family: 'Plaza', sans-serif;
  }
  .logo {
    font-size: 40px;
  }
`

export const FailureWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  text-align: center;
  font-size: 18px;
`
export const IconFailed = styled.img`
    width: 100px;
    height: 100px;
    margin: 3rem 0;
`