import styled from 'styled-components';

export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const Title = styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;

    span {
        font-size: 25px;
    }
`