import styled from 'styled-components'
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
    background: rgba(30, 32, 34, 0.98);
    /* background: ${({ scrollNav }) => (scrollNav ? 'rgba(30, 32, 34, 0.98)' : 'transparent')}; */
    width: 100%;
    height: 60px;
    /* margin-top: -60px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: 0.8s all ease;
`

export const NavbarContainer = styled.div`
    /* background: transparent; */
    width: 100%;
    height: 60px;
    display: flex;
    /* justify-content: space-between; */
    z-index: 1;     // cũ: 1, mới: 10???
    padding: 0;
    max-width: 1200px;

    @media screen and (max-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`
export const NavLogo = styled(Link)`
    color: #FFC09F;     //#94c6f4, #592A17, #fc94a5
    justify-self: flex-start;
    /* flex-grow: 1; */
    cursor: pointer;
    display: flex;
    align-items: center;
    /* margin-left: 2em; */
    /* font-weight: bold; */
    text-decoration: none;
    opacity: 1;
    /* border: 1px solid black; */
    /* font-size: 1.5rem; */

    span {
        /* font-family: 'Destain'; */
        font-family: 'Plaza', sans-serif;
    }
    .logo-deluxe {
        font-size: 3.2rem;
        /* margin-top: 0.1rem; */
    }
    .logo-nails {
        font-size: 2rem;
        margin-top: 0.8rem;
        margin-left: 0.375rem;
    }
    .logo-and {
        font-weight: 100;
        font-size: 1.3rem;
        margin: 0 0.15rem;
    }

    @media screen and (max-width: 768px) {
        /* margin-left: 24px; */

        .logo-deluxe {  // xem responsive trên web khác trên real device
            margin-top: 1.5rem;
        }
        .logo-nails {
            margin-top: 1.6rem;     // 0.8rem     // xem responsive trên web khác trên real device
            /* margin-left: 0.375rem; */
        }
        .logo-and {
            margin: 0 0.15rem;
        }
    }
    @media screen and (max-width: 480px) {
        .logo-deluxe {  // xem responsive trên web khác trên real device
            margin-top: 1.5rem;
        }
        .logo-nails {
            margin-top: 1.6rem;     // 0.8rem     // xem responsive trên web khác trên real device
            /* margin-left: 0.375rem; */
        }
        .logo-and {
            margin: 0 0.15rem;
        }
    }
`
export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 780px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -4px;
        transform: translate(-100%, 60%);
        font-size: 1.6rem;
        cursor: pointer;
        color: #FFC09F;     //#592A17
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 1rem;
    /* border: 1px solid black; */

    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
    @media screen and (max-width: 900px) {
        max-width: calc(100vw - 210px - 110px - 80px);  // brandName: 210px, btnBooking: 110px, gap between brandName & menu: 80px
        overflow: auto hidden;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    @media screen and (max-width: 780px) {
        display: none;
    }
`
export const NavItem = styled.li`
    height: 60px;
`
export const NavLinks = styled(Link)`
    color: #FFC09F;     //#522119
    font-weight: 600;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.15s;

    /* &:hover, &.active {
        border-bottom: 4px solid #FFC09F;
    } */
    &:hover {
        padding-bottom: 0.5rem;
        border-bottom: 6px solid #FFC09F;
    }
    &.active {
        border-bottom: 4px solid #FFC09F;
    }
    
    @media screen and (max-width: 1100px) {
        padding: 0 0.8rem;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 0.6rem;
    }
    @media screen and (max-width: 860px) {
        padding: 0 0.4rem;
    }
    @media screen and (max-width: 820px) {
        padding: 0 0.5rem;
    }
`

export const BookingZone = styled.nav`
    display: flex;
    align-items: center;
    /* margin-right: 24px; */

    @media screen and (max-width: 780px) {
        display: none;
    }
`
export const BookingBtn = styled.button`
    color: #171416;
    background: #FFC09F;    //#f79b71, #F64A5F
    border-radius: 50px;
    white-space: nowrap;
    padding: 10px 20px;
    /* color: #FFFEFE; */
    /* text-transform: uppercase; */
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
    }
    
    @media screen and (max-width: 975px) {
        font-size: 14px;
        padding: 9px 18px;
    }
`

export const BtnCheckInWrapper = styled.div`
    display: flex;
    align-items: center;
    /* margin-right: 24px; */

    @media screen and (max-width: 780px) {
        display: none;
    }
`
export const BtnCheckIn = styled.button`
    color: #FFC09F;
    background: none;
    border-radius: 50px;
    border: 2px solid #FFC09F;
    white-space: nowrap;
    padding: 8px 18px;
    margin-right: 0.5rem;
    /* color: #FFFEFE; */
    /* text-transform: uppercase; */
    font-size: 16px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #171416;
        background: #ffcbaf;
        border-color: #ffcbaf;
    }
    
    @media screen and (max-width: 975px) {
        font-size: 14px;
        padding: 7px 15px;
    }
`