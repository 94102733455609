import styled from 'styled-components'
import { Element as ScrollEl } from 'react-scroll';

export const LoadingIcon = styled.i`
    font-size: 4rem;
    color: gray;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
`

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid #3D6EF7; */

    @media screen and (max-width: 1200px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    @media screen and (max-width: 768px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
`

export const RowOdd = styled(ScrollEl)`
    width: 100%;
    /* min-height: 570px; */
    display: grid;
    grid-template-columns: 9fr 5fr;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 11fr 6fr;
    }
    @media screen and (max-width: 960px) {
        grid-template-columns: 2fr 1fr;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const Col = styled.div`
    width: 100%;
    /* height: 100%; */
    overflow-x: hidden;
    position: relative;
`
export const Title = styled.h2`
    font-style: italic;
    color: #333;
    font-size: 2.4rem;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: left;
    
    padding: 3rem 0 0.75rem 0.2rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        width: 80px;
    }

    &.even-row {
        padding-top: 2.35rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        max-width: 390px;
    }
    @media screen and (max-width: 640px) {
        font-size: 34px;
        max-width: 250px;
    }
    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`
export const ImgRight = styled.img`
    width: 85%;
    max-height: 250px;
    padding: 0;
    /* border: 1.2rem solid white; */
    /* border: 3px solid lightskyblue; */
    border-radius: 50px 50px 50px 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

    position: absolute;
    top: 55px;
    right: 25px;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }
    @media screen and (max-width: 960px) {
        width: 93%;
        top: 50px;
        right: 15px;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const BackgroundRight = styled.div`
    width: 160px;
    height: 150px;
    /* border: 0.75rem solid #FFC09F; */
    background: url(/assets/images/squares-bg.png) repeat;
    
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 0;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const RowEven = styled(ScrollEl)`
    width: 100%;
    /* min-height: 570px; */
    margin-top: 0.6rem;
    display: grid;
    grid-template-columns: 5fr 9fr;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 6fr 11fr;
    }
    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr 2fr;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const ImgLeft = styled.img`
    width: 82%;
    max-height: 260px;
    padding: 0;
    border-radius: 50px 0 50px 50px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        top: 35px;
    }
    @media screen and (max-width: 960px) {
        width: 85%;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const BackgroundLeft = styled.div`
    width: 160px;
    height: 150px;
    /* width: 50%;
    height: 66%; */
    background: url(/assets/images/squares-bg.png) repeat;
    
    position: absolute;
    top: 15px;
    right: 50px;
    z-index: 0;

    @media screen and (max-width: 1200px) {
        right: 40px;
    }
    @media screen and (max-width: 1024px) {
        right: 35px;
    }
    @media screen and (max-width: 960px) {
        right: 25px;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`

// Only display when max-width = 768px
export const ImgSmall = styled.img`
    display: none;

    @media screen and (max-width: 768px) {
        width: ${({ isEven }) => isEven ? 'auto' : '250px'};
        height: ${({ isEven }) => isEven ? '175px' : 'auto'};
        padding: 0;
        border-radius: 50px 50px 50px 0;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

        display: block;
        position: absolute;
        top: 50px;
        right: 20px;
        z-index: 1;
    }
    @media screen and (max-width: 640px) {
        width: ${({ isEven }) => isEven ? 'auto' : '220px'};
        height: ${({ isEven }) => isEven ? '150px' : 'auto'};
    }
    @media screen and (max-width: 480px) {        
        top: ${({ isEven }) => isEven ? '102px' : '110px'};
        z-index: 0;
    }
`
export const BackgroundSmall = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        width: 160px;
        height: 120px;
        /* border: 0.75rem solid #FFC09F; */
        background: url(/assets/images/squares-bg.png) repeat;
        
        display: block;
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 0;
    }
    @media screen and (max-width: 640px) {
        width: 120px;
        height: 120px;
    }
    @media screen and (max-width: 480px) {        
        width: 100px;
        height: 110px;
        top: 80px;
    }
`

export const ListServices = styled.div`
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    font-size: 1rem;
    line-height: 1.6rem;

    @media screen and (max-width: 768px) {
        margin-top: ${({ isEven }) => isEven ? '9.3rem' : '8rem'};
    }
    @media screen and (max-width: 640px) {
        margin-top: ${({ isEven, isArtNails }) => isEven ? '7.9rem' : isArtNails ? '4.2rem' : '7rem'};
    }
    @media screen and (max-width: 480px) {
        margin-top: ${({ isEven, isArtNails }) => isEven ? '11.8rem' : isArtNails ? '11.5rem' : '11.4rem'};
    }
`
export const ServiceWrapper = styled.div`
    width: 100%;
    margin-bottom: 1.25rem;
    
    & .odd-row {
        padding-right: 1.2rem;
    }
`
export const Part1 = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    
    &::before {
        content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
        float: left;
        width: 0;
        white-space: nowrap;
        /* overflow-x: hidden; */
    }

    @media screen and (max-width: 960px) {
        &::before {
            content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
        }
    }
    @media screen and (max-width: 640px) {
        &::before {
            content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
        }
    }
`
export const Name = styled.h4`
    font-size: 1.1rem;
    padding-right: 0.35em;
    background: #fff;
    /* margin-bottom: 0.8rem; */
    
    @media screen and (max-width: 576px) {
        max-width: 400px;
    }
`
export const NoteTag = styled.div`
    /* margin-left: 0.5rem; */
    width: 7.25rem;
    padding: 0 0.5em ;
    background: #fff;
    position: relative;

    &.outside {
        margin-top: 0.25rem;
        padding: 0;
    }
`
export const Note = styled.div`
    width: 6.5rem;
    height: 30px;
    padding: 0.2rem 0.7rem 0.25rem 0.5rem;
    font-style: italic;
    border-radius: 100px;
    background-color: #FFC09F;

    position: absolute;
    top: -3px;
    
    i {
        margin-right: 0.15rem;
    }

    &.outside {
        position: relative;
        top: unset;
    }
`
export const Price = styled.h4`
    font-size: 1.1rem;
    margin-left: auto;
    padding-left: 0.3rem;
    background: #fff;

    &.odd-row {
        padding-right: 1.2rem;
    }

    @media screen and (max-width: 768px) {
        &.odd-row {
            padding-right: 0;
        }
    }
`

export const Part2 = styled.div`
    width: 100%;
    margin-top: 0.8rem;
    margin-bottom: 2.2rem;
`
export const Description = styled.div`
    line-height: 1.6rem;
    font-size: 1rem;
    font-style: italic;
`
export const DescriptionPart = styled.p`
    margin-bottom: 0.5rem;

    &.des-first-part {
        /* color: #FFC09F; */
        color: #c08c7c;
        font-weight: 600;
        margin-bottom: 0.15rem;
    }
`
export const Flavors = styled.div`
    margin-top: 0.8rem;
    & span {
        color: #c08c7c;
        font-weight: 600;
        font-style: italic;
    }
    & ul {
        padding-left: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 960px) {
        & ul {
            grid-template-columns: 3fr 2fr;
        }
    }
    @media screen and (max-width: 500px) {
        & ul {
            grid-template-columns: 1fr;
        }
    }
`