import { default as Api } from './Apis';

// Step 2: Get all service groups, services & service options
export async function getServicesMenu() {
    const response = await Api.get('/services')
    // const data = await response.data
    return response     // Ở đây ko cần ".data" nữa vì đã config chung ở trong interceptor rồi.
}

// Step 3: Get list technicians
export async function getTechnicians() {
    const response = await Api.get('/technicians')
    return response
}

// Step 5: Book an appointment
export async function bookAppointment(appointment) {
    const response = await Api.post('/appointments', appointment)
    return response
}

//=====================================================================
// API for Opening hours/ (available time slots for booking)
export async function getSlotsByDay(day) {
    const response = await Api.get(`/time?date=${day}`)
    return response
}