import React, { useEffect, useState } from 'react'
import { ScrollTop } from 'primereact/scrolltop'
import { Header, GeneralNavbar, Sidebar, PageCover, Footer, CheckInModal } from '../components'
import { Container, PageBody } from './LayoutStyles'
import { useQuery } from '../hooks/useQuery'

const Layout = (props) => {
    const [query, setQuery] = useQuery()

    const { children, title, coverImgSrc, isNotFound } = props

    const [showSidebar, setShowSidebar] = useState(false);
    const [showMdBooking, setShowMdBooking] = useState(false);
    const [showMdCheckIn, setShowMdCheckIn] = useState(false);

    useEffect(() => {
        if (query.get('checkin') === 'true' && !showMdCheckIn)
            setShowMdCheckIn(true)
    }, [query])

    useEffect(() => {
        if (showMdCheckIn)
            setQuery({ 'checkin': true })
        else
            setQuery({})
    }, [showMdCheckIn])

    return (
        <Container>
            <Header />
            <GeneralNavbar
                showModal={showMdBooking}
                setShowModal={setShowMdBooking}
                setShowMdCheckIn={setShowMdCheckIn}
                setShowSidebar={setShowSidebar}
            />
            <Sidebar
                setShowModal={setShowMdBooking}
                setShowMdCheckIn={setShowMdCheckIn}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />

            {!isNotFound &&
                <PageCover title={title} coverImgSrc={coverImgSrc} />
            }
            <PageBody>
                {children}
            </PageBody>
            <Footer />
            <ScrollTop />

            <CheckInModal open={showMdCheckIn} setOpen={setShowMdCheckIn} />
        </Container>
    );
}

export default Layout;
