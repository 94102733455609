import React from 'react'
import {
    Container, Wrapper, LinksContainer, LinksWrapper, LinkItems, LinkTitle, FooterLink,
    SocialMediaWrap, SocialIcons, SocialIconLink, CopyrightContainer, Copyright, DecoratedDivider,
    InfoContainer, SalonName, SalonDescription, SalonInfo, InfoIcon, InfoContent,
    OpeningHours, OpeningHour, DescriptionRow
} from './FooterItems'
import { FaFacebook, FaInstagram, FaGooglePlus, FaYelp, FaPhoneAlt, } from 'react-icons/fa'
import { MdPlace, MdEmail } from 'react-icons/md'
import { IoTime } from 'react-icons/io5'
import { useApp } from '../../hooks/AppContext'
import { animateScroll as scroll } from 'react-scroll'

function Footer() {
    const { salonInfo, isActive, setIsActive } = useApp()

    return (
        <Container>
        {/* <footer> */}
            {/* <DecoratedDivider /> */}
                <Wrapper>
                    <InfoContainer className="salonBrief">
                        <SalonName to='/' onClick={() => scroll.scrollToTop()}>{salonInfo?.name}
                            <span className="logo-deluxe">dELUXE</span>
                            <span className="logo-nails">
                                nAILS
                                <span className="logo-and">&amp;</span>
                                sPA
                            </span>
                        </SalonName>
                        <SalonDescription>
                            {salonInfo?.description}
                            <DescriptionRow>Complimentary Drinks &amp; Wine Are Provided</DescriptionRow>
                            <DescriptionRow>Walk-ins Welcome</DescriptionRow>
                            <DescriptionRow>Gift Cards Available</DescriptionRow>
                        </SalonDescription>
                        <SocialMediaWrap>
                            {/* <h4>Follow us </h4> */}
                            <SocialIcons>
                                <SocialIconLink href='//www.facebook.com/Deluxe-Nails-Spa-Coppell-537454632943839/' target='_blank' aria-label="Facebook">
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink href='//www.google.com/search?q=Deluxe+Nails+%26+Spa+Coppell&rlz=1C1ONGR_enVN952VN952&biw=1536&bih=731&ei=CsbuYISSAtSRoATnxZrwBQ&oq=Deluxe+Nails+%26+Spa+Coppell&gs_lcp=Cgdnd3Mtd2l6EAMyCwguEMcBEK8BEJMCMgYIABAWEB46BwgAEEcQsAM6EQgAELADEIoDELcDENQDEOUCSgQIQRgAUJIdWOgeYN8iaAFwAngAgAFpiAG6ApIBAzAuM5gBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwiEv9qqtuLxAhXUCIgKHeeiBl4Q4dUDCA4&uact=5' target='_blank' aria-label="Google">
                                    <FaGooglePlus />
                                </SocialIconLink>
                                <SocialIconLink href='#' aria-label="Instagram">    {/** target='_blank' */}
                                    <FaInstagram />
                                </SocialIconLink>
                                <SocialIconLink href='//www.yelp.com/biz/deluxe-nails-and-spa-coppell' target='_blank' aria-label="Yelp">
                                    <FaYelp />
                                </SocialIconLink>
                            </SocialIcons>
                        </SocialMediaWrap>
                    </InfoContainer>
                    <InfoContainer className="contact-info">
                        <SalonInfo>
                            <InfoIcon className="phone"><FaPhoneAlt /></InfoIcon>
                            <InfoContent>{salonInfo?.phone}(972) 304-1199</InfoContent>
                        </SalonInfo>
                        <SalonInfo>
                            <InfoIcon className="email"><MdEmail /></InfoIcon>
                            <InfoContent className="email">{salonInfo?.email}deluxenails.dev@gmail.com</InfoContent>
                        </SalonInfo>
                        <SalonInfo>
                            <InfoIcon className="address"><MdPlace /></InfoIcon>
                            <InfoContent className="address">{salonInfo?.address}143 South Denton Tap Road, Suite 160 Coppell, TX 75019</InfoContent>
                        </SalonInfo>
                        <SalonInfo>
                            <InfoIcon className="hour"><IoTime /></InfoIcon>
                            <OpeningHours>{salonInfo?.openingHours}
                                <OpeningHour>
                                    <p>Mon - Fri</p>
                                    <p>9:30 AM - 7:30 PM</p>
                                </OpeningHour>
                                <OpeningHour>
                                    <p>Saturday</p>
                                    <p>9:00 AM - 7:00 PM</p>
                                </OpeningHour>
                                <OpeningHour>
                                    <p>Sunday</p>
                                    <p>11:30 AM - 5:30 PM</p>
                                </OpeningHour>
                            </OpeningHours>
                        </SalonInfo>
                    </InfoContainer>

                    <LinksContainer>
                        <LinksWrapper>
                            <LinkItems>
                                <LinkTitle>Explore</LinkTitle>
                                <FooterLink to='/about' onClick={() => setIsActive({ ...isActive, pageName: 'about' })}>About Us</FooterLink>
                                <FooterLink to='/services' onClick={() => setIsActive({ ...isActive, pageName: 'services' })}>Services</FooterLink>
                                <FooterLink to='/gallery' onClick={() => setIsActive({ ...isActive, pageName: 'gallery' })}>Gallery</FooterLink>
                                <FooterLink to='/contact' onClick={() => setIsActive({ ...isActive, pageName: 'contact' })}>Contact Us</FooterLink>
                                {/* <FooterLink to='/feedback'>Feedback</FooterLink> */}
                            </LinkItems>
                            <LinkItems>
                                <LinkTitle>Others</LinkTitle>
                                <FooterLink to='/rewards' onClick={() => setIsActive({ ...isActive, pageName: 'rewards' })}>Rewards</FooterLink>
                                <FooterLink to='/careers' onClick={() => setIsActive({ ...isActive, pageName: '' })}>Careers</FooterLink>
                                <FooterLink to='/term' onClick={() => setIsActive({ ...isActive, pageName: '' })}>Term of Use</FooterLink>
                            </LinkItems>
                        </LinksWrapper>
                    </LinksContainer>

                    <CopyrightContainer>
                        <Copyright>
                            <span className="copyright">Copyright © 2021 <strong style={{ color: '#825142' }}>Deluxe Nails &amp; Spa</strong>.</span>
                            <span className="reserved"> All rights reserved</span>
                            <span className="tab-space"> &ensp; | &ensp; </span>
                            <span className="dev-by">Developed by <strong style={{ color: '#825142' }}>HG Team</strong></span>
                        </Copyright>
                    </CopyrightContainer>
                </Wrapper>
        {/* </footer> */}
        </Container>
    )
}

export default Footer
