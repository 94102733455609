import React from 'react'
import { Container, Wrapper, Heading, Row, TechnicianCard, InfoWrapper } from './TechniciansSectionItems'
import { technicians } from '../data'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles (of the library)
import "swiper/swiper.min.css"
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import './slider.css'
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from 'swiper'

SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

function TechniciansSection() {
    return (
        <section id='technicians'>
            <Container>
                <Wrapper>
                    <Heading data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">
                        Meet our Team
                    </Heading>
                    <Row>
                        <Swiper
                            loop={true} navigation={true} pagination={{ clickable: true }}
                            effect='coverflow'
                            grabCursor={true} centeredSlides={true} slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true
                            }}
                            className="mySwiper"
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {technicians?.map(staff => (
                                <SwiperSlide key={staff?.id}>
                                    <TechnicianCard>
                                        <img src={staff?.avatar} alt={`${staff?.name} avatar`} />
                                        <InfoWrapper>
                                            <h3>{staff?.name}</h3>
                                        </InfoWrapper>
                                    </TechnicianCard>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </Wrapper>
            </Container>
        </section>
    )
}

export default TechniciansSection
