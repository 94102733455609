import React, { useMemo, useState } from 'react'
import HorizontalTimeline from "react-horizontal-timeline"
import { classNames } from 'primereact/utils'
import {
  HistoryContainer,
  HistoryWrapper,
  HistoryTimeline as Timeline,
  HistoryCard,
  Row, Title, Content
} from './HistoryTimelineItem'
import { parseDateToString } from '../../../utils/DateTime'
import { useWindowSize } from '../../../hooks/useWindowSize'
// import useResponsive from '../../../hooks/useResponsive'

function HistoryTimeline(props) {
  const { data } = props
  // const [, isLargeMobileScreen] = useResponsive()

  // fakeData vì ko có mạng
  // const data = [
  //   {
  //     id: 1,
  //     spa: 'deluxe',
  //     checkinTime: '2020-05-20 13:20:00',
  //     checkoutTime: '2020-05-20 13:20:00',
  //     name: 'Ha',
  //     discount: 0,
  //     reward: 200,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'nail-club',
  //     checkinTime: '2021-03-20 13:20:00',
  //     checkoutTime: '2021-03-20 13:20:00',
  //     name: 'Dev 1',
  //     discount: 0,
  //     reward: 136,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'deluxe',
  //     checkinTime: '2021-10-20 13:20:00',
  //     checkoutTime: '2021-10-20 13:20:00',
  //     name: 'Ha',
  //     discount: 300,
  //     reward: 175,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'deluxe',
  //     checkinTime: '2021-01-20 13:20:00',
  //     checkoutTime: '2021-01-20 13:20:00',
  //     name: 'Dev 1',
  //     discount: 0,
  //     reward: 80,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'nail-club',
  //     checkinTime: '2022-04-20 13:20:00',
  //     checkoutTime: '2022-04-20 13:20:00',
  //     name: 'Ha',
  //     discount: 0,
  //     reward: 224,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'deluxe',
  //     checkinTime: '2022-07-20 13:20:00',
  //     checkoutTime: '2022-07-20 13:20:00',
  //     name: 'Ha',
  //     discount: 0,
  //     reward: 210,
  //     status: 'CHECK-OUT'
  //   },
  //   {
  //     id: 1,
  //     spa: 'nail-club',
  //     checkinTime: '2022-10-20 13:20:00',
  //     checkoutTime: '2022-10-20 13:20:00',
  //     name: 'Dev 1',
  //     discount: 0,
  //     reward: 100,
  //     status: 'CHECK-IN'
  //   },
  // ]
  const [windowWidth] = useWindowSize()
  const [currentYear, setCurrentYear] = useState(0)

  const allYears = useMemo(() => {
    return Array.from(new Set(data?.reverse()?.map(item =>
      parseDateToString(item?.checkinTime, 'YYYY'))
    ))
  }, [data])

  const historyData = useMemo(() => {
    return data?.reverse()?.filter(item =>
      parseDateToString(item?.checkinTime, 'YYYY') === allYears[currentYear]
    )
  }, [data, currentYear])

  const getSpaName = spa => {
    return spa === 'deluxe' ? 'Deluxe Nails & Spa Coppell' : 'Nail Club'
  }

  const marker = item => (
    <span className="custom-marker p-shadow-4"
      style={
        item?.status !== 'CHECK-OUT' ? { backgroundColor: '#02B4F5' }
          : item?.discount > 0
            ? { backgroundColor: '#05E177' } : { backgroundColor: '#F9C892' }}
    >
      <i className={classNames('pi', {
        'pi-dollar': item?.status === 'CHECK-OUT' && item?.discount <= 0,
        'pi-percentage': item?.status === 'CHECK-OUT' && item?.discount > 0,
        'pi-star-fill': item?.status !== 'CHECK-OUT'
      })} />
    </span>
  )

  const content = item => (
    <HistoryCard
      className={classNames({
        "redeem": item?.discount > 0,
        "current": item?.status !== 'CHECK-OUT'
      })}
    >
      <Row>
        <Title>Name: </Title>
        <Content>{item?.name}</Content>
      </Row>
      {item?.status === 'CHECK-OUT' && (
        <Row>
          <Row>
            <Title>Reward: </Title>
            <Content>{item?.reward}</Content>
          </Row>
          <Row style={{ marginLeft: '4rem' }}>
            <Title>Redeem: </Title>
            <Content>{item?.discount}</Content>
          </Row>
        </Row>
      )}

      <Row>
        <Content>
          {item?.status === 'CHECK-OUT' ? (
            <>
              Visited <strong><em>{getSpaName()}</em></strong> from {''}
              {parseDateToString(item?.checkinTime, 'HH:mm')} ~ {parseDateToString(item?.checkoutTime, 'HH:mm')}
            </>
          )
            : item?.status === 'CHECK-IN' ? (
              <strong style={{ display: 'block', marginTop: '12px' }}>
                <em>
                  Thanks for choosing us & Enjoy your time here! ^ ^
                </em>
              </strong>
            )
              : item?.status === 'REQUEST' ? (
                <em style={{ display: 'block', marginTop: '12px' }}>
                  <strong style={{ display: 'block', marginBottom: '6px' }}>
                    Thanks for choosing us! ^ ^
                  </strong>
                  Please contact the staff at the counter to let you check-in.
                </em>
              ) : <></>}
        </Content>
      </Row>
    </HistoryCard >
  )

  const oppositeContent = item => parseDateToString(item.checkinTime, 'MMMM DD')

  if (!data) return <></>

  return (
    <HistoryContainer>
      <h3>My Rewards History</h3>

      <HistoryWrapper>
        {/* <em style={{ fontColor: '#848484' }}>Coming soon . . .</em> */}

        <div style={{
          width: windowWidth >= 1024 ? '45%' : windowWidth >= 768 ? '64%' : '80%',
          height: '100px', margin: '0 auto'
        }}>
          <HorizontalTimeline
            getLabel={allYears => allYears}
            index={currentYear}
            indexClick={index => setCurrentYear(index)}
            values={allYears}
            maxEventPadding={0}
            minEventPadding={0}
            styles={{
              background: "white",
              foreground: "#F9C892",
              outline: "#C8C8C8",
              margin: "0 auto",
              textAlign: "center"
            }}
          />
        </div>

        {historyData?.length > 0 ? (
          <Timeline value={historyData}
            content={content}
            opposite={oppositeContent}
            marker={marker}
            className="reward-timeline"
          />
        ) : (
          <>
            {/* <p>Hmmm... It seem that you have not created any reward accounts.</p> */}
            <p>Hmmm... It seem that you have not visited Deluxe Nails & Spa before.</p>
            <span>Join us now to get many attractive rewards!!! ^ ^</span>
          </>
        )}

        {/* Sau này update lên là với isLargeMobileScreen === true thì để history dạng vertical lun */}
        {/* Nó là 1 version khác á */}
      </HistoryWrapper>
    </HistoryContainer>
  )
}

export default HistoryTimeline