import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import styled from 'styled-components'

export const RequestFormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
export const NoticeMsg = styled.p`
  font-size: 1rem;
  font-weight: normal;
  font-style: italic;
  line-height: 26px;

  margin-bottom: 2.5rem;
`
export const FormWrapper = styled.form`
  max-width: 420px;
  width: 100%;
  padding: 0;

  & > * {
    padding-left: 0;
    padding-right: 0;
  }
  & > div[class="p-field p-col-12"] {
    margin-bottom: 0.8rem;

    & span {
      height: 38px;
    }
    & span[class*="p-float-label"] input {
      padding-left: 8px;
      padding-right: 8px;
    }
    & span[class="p-float-label p-input-icon-right"] {
      i {
        right: 10px;
      }
      input {
        padding-right: 30px;
      }
    }
  }
  & > div[class*="reward-form__note"] {
    margin-top: -4px;
  }
`
export const SuggestedNameWrapper = styled.div`
  /* width: 100%; */
  margin-top: 6px;
  margin-left: 40px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 6px;
`
export const SuggestedName = styled.div`
  padding: 4px 10px;

  font-size: 0.85rem;
  color: #fff;
  background-color: #F9C892;
  border-radius: 3px;

  &:hover {
    color: #333;
    font-weight: 500;
    cursor: pointer;

  }
`

export const NoteTextarea = styled(InputTextarea)`
  width: 100%;
  min-width: 100%;
  min-height: 38px;
  border-radius: 0 3px 3px 0 !important;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`
// export const BtnSend = styled(Button)`
//   padding: 7px 13px;
//   margin: 0;

//   color: #171416;
//   background: #F9C892;
//   border-radius: 3px;
//   border: 2px solid #F9C892;

//   &:hover {
//     color: #171416 !important;
//     background: #ffcbaf !important;
//     border: 2px solid #ffcbaf !important;
//     transition: all 0.2s ease-in-out;
//   }
// `
export const BtnViewHistory = styled(Button)`
  padding: 7px 13px;

  color: #171416;
  background: #F9C892;
  border-radius: 3px;
  border: 2px solid #F9C892;
  /* color: #fdb970;
  background: none;
  border-radius: 3px;
  border: 2px solid #F9C892; */
  
  &:hover, &:focus, &:active {
    color: #171416 !important;
    background: #ffcbaf !important;
    border: 2px solid #ffcbaf !important;
    transition: all 0.2s ease-in-out;
    /* color: #F9C892 !important;
    border: 2px solid #F9C892 !important;
    background: #ffc19f26 !important;
    transition: all 0.2s ease-in-out; */
  }
`

export const Message = styled.p`
  font-size: 0.95rem;
  font-style: italic;
  color: ${({ isSuccess }) => (isSuccess ? '#01A601' : '#d32f2f')};
  /* margin-left: 1rem; */
`