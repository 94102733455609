import styled from 'styled-components';
import { ToggleButton } from 'primereact/togglebutton';
import { Calendar } from 'primereact/calendar';

export const Wrapper = styled.div`
    /* height: 100%;
    overflow-y: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    /* border: 1px solid black; */
`

export const DatePicker = styled(Calendar)`
    margin-top: 0.25rem;
    border: none;
`
export const TimePicker = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`
export const TimeCard = styled(ToggleButton)`
    width: 80px;
    padding: 0.4rem 0;
    margin: 0.2rem 0.25rem;
    font-size: 0.9rem;
    font-weight: ${({ checked }) => checked ? 600 : 500};

    color: ${({ checked }) => checked ? '#171416 !important' : '#B87E70 !important'};
    background-color: ${({ checked }) => checked ? '#FFC09F !important' : 'none'};
    border: ${({ checked }) => checked ? '1px solid #FFC09F !important' : '1px solid #B87E70 !important'};

    &:hover {
        color: #171416 !important;
        background-color: #FFC09F !important;
        border-color: #FFC09F !important;
        transition: all 50ms;
    }
`
export const MsgText = styled.div`
    font-size: 0.9rem;
    /* font-style: italic; */
    color: rgba(0, 0, 0, 0.7);
    margin-left: 1rem;
`