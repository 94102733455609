import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 400px;
    background-color: #fafafa;
    color: #333;
    /* font-size: 10rem; */
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.45;
        background: url(/assets/images/wcu-bg.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
    /* opacity: 0.5; */
    /* border: 1px solid black; */
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    /* min-height: 400px; */
    /* height: 100%; */
    margin: 0 auto;
    padding: 3.375rem 1rem 2rem;
    position: relative;
`

export const Heading = styled.h1`
    color: #333;
    font-size: 45px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 12%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`

export const CardReasons = styled.div`
    width: 100%;
    margin-top: 2rem;
    font-size: 1rem;

    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-between; */
    align-items: center;
    
    @media screen and (max-width: 640px) {
        justify-content: center;
    }
`
export const CardGroup = styled.div`
    width: 50%;
    min-width: 290px;
    font-size: 1rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 640px) {
        justify-content: center;
    }
`
export const CardItem = styled.div`
    width: 270px;
    min-width: 270px;
    height: 150px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25);
    padding: 1.25rem 0.5rem 1rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 1.5rem 0;

    &:not(&:last-child) {
        /* margin-right: 1.5rem; */
    }
`
export const Icon = styled.img`
    width: 60px;
    margin-right: 1rem;
    margin-bottom: 1rem;
`
export const Text = styled.p`
    text-align: center;
    font-weight: 500;
    color: black;
`