import React, { useEffect, useState } from 'react';
import { Wrapper, Icon, H3, LoadingIcon } from './Step6SuccessItems';
import { SuccessIcon, FailedIcon } from '../../../assets/images';

const Step6Success = ({ isSuccessful }) => {
    // if (isSuccessful !== true && isSuccessful !== false)
    //     return <LoadingIcon className="pi pi-spin pi-spinner" />
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])
    if (loading)
        return <LoadingIcon className="pi pi-spin pi-spinner" />

    return (
        <Wrapper>
            {isSuccessful ? (
                <>
                    <Icon src={SuccessIcon} alt="success Icon" />
                    <H3 isSuccessful={isSuccessful}>Booked Successful!</H3>

                    Thank you for choosing us! &#128525;
                    <br /><br />
                    <i>
                        Please note that you will receive a <b>booking confirmation email</b> within the <b>next 24 hours</b>!
                        <br />
                        Please recheck your info in the email carefully before you come.
                    </i>
                </>
            ) : (
                <>
                    <Icon src={FailedIcon} alt="failed Icon" />
                    <H3 isSuccessful={isSuccessful}>Whoops... Booked Failed!</H3>

                    Thank you for choosing us and we're sorry for this inconvenience &#9785;
                    <br /><br />
                    Please try again later.
                </>
            )}
        </Wrapper>
    );
}

export default Step6Success;