import React from 'react'
import { IoClose } from 'react-icons/io5'

import { DecoImg7 } from '../../../assets/images'
import { useCheckIn } from '../hooks/CheckInContext'
import { Step1, Step2, Step3 } from '.'
import { ModalContainer, BtnClose, DecoImg } from './ModalContentsItems'

function ModalContents({ setOpen }) {
  const { step, stepContent, isNewCustomer } = useCheckIn()

  return (
    <ModalContainer isNewCustomer={isNewCustomer}>
      <BtnClose onClick={() => setOpen(false)}>
        <IoClose />
      </BtnClose>

      {step === stepContent.CHECK_IN ?
        <Step1 />
        : step === stepContent.CUS_INFO ?
          <Step2 />
          : step === stepContent.SUCCESS &&
          <Step3 />
      }

      <DecoImg src={DecoImg7} alt='' />
    </ModalContainer>
  )
}

export default ModalContents