import React from 'react'
import {
    Container, Wrapper, Heading, Content, Row, Row1, RowMobile, Col,
    Img, ButtonWrapper, BtnView
} from './GallerySectionItems'
import { ImArrowRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { gallery } from '../data'
import { useApp } from '../../../../hooks/AppContext'

function GallerySection() {
    const { isActive, setIsActive } = useApp()

    return (
        <section id="gallery">
            <Container>
                <Wrapper>
                    <Heading data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">
                        Gallery
                    </Heading>
                    <Content>
                        <Row data-aos="zoom-in" data-aos-anchor-placement="top-bottom">
                            <Img id="img_1st" src={gallery[0]?.src} alt="nice nails" />
                            <Col>
                                <Row1>
                                    <Img src={gallery[1]?.src} alt="nice nails" />
                                    <Img src={gallery[2]?.src} alt="nice nails" />
                                    <Img src={gallery[3]?.src} alt="nice nails" />
                                    <Img src={gallery[7]?.src} alt="nice nails" />
                                    <Img src={gallery[5]?.src} alt="nice nails" />
                                    <Img src={gallery[6]?.src} alt="nice nails" />
                                </Row1>
                            </Col>
                        </Row>
                        <RowMobile>
                            <Img src={gallery[0]?.src} alt="nice nails" />
                            <Img src={gallery[1]?.src} alt="nice nails" />
                            <Img src={gallery[2]?.src} alt="nice nails" />
                            <Img src={gallery[3]?.src} alt="nice nails" />
                            <Img src={gallery[4]?.src} alt="nice nails" />
                            <Img src={gallery[7]?.src} alt="nice nails" />
                            <Img src={gallery[5]?.src} alt="nice nails" />
                            <Img src={gallery[6]?.src} alt="nice nails" />
                            <Img src={gallery[8]?.src} alt="nice nails" />
                        </RowMobile>
                    </Content>
                    <ButtonWrapper data-aos="zoom-out-up" data-aos-anchor-placement="top-bottom">
                        <Link to="/gallery" onClick={() => setIsActive({ ...isActive, pageName: 'gallery' })}>
                            <BtnView>
                                View more <ImArrowRight2 id="arrow-icon" style={{ marginLeft: '0.2rem' }} />
                            </BtnView>
                        </Link>
                    </ButtonWrapper>
                </Wrapper>
            </Container>
        </section>
    )
}

export default GallerySection