import React, { useEffect, useState, createContext, useContext } from 'react'

const AppContext = createContext()

export function useApp() {
    return useContext(AppContext)
}

function useAppProvider() {
    const [isActive, setIsActive] = useState({
        isClicked: true,    // for generalNavbar
        pageName: ''
    })
    // const [salonInfo, setSalonInfo] = useState(null) // chưa dùng tới

    // useEffect(() => {
    //     getSalonInfo().then(res => {
    //         setSalonInfo(res)
    //     }.catch(error => {
    //         if (error.response) {
    //             console.error('Error at AppContext - getSalonInfo')
    //         }
    //     })
    // }, [])

    return { isActive, setIsActive }    //salonInfo, setSalonInfo
}

function AppProvider({ children }) {
    const app = useAppProvider()

    return (
        <AppContext.Provider value={app}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider