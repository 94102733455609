import styled from 'styled-components';

export const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10 !important;

    display: flex;
    justify-content: center;
    align-items: center;
`