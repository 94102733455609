import styled from 'styled-components';
import { Fieldset } from 'primereact/fieldset';

export const Wrapper = styled.div`
    width: 100%;
    /* height: 100%; */
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;

    /* border: 1px solid black; */
`
export const MyFieldset = styled(Fieldset)`
    margin: 0.5rem 0;
    /* border: 2px solid #FFC09F; */
    border: none;
    background-color: #ffc19f30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
`

// export const CustomerFieldSetWrapper = styled.div`
//     display: grid;
//     grid-template-columns: 8fr 4fr;

//     @media screen and (max-width: 480px) {
//         grid-template-columns: 8fr 4fr;
//     }
// `
export const CustomerInfoLeft = styled.div`
    padding-left: 0.7rem;
    display: flex;
    flex-direction: column;

    /* border-right: 1px solid lightgray; */
    /* justify-content: center; */
`
export const CustomerInfoRow = styled.div`
    width: 100%;
    padding: 0.3rem 0;
    display: grid;
    grid-template-columns: 2fr 7fr;

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 2fr;
    }
`
export const Title = styled.p`
    font-size: 0.9rem;
    font-weight: 600;
`
export const Content = styled.p`
    font-size: 0.9rem;
    /* border: 1px solid black; */
`

export const BookingFieldSetWrapper = styled.div`
    display: grid;
    grid-template-columns: 5fr 7fr;

    @media screen and (max-width: 480px) {
        display: block;
    }
`
export const DateTimeInfoLeft = styled.div`
    /* padding-left: 0.5rem; */
    padding-left: 0.7rem;
    /* margin-right: 0.2rem; */
    border-right: 2px dotted #FFC09F;
    
    @media screen and (max-width: 480px) {
        border: none;
        grid-template-columns: 1fr;
    }
`
export const BookingInfoRow = styled.div`
    width: 100%;
    padding: 0.3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 2fr;
    }
`
export const NoteRight = styled.div`
    padding-left: 0.5rem;

    @media screen and (max-width: 480px) {
        padding-left: 0.7rem;
    }
`
export const NoteRow = styled.div`
    width: 100%;
    padding: 0.3rem 0 0.3rem 0.4rem;
    display: grid;
    grid-template-columns: 1fr 4fr;

    @media screen and (max-width: 480px) {
        padding-left: 0;
        grid-template-columns: 1fr 2fr;
    }
`

export const ServiceFieldSetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 100px; */
`
export const ServiceGroupRow = styled.div`
    padding-left: 0.1rem;
    margin: 0.5rem 0;
`
export const ServiceGroupName = styled.h4`

`
export const ServiceRow = styled.div`
    padding: 0.25rem 0 0.35rem 1.2rem;
`
export const ServiceName = styled.h4`
    font-weight: 500;
`
export const ServiceOptionRow = styled.div`
    padding: 0.15rem 0 0 1.65rem;
`
export const ServiceOptionTitle = styled.h5`
    color: gray;
    font-size: 0.9rem;
    font-weight: 600;
    font-style: italic;
    display: inline;
`
export const ServiceOptionName = styled.span`
    font-size: 0.925rem;
    font-weight: 500;
`