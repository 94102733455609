import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import Layout from '../../layout/Layout';
import { BodyWrapper, Row, ColForm, SubTitle, FormWrapper, Row1, ButtonWrapper, BtnSend, Message, ColMap, MapWrapper } from './ContactUsItems'
import { AboutCoverImg } from '../../assets/images'
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';

function ContactUs() {
    useEffect(() => {
        // scroll.scrollToTop();
        window.scrollTo(0, 0)
    }, [])

    const defaultValues = {
        name: '',
        email: '',
        subject: '',
        content: '',
    }

    const [message, setMessage] = useState(null)
    const { control, formState: { errors }, getValues, reset, handleSubmit } = useForm({    // handleSubmit, reset
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        // Xử lý, gọi API các kiểu
        // console.log('data: ', data);
        setMessage({
            isSuccess: true,
            msg: 'Your message has been saved. We will reply within 1 day.'
        })

        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && (
            <div style={{ width: '100%', textAlign: 'right' }}>
                <small className="p-error">{errors[name].message}</small>
            </div>
        )
    };

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>Contact Us | Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="Contact Deluxe Nails & Spa | (972) 304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019 | Services: Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and more!" />
                <meta name="keywords" content="Deluxe, Nails, Spa, Beauty, Nails Salon, Coppell, TX 75019, Texas, Pedicures, Manicure, Acrylic, Gel, Waxing" />
                <link rel="canonical" href="https://deluxenailsspacoppell.com/" />
            </Helmet>

            <Layout title="Contact Us" coverImgSrc={AboutCoverImg} isNotFound={false}>
                <BodyWrapper>
                    <Row>
                        <ColForm>
                            <SubTitle>
                                <p>
                                    We can be reach by phone <a href="tel:+1 (972) 304-1199">(972) 304-1199</a>.
                                </p>
                                <p>
                                    To reach us via email, please fill out the form below.
                                </p>
                            </SubTitle>
                            {/* <form className="p-fluid" style={{ width: '100%', border: '1px solid black' }} onSubmit={handleSubmit(onSubmit)}> */}
                            <FormWrapper className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
                                <Row1>
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <Controller name="name" control={control} rules={{ required: 'Please enter your name' }} render={({ field, fieldState }) => (
                                                <InputText
                                                    id={field.name} {...field}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                />
                                            )} />
                                            <label htmlFor="name">Your Name*</label>
                                        </span>
                                        {getFormErrorMessage('name')}
                                    </div>

                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <Controller name="email" control={control}
                                                rules={{
                                                    required: 'Please enter your email',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'Invalid email address. E.g. example@email.com'
                                                    }
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        id={field.name} {...field}
                                                        className={classNames({ 'p-invalid': fieldState.invalid })}
                                                    />
                                                )} />
                                            <label htmlFor="email">Your Email*</label>
                                        </span>
                                        {getFormErrorMessage('email')}
                                    </div>
                                </Row1>

                                <div className="p-field">
                                    <span className="p-float-label">
                                        <Controller name="subject" control={control} rules={{ required: 'Please enter an email subject' }} render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.subject} {...field}
                                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                            />
                                        )} />
                                        <label htmlFor="subject">Email Subject*</label>
                                    </span>
                                    {getFormErrorMessage('subject')}
                                </div>

                                <div className="p-field content-textarea">
                                    <span className="p-float-label">
                                        <Controller name="content" control={control} rules={{ required: 'Please enter email content' }} render={({ field, fieldState }) => (
                                            <InputTextarea rows={7} cols={30}
                                                id={field.content} {...field}
                                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                            />
                                        )} />
                                        <label htmlFor="content">Your Message*</label>
                                    </span>
                                    {getFormErrorMessage('content')}
                                </div>

                                <ButtonWrapper>
                                    <BtnSend>Send</BtnSend>
                                    <Message isSuccess={message?.isSuccess}>{message?.msg}</Message>
                                </ButtonWrapper>
                            </FormWrapper>

                            {/* </form> */}
                        </ColForm>
                        <ColMap>
                            {/* <FrontSecondaryImg src={OrchidFlower2} /> */}
                            <MapWrapper>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3159.5055162894696!2d-96.99499362652416!3d32.96738955172885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdaa3335db46a5d02!2sDeluxe%20Nails%20%26%20Spa!5e0!3m2!1svi!2s!4v1629320142976!5m2!1svi!2s" width={600} height={450} style={{ border: '0' }} allowFullScreen="" loading="lazy" />
                            </MapWrapper>
                        </ColMap>
                    </Row>
                </BodyWrapper>
            </Layout>
        </>
    )
}

export default ContactUs
