import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    /* min-height: 1240px; */
    position: relative;
    background-color: #ffc19f30;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        background: url(/assets/images/gallery-bg.png) no-repeat;
        background-size: contain;
        background-position: center;
        background-attachment: fixed;

        position: absolute;
        top: 0;
        left: 0;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    /* min-height: 1240px; */
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 3.375rem;
    padding-bottom: 3.75rem;
    position: relative;

    @media screen and (max-width: 1200px) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
`

export const Heading = styled.h1`
    color: #333;
    font-size: 45px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 10%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`

export const Content = styled.div`
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 3rem;

    display: grid;
    grid-gap: 1rem;

    img {
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    }
`
export const Row = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: minmax(240px, 2fr) 3fr;
    grid-gap: 0.75rem;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: minmax(240px, 2fr) 2fr;
    }
    @media screen and (max-width: 660px) {
        display: none;
    }
`
export const RowMobile = styled.div`    
    display: none;

    @media screen and (max-width: 660px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(150px, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 0.6rem;
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
`

export const Row1 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(120px, 1fr));
    grid-gap: 0.9rem 0.75rem;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
    @media screen and (max-width: 660px) {
        grid-template-columns: 1fr;
    }
`
export const Col = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2, 230px);
    /* grid-gap: 1rem; */

    @media screen and (max-width: 960px) {
        grid-template-rows: repeat(2, 200px);
    }
    @media screen and (max-width: 768px) {
        grid-template-rows: repeat(2, 180px);
    }
`

export const Img = styled.img`
    width: 100%;
    height: 230px;

    &#img_1st {
        height: 475px;
    }

    @media screen and (max-width: 960px) {
        &:nth-child(4), &:nth-child(5) {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        height: 180px;
        &#img_1st {
            height: 375px;
        }
    }
    @media screen and (max-width: 660px) {
        width: 180px;
        height: 150px;

        &:nth-child(4), &:nth-child(5) {
            display: block;
        }
    }
    @media screen and (max-width: 600px) {
        width: 92%;
        /* height: 95%; */
        /* width: 210px;
        height: 180px; */

        position: relative;
        left: 50%;
        transform: translateX(-50%);

        &:nth-child(6), &:nth-child(7), &:nth-child(8) {
            display: none;
        }
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
`
export const BtnView = styled.button`
    color: #171416;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 30px;
    
    background: rgb(255, 192, 159);
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    overflow-x: hidden;

    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        max-width: 480px;
    }
`