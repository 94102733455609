import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from "primereact/inputtext"
import { InputMask } from 'primereact/inputmask'
import { Checkbox } from 'primereact/checkbox'

import {
  FormContainer, NoticeMsg, FormWrapper,
  SuggestedNameWrapper, SuggestedName,
  ButtonWrapper, BtnSubmit, //Message
} from './FormCheckInItems'
import { checkValidPhoneNo, formatPhoneNo } from '../../../../utils/String'
import { getCustomerDetail, getListNamesByPhone, requestCheckIn } from '../../../../services/CheckInApi'
import { useCheckIn } from '../../hooks/CheckInContext'

const loadingType = { SUBMIT: 1, SUGGEST: 2 }

function FormCheckIn({ style }) {
  const {
    setStep, isNewCustomer, setIsNewCustomer,
    checkInData, onChangeCheckInData,
    setMessage, setCustomerInfo
  } = useCheckIn()
  const { phone, name } = checkInData

  const [listCustomerNames, setListCustomerNames] = useState([])

  const [loading, setLoading] = useState({ type: 0, isLoading: false })

  const defaultValues = { phone, name }

  const { control, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  })

  // useEffect(() => {
  //     if ([true, false]?.includes(message?.isSuccess))
  //         setMessage({})
  // }, [phone, name])

  useEffect(() => {
    if (listCustomerNames?.length > 0)
      setIsNewCustomer(false)
  }, [listCustomerNames])

  const suggestCusNameByPhone = phoneNo => {
    if (checkValidPhoneNo(phoneNo)) {
      setLoading({ type: loadingType.SUGGEST, isLoading: true })

      getListNamesByPhone(formatPhoneNo(phoneNo))  // thiệt ra ko cần format() nữa vì đã có "unmask={true}"
        .then(res => {
          if (res) setListCustomerNames(res)
          // API cũ
          // setListCustomerNames(Array.from(new Set(res?.map(item => item?.fullName))))
        })
        .catch(err => {
          console.log('Error at suggestCusNameByPhone() in FormCheckIn: ', err)
        })
        .finally(() => setLoading({ type: loadingType.SUGGEST, isLoading: false }))
    }
    else setListCustomerNames([])
  }

  const handleRequestCheckIn = () => {
    if (checkValidPhoneNo(phone)) {
      setLoading({ type: loadingType.SUBMIT, isLoading: true })

      const data = {
        ...checkInData,
        phone: formatPhoneNo(phone),    // '012567891011'
        name: checkInData?.name || null,
        email: checkInData?.email || null,
        address: checkInData?.address || null,
        spa: 'deluxe'
      }

      requestCheckIn(data).then(resCheckIn => {
        // console.log('step 1 >> check-in thành công: ', resCheckIn)

        // getCustomerDetail('012567891011').then(resDetail => {
        getCustomerDetail(checkInData?.phone).then(resDetail => {
          const customerObj = resDetail?.list?.[0]

          setCustomerInfo({
            ...resCheckIn,
            currentPoints: +customerObj?.totalPoint - +customerObj?.discount
            // currentPoints: calSumCurrentPoints(resDetail)
          })
        })
      })
        .catch(err => {
          if (err?.message?.includes('status code 400')) {
            setMessage(
              'You had requested already. Please wait until our staff approved your request.'
            )
          }
          else {
            setMessage('Something went wrong! Please try again later :(')
            console.log('Error at handleRequestCheckIn() in Rewards: ',
              JSON.parse(JSON.stringify(err)))
          }
        })
        .finally(() => {
          reset()
          setStep(3)
          setLoading({ type: loadingType.SUBMIT, isLoading: false })
        })
    }
  }

  const handleClearPhone = () => {
    onChangeCheckInData('phone', '')
    setListCustomerNames([])
    setMessage('')
  }

  const handleNext = () => {
    setStep(2)
  }

  const getFormErrorMessage = fieldName => {
    return errors[fieldName] && (
      <small className="p-error" style={{ marginLeft: '2.85rem' }}>
        {errors[fieldName].message}
      </small>
    )
  }

  return (
    <FormContainer style={style}>
      <NoticeMsg>
        Please provide your phone number to check in
      </NoticeMsg>

      <FormWrapper onSubmit={event => event.preventDefault()}>
        {/* Phone number */}
        <div className="p-field p-col-12">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-phone" />
            </span>
            <span className={classNames("p-float-label", {
              "p-input-icon-right": (loading.type === loadingType.SUGGEST && loading.isLoading)
                || (phone && !loading.isLoading)
            })}>
              {(loading.type === loadingType.SUGGEST && loading.isLoading) &&
                <i className="pi pi-spin pi-spinner" />
              }
              {(phone && !loading.isLoading) &&
                <i className="pi pi-times" style={{ cursor: 'pointer' }}
                  onClick={handleClearPhone} />
              }
              <Controller name="phone" control={control}
                rules={{
                  required: 'Phone is required.',
                  // pattern: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
                }}
                render={({ field, fieldState }) => (
                  <InputMask id={field.name} {...field}
                    mask="(999) 999-9999" unmask={true}
                    autoFocus autoClear={false}
                    value={phone}
                    onChange={e => onChangeCheckInData('phone', e?.target?.value)}
                    onBlur={e => suggestCusNameByPhone(e?.target?.value)}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  />
                )} />
              <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Phone Number *</label>
            </span>
          </div>
          {getFormErrorMessage('phone')}
        </div>

        {/* Customer Name */}
        <div className="p-field p-col-12" style={{ marginBottom: 2 }}>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user" />
            </span>
            <span className="p-float-label">
              <Controller name="name" control={control} render={({ field }) => (
                <InputText id={field.name} {...field}
                  value={name}
                  onChange={(e) => onChangeCheckInData('name', e?.target?.value)}
                />
              )} />
              <label htmlFor="name">Your Name (optional)</label>
            </span>
          </div>

          <SuggestedNameWrapper>
            {listCustomerNames?.length > 0 &&
              listCustomerNames?.map(cusName => cusName ? (
                <SuggestedName
                  key={cusName}
                  onClick={() => onChangeCheckInData('name', cusName)}
                >
                  {cusName}
                </SuggestedName>
              ) : '')}
          </SuggestedNameWrapper>
        </div>

        <div className="field-checkbox">
          <Checkbox
            inputId="chk-more-info"
            disabled={listCustomerNames?.length > 0}
            checked={isNewCustomer}
            onChange={e => setIsNewCustomer(e?.checked)}
          />
          <label htmlFor="chk-more-info">It's my first time here</label>
        </div>
      </FormWrapper>

      {/* {[true, false]?.includes(message?.isSuccess) &&
                <Message isSuccess={message?.isSuccess}>{message?.msg}</Message>
            } */}

      <ButtonWrapper>
        <BtnSubmit id='btn-send'
          // type='submit'
          label={isNewCustomer ? 'Next' : 'Check in'}
          // icon={<FaRegSmileWink style={{ marginRight: 6, fontSize: 20 }} />}
          // iconPos="left"
          onClick={isNewCustomer ? handleNext : handleRequestCheckIn}
          disabled={!checkValidPhoneNo(phone)}
          loading={loading.type === loadingType.SUBMIT && loading.isLoading}
        />
      </ButtonWrapper>
    </FormContainer>
  )
}

export default FormCheckIn