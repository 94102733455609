import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'
import { FaBars } from 'react-icons/fa';

import {
    Nav, NavbarContainer, NavLogo, MobileIcon,
    NavMenu, NavItem, NavLinks,
    BtnCheckInWrapper, BtnCheckIn,
    BookingZone, BookingBtn
} from './GeneralNavbarItems';
import { BookingModal } from '..'
import BookingProvider from '../BookingModal/BookingContext';
import { useApp } from '../../hooks/AppContext'

function GeneralNavbar(props) {
    const {
        showModal, setShowModal,
        setShowMdCheckIn,
        setShowSidebar,
    } = props
    const { isActive, setIsActive } = useApp()

    const location = useLocation()

    const handleMenuClick = (pageName) => {
        setIsActive({ ...isActive, pageName: pageName })
    }

    useEffect(() => {
        handleMenuClick(location?.pathname?.substring(1))
    }, []);

    return (
        <Nav>
            <NavbarContainer>
                <NavLogo to='/' onClick={() => scroll.scrollToTop()}>
                    <span className="logo-deluxe">dELUXE</span>
                    <span className="logo-nails">
                        nAILS
                        <span className="logo-and">&amp;</span>
                        sPA
                    </span>
                </NavLogo>
                <div style={{ flexGrow: 1 }}></div>
                <MobileIcon onClick={() => setShowSidebar(true)}><FaBars /></MobileIcon>
                <NavMenu>
                    {/* <NavItem>
                        <NavLinks to="/">Home</NavLinks>
                    </NavItem> */}
                    <NavItem>
                        <NavLinks to="/about"
                            onClick={() => handleMenuClick('about')}
                            className={(isActive?.isClicked && isActive?.pageName === 'about') ? 'active' : ''}
                        >
                            About Us
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/services"
                            onClick={() => handleMenuClick('services')}
                            className={(isActive?.isClicked && isActive?.pageName === 'services') ? 'active' : ''}
                        >
                            Services
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/gallery"
                            onClick={() => handleMenuClick('gallery')}
                            className={(isActive?.isClicked && isActive?.pageName === 'gallery') ? 'active' : ''}
                        >
                            Gallery
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/contact"
                            onClick={() => handleMenuClick('contact')}
                            className={(isActive?.isClicked && isActive?.pageName === 'contact') ? 'active' : ''}
                        >
                            Contact Us
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/rewards"
                            onClick={() => handleMenuClick('rewards')}
                            className={(isActive?.isClicked && isActive?.pageName === 'rewards') ? 'active' : ''}
                        >
                            Rewards
                        </NavLinks>
                    </NavItem>
                    {/* <NavItem>
                        <NavLinks to="/">
                            Contact Us      // scroll tới phần contact trên trang Home --> 2 cái Link lồng nhau
                        </NavLinks>
                    </NavItem> */}
                </NavMenu>

                <BtnCheckInWrapper>
                    <BtnCheckIn
                        onClick={() => setShowMdCheckIn(true)}
                    >
                        Check in
                    </BtnCheckIn>
                </BtnCheckInWrapper>

                <BookingZone>
                    <BookingBtn onClick={() => setShowModal(true)}>
                        Book now
                    </BookingBtn>  {/**to="/services" */}
                </BookingZone>
                <BookingProvider>
                    <BookingModal showModal={showModal} setShowModal={setShowModal} />
                </BookingProvider>
            </NavbarContainer>
        </Nav>
    );
}

export default GeneralNavbar;
