import React, { useEffect } from 'react';
import {
    Wrapper,
    MyFieldset,
    // CustomerFieldSetWrapper,
    CustomerInfoLeft,
    CustomerInfoRow,
    Title,
    Content,
    BookingFieldSetWrapper,
    DateTimeInfoLeft,
    BookingInfoRow,
    NoteRight,
    NoteRow,
    ServiceFieldSetWrapper,
    ServiceGroupRow,
    ServiceGroupName,
    ServiceRow,
    ServiceName,
    ServiceOptionRow,
    ServiceOptionTitle,
    ServiceOptionName
} from './Step5ConfirmItems';
import { useBooking } from '../BookingContext';
import { parseDateToString, parseDateToUTC, getUTCTimeZone } from '../../../utils/DateTime';

const Step5Confirm = () => {
    const { appointment, setAppointment } = useBooking()

    const _getSelectedSlotTime = () => {
        let pickedTime = appointment?.dateTime?.split(' ')[0]
        let hour = Number(pickedTime?.split(':')[0])
        const atPeriod = appointment?.dateTime?.split(' ')[1]

        if (atPeriod === 'pm' && hour < 12) {
            hour += 12
            pickedTime = hour + ':' + pickedTime?.split(':')[1]
        }

        return pickedTime + ':00'
    }

    useEffect(() => {
        const pickedTime = _getSelectedSlotTime()
        const timeString = parseDateToUTC(appointment?.date, 'YYYY-MM-DD') + ' ' + pickedTime

        let options = []
        appointment?.serviceGroups?.map(group =>
            group.services.map(service =>
                service.options?.map(option =>
                    options.push(option.id)
                )
            )
        )

        setAppointment({ ...appointment, options: options, time: timeString })
    }, [])

    return (
        <Wrapper>
            {/* Customer Info */}
            <MyFieldset legend={<h5>Customer Info</h5>}>
                {/* <CustomerFieldSetWrapper> */}
                <CustomerInfoLeft>
                    <CustomerInfoRow>
                        <Title>Your Name</Title>
                        <Content>{appointment?.name}</Content>
                    </CustomerInfoRow>
                    <CustomerInfoRow>
                        <Title>Phone No.</Title>
                        <Content>{appointment?.phone}</Content>
                    </CustomerInfoRow>
                    <CustomerInfoRow>
                        <Title>Email</Title>
                        <Content>
                            {appointment?.email ? appointment?.email :
                                <span style={{ color: '#a0a0a0', fontStyle: 'italic' }}>(none)</span>
                            }
                        </Content>
                    </CustomerInfoRow>
                </CustomerInfoLeft>
                {/* </CustomerFieldSetWrapper> */}
            </MyFieldset>

            {/* Booking Info */}
            <MyFieldset legend={<h5>Booking Info</h5>} style={{ margin: '1rem 0' }}>
                <BookingFieldSetWrapper>
                    <DateTimeInfoLeft>
                        <BookingInfoRow>
                            <Title>Date</Title>
                            <Content>{parseDateToString(appointment?.date, 'MMM D, YYYY')}</Content>
                        </BookingInfoRow>
                        <BookingInfoRow>
                            <Title>Time</Title>
                            <Content>
                                {appointment?.dateTime}
                                <span style={{ fontSize: '0.85rem', }}> ({getUTCTimeZone(appointment?.date)})</span>
                            </Content>
                        </BookingInfoRow>
                        <BookingInfoRow>
                            <Title>Technician</Title>
                            {/**Dùng chips */}
                            <Content>{appointment?.technician?.name}</Content>
                        </BookingInfoRow>
                    </DateTimeInfoLeft>
                    <NoteRight>
                        <NoteRow>
                            <Title>Note</Title>
                            <Content>
                                {appointment?.note ? appointment?.note :
                                    <span style={{ color: '#a0a0a0', fontStyle: 'italic' }}>(none)</span>
                                }
                            </Content>
                        </NoteRow>
                    </NoteRight>
                </BookingFieldSetWrapper>
            </MyFieldset>

            {/* Service Info */}
            <MyFieldset legend={<h5>Services Info</h5>} style={{ marginBottom: '0.65rem' }}>
                <ServiceFieldSetWrapper>
                    {appointment?.serviceGroups?.map(group => (
                        <ServiceGroupRow key={group?.id}>
                            <ServiceGroupName>{group?.name}</ServiceGroupName>
                            {group?.services?.map((service, indexS) => (
                                <ServiceRow key={indexS}>
                                    <ServiceName>{indexS + 1}/ &nbsp; {service?.name}</ServiceName>
                                    {[1, 2].includes(group.id) && (
                                        <ServiceOptionRow>
                                            <ServiceOptionTitle>Flavor: &nbsp;</ServiceOptionTitle>
                                            {service?.options?.map((option, indexO) => (
                                                <ServiceOptionName key={indexO}>
                                                    {option?.name}
                                                    {indexO < service.options.length - 1 && ', '}
                                                </ServiceOptionName>
                                            ))}
                                        </ServiceOptionRow>
                                    )}
                                </ServiceRow>
                            ))}
                        </ServiceGroupRow>
                    ))}
                    {appointment?.serviceGroups?.length < 1 && (
                        <span style={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '0.9rem' }}>No services selected</span>
                    )}
                </ServiceFieldSetWrapper>
            </MyFieldset>
        </Wrapper>
    );
}

export default Step5Confirm;
