import styled from 'styled-components';

export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const Title = styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;

    position: relative;
`

export const BtnBack = styled.div`
    padding: 2.5px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    &:hover {
        cursor: pointer;
        background-color: #eeeeee;
    }
`