import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from "primereact/inputtext"
import { InputMask } from 'primereact/inputmask'
// import { Divider } from 'primereact/divider'
import {
  RequestFormContainer, NoticeMsg, FormWrapper,
  SuggestedNameWrapper, SuggestedName, //NoteTextarea,
  ButtonWrapper, BtnViewHistory, Message  // BtnSend, 
} from './RequestFormItem'
import { getListNamesByPhone, getHistory } from '../../../services/CheckInApi'  //, requestReward
import { checkValidPhoneNo, formatPhoneNo } from '../../../utils/String'

const loadingType = { SEND: 1, VIEW: 2, SUGGEST: 3 }

function RequestForm({ setHistory }) {
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  // const [note, setNote] = useState('')

  const [listCustomerNames, setListCustomerNames] = useState([])

  const [message, setMessage] = useState({})  // can be both error message & success message
  const [loading, setLoading] = useState({ type: 0, isLoading: false })

  const defaultValues = { phone: '', name: '' }   // , note: ''

  const { control, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if ([true, false]?.includes(message?.isSuccess))
      setMessage({})
  }, [phone, name])   // , note

  const suggestCusNameByPhone = phoneNo => {
    if (checkValidPhoneNo(phoneNo)) {
      setLoading({ type: loadingType.SUGGEST, isLoading: true })

      getListNamesByPhone(formatPhoneNo(phoneNo))  // thiệt ra ko cần format() nữa vì đã có "unmask={true}"
        .then(res => {
          if (res) setListCustomerNames(res)
          // API cũ
          // setListCustomerNames(Array.from(new Set(res?.map(item => item?.fullName))))
        })
        .catch(err => {
          console.log('Error at suggestCusNameByPhone() in Rewards: ', err)
        })
        .finally(() => setLoading({ type: loadingType.SUGGEST, isLoading: false }))
    }
    else setListCustomerNames([])
  }

  /*   Updated on 29/05/2022:
      Now customers will request for login, and then staff will record rewards,
      customer needn't to request rewards again.
  */
  //   const handleSendRequest = () => {
  //     if (checkValidPhoneNo(phone)) {
  //       setLoading({ type: loadingType.SEND, isLoading: true })

  //       const data = {
  //         phone: formatPhoneNo(phone),
  //         username: name || null,
  //         description: note
  //       }

  //       requestReward(data).then(res => {
  //         if (res === 'successful') // message from API
  //           setMessage({
  //             isSuccess: true,
  //             msg: 'Your request has been recorded'
  //           })
  //       })
  //         .catch(err => {
  //           if (err?.message?.includes('status code 400')) {
  //             setMessage({
  //               isSuccess: false,
  //               msg: 'You had requested already. Please wait until our staff approved your request.'
  //             })
  //           }
  //           else {
  //             setMessage({
  //               isSuccess: false,
  //               msg: 'Something went wrong! Please try again later :('
  //             })
  //             console.log('Error at handleSendRequest() in Rewards: ', JSON.parse(JSON.stringify(err)))
  //           }
  //         })
  //         .finally(() => {
  //           reset()
  //           setLoading({ type: loadingType.SEND, isLoading: false })
  //         })
  //     }
  //   }

  const handleViewHistory = () => {
    setMessage({})
    const phoneNo = formatPhoneNo(phone)

    if (checkValidPhoneNo(phoneNo)) {
      setLoading({ type: loadingType.VIEW, isLoading: true })

      // getHistory('012567891011').then(res => {
      getHistory(phoneNo).then(res => {
        if (res) setHistory(res)
      })
        .catch(err => {
          if (err?.response?.data?.status === 404) {
            setHistory([])

            console.log('not found');
            // setMessage({
            //   isSuccess: false,
            //   msg: 'You had requested already. Please wait until our staff approved your request.'
            // })
          }
          else
            console.log('Error at handleViewHistory() in Rewards: ', err)
        })
        .finally(() => {
          reset()
          setLoading({ type: loadingType.VIEW, isLoading: false })
        })
    }
    else setHistory(null)
  }

  const handleClearPhone = () => {
    setPhone('')
    setListCustomerNames([])
    setHistory(null)
    setMessage({})
  }

  const getFormErrorMessage = fieldName => {
    return errors[fieldName] && (
      <small className="p-error" style={{ marginLeft: '2.85rem' }}>
        {errors[fieldName].message}
      </small>
    )
  }

  return (
    <RequestFormContainer>
      <NoticeMsg>
        You can enter your phone number to search for your history
        <br />
        (including check-in/check-out time and rewards)
      </NoticeMsg>

      {/* <FormWrapper onSubmit={handleSubmit(
            action === actionType.SEND ? handleSendRequest : handleViewHistory
          )}> */}
      <FormWrapper onSubmit={event => event.preventDefault()}>
        {/* Phone number */}
        <div className="p-field p-col-12">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-phone" />
            </span>
            <span className={classNames("p-float-label", {
              "p-input-icon-right": (loading.type === loadingType.SUGGEST && loading.isLoading)
                || (phone && !loading.isLoading)
            })}>
              {(loading.type === loadingType.SUGGEST && loading.isLoading) &&
                <i className="pi pi-spin pi-spinner" />
              }
              {(phone && !loading.isLoading) &&
                <i className="pi pi-times" style={{ cursor: 'pointer' }} onClick={handleClearPhone} />
              }
              <Controller name="phone" control={control}
                rules={{
                  required: 'Phone is required.',
                  // pattern: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
                }}
                render={({ field, fieldState }) => (
                  <InputMask id={field.name} {...field}
                    mask="(999) 999-9999" unmask={true}
                    autoFocus autoClear={false}
                    value={phone}
                    onChange={e => setPhone(e?.target?.value)}
                    onBlur={e => suggestCusNameByPhone(e?.target?.value)}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  />
                )} />
              <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Phone Number *</label>
            </span>
          </div>
          {getFormErrorMessage('phone')}
        </div>

        {/* Customer Name */}
        <div className="p-field p-col-12">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user" />
            </span>
            <span className="p-float-label">
              <Controller name="name" control={control} render={({ field }) => (
                <InputText id={field.name} {...field}
                  value={name}
                  onChange={(e) => setName(e?.target?.value)}
                />
              )} />
              <label htmlFor="name">Your Name (optional)</label>
            </span>
          </div>

          <SuggestedNameWrapper>
            {listCustomerNames?.length > 0 &&
              listCustomerNames?.map(cusName => cusName ? (
                <SuggestedName key={cusName} onClick={() => setName(cusName)}>
                  {cusName}
                </SuggestedName>
              ) : '')}
          </SuggestedNameWrapper>
        </div>

        {/* Note */}
        {/* <div className="p-field p-col-12 reward-form__note">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-pencil" />
            </span>
            <span className="p-float-label">
              <Controller name="note" control={control} render={({ field }) => (
                <NoteTextarea id={field.name} {...field}
                  value={note}
                  onChange={e => setNote(e?.target?.value)} rows={4} //cols={30}
                />
              )} />
              <label htmlFor="note">Your Note (optional)</label>
            </span>
          </div>
        </div> */}

        {[true, false]?.includes(message?.isSuccess) &&
          <Message isSuccess={message?.isSuccess}>{message?.msg}</Message>
        }

        <ButtonWrapper>
          <BtnViewHistory id='btn-view-history'
            // type='submit'
            label='View history'
            icon="pi pi-history" iconPos="left"
            onClick={handleViewHistory}
            disabled={!checkValidPhoneNo(phone)}
            loading={loading.type === loadingType.VIEW && loading.isLoading}
          />

          {/* <BtnSend id='btn-send'
            // type='submit'
            label='Send'
            icon="pi pi-send" iconPos="left"
            onClick={handleSendRequest}
            disabled={!checkValidPhoneNo(phone)}
            loading={loading.type === loadingType.SEND && loading.isLoading}
          /> */}
        </ButtonWrapper>
      </FormWrapper>
    </RequestFormContainer>
  )
}

export default RequestForm