import React from 'react'
import { BgNailClub } from '../../assets/images'
import CheckInProvider from './hooks/CheckInContext'
import { CheckInFrame } from './components'
import { Background, FrameWrapper } from './CheckInNailClubItems'

const CheckInNailClub = () => (
  <Background>
    <img className='bg' src={BgNailClub} alt='' />

    <FrameWrapper>
      <CheckInProvider>
        <CheckInFrame />
      </CheckInProvider>
    </FrameWrapper>
  </Background>
)

export default CheckInNailClub;
