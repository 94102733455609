import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    /* background-color: #a0a0a0; */
    color: #333;
    /* font-size: 10rem; */
    position: relative;
`

export const DecorationImg = styled.img`
    width: auto;
    height: 180px;  // lọ sơn móng
    /* height: 220px;  // hoa */
    position: absolute;
    bottom: 5px;
    right: 15px;
    /* bottom: 0;   // hoa
    right: 10px; */

    opacity: 0.3;

    @media screen and (max-width: 1100px) {
        left: 24px;
    }
    @media screen and (max-width: 768px) {
        bottom: 5px;
        left: unset;
        right: 15px;
    }
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const Wrapper = styled.div`
    max-width: 1200px;
    /* min-height: 570px; */
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 1.7rem;

    @media screen and (max-width: 1200px) {
        padding-left: 2rem;
        padding-right: 1.5rem;
    }
`

export const Row = styled.div`
    width: 100%;
    min-height: 540px;
    /* height: 100%; */
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 4fr 5fr;
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: 2fr 3fr;
    }
    @media screen and (max-width: 960px) {
        grid-template-columns: 4fr 7fr;
    }
    @media screen and (max-width: 768px) {
        display: block;
    }
`

export const ColImage = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const Background = styled.div`
    /* width: 300px;
    height: 280px; */
    width: 52%;
    height: 66%;
    border: 0.75rem solid #FFC09F;
    background: url(/assets/images/dots-bg.png) repeat;
    
    position: absolute;
    top: 120px;
    right: 56px;
    z-index: 1;

    @media screen and (max-width: 1200px) {
        height: 295px;
        right: 40px;
    }
    @media screen and (max-width: 1100px) {
        height: 260px;
        right: 30px;
    }
    @media screen and (max-width: 1024px) {
        height: 230px;
        right: 0;
    }
    @media screen and (max-width: 960px) {
        height: 205px;
        right: -20px;
    }
`
export const PrimaryImgWrapper = styled.div`
    width: 82%;
    padding: 0;
    border: 1.2rem solid white;
    /* border: 3px solid lightskyblue; */
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);

    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }
    @media screen and (max-width: 960px) {
        width: 96%;
    }
`
export const PrimaryImg = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /* z-index: 1; */
`
// export const FrontSecondaryImg = styled.img`
//     z-index: 3;
// `
export const SecondaryImg = styled.img`
    width: 200px;
    height: auto;
    position: absolute;
    top: 380px;
    right: 0;
    z-index: 2;

    @media screen and (max-width: 1200px) {
        width: 175px;
        top: 310px;
        right: -40px;
    }
    @media screen and (max-width: 1100px) {
        top: 290px;
    }
    @media screen and (max-width: 1024px) {
        width: 160px;
        top: 250px;
        right: -50px;
    }
    @media screen and (max-width: 960px) {
        width: 140px;
        right: -80px;
        opacity: 0.85;
    }
`

export const ColContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 1.3rem 0 2rem 4.5rem;

    display: flex;
    flex-flow: column wrap;
    z-index: 0;     // 3 (ko hiểu sao ở đây nếu để zIndex = 3 thì nó bị đè lên trên popup CheckIn)

    @media screen and (max-width: 768px) {
        padding-left: 0;
    }
`
export const TextWrapper = styled.div`
    margin-top: 1rem;
`
export const Heading = styled.h1`
    font-style: italic;
    color: #333;
    font-size: 2.6rem;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: left;

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        width: 22.5%;
    }

    span {
        font-style: normal;
        /* color: #171416d3; */
        font-family: 'Plaza', sans-serif;
        font-weight: 900;
    }
    .logo-deluxe {
        font-size: 3.5rem;
        /* margin-left: 0.2rem; */
    }
    .logo-nails {
        font-size: 2.4rem;
        margin-top: 0.9rem;
        margin-left: 0.5rem;
    }
    .logo-and {
        font-size: 1.8rem;
        margin: 0 0.25rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`
export const SubTitle = styled.p`
    min-height: calc(2 * 1.25rem + 2 * 0.95rem);  // 2 rows + gap between rows (0.95rem)
    margin-top: 1.2rem;
    font-style: italic;
    font-size: 1.25rem;
    /* line-height: 1.5rem; */

    @media screen and (max-width: 500px) {
        font-size: 1.2rem;
    }
    @media screen and (max-width: 480px) {
        min-height: calc(3 * 1.25rem + 3 * 0.75rem);  // 3 rows + gap between rows (0.75rem)
    }
`
export const Content = styled.p`
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    font-size: 1rem;
    line-height: 1.6rem;

    a {
        text-decoration: none;
        color: #333;
    }

    a:hover {
        color: #848484;
        font-weight: 600;
    }

    @media screen and (max-width: 480px) {
        margin-top: 1.6rem;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`
export const BtnReadMore = styled.button`
    color: #171416;
    font-size: 18px;
    font-weight: 500;
    /* text-transform: uppercase; */
    
    /* color: #fff; */
    background: #FFC09F;
    padding: 12px 30px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    overflow-x: hidden;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        max-width: 480px;
    }
`