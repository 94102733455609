import styled from 'styled-components'

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0 1rem 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @media screen and (max-width: 900px) {
        padding: 2rem 1.5rem 3rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const Img = styled.img`
    @media screen and (max-width: 1200px) {
        width: 700px;
    }
    @media screen and (max-width: 1100px) {
        width: 620px;
    }
    @media screen and (max-width: 1024px) {
        width: 580px;
    }
    @media screen and (max-width: 940px) {
        width: 500px;
    }
    @media screen and (max-width: 900px) {
        width: 400px;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

export const ErrorMessage = styled.p`
    font-size: 1.8rem;
    text-align: left;
    margin-bottom: 3rem;

    @media screen and (max-width: 1200px) {
        font-size: 1.6rem;
        margin-bottom: 2.5rem;
    }
    @media screen and (max-width: 1100px) {
        font-size: 1.5rem;
    }
    @media screen and (max-width: 1024px) {
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
    @media screen and (max-width: 960px) {
        font-size: 1.25rem;
        margin-left: 1rem;
    }
    @media screen and (max-width: 900px) {
        margin-top: 0.8rem;
        margin-left: 0;
    }
    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
    }
    @media screen and (max-width: 480px) {
        font-size: 1.05rem;
    }
`