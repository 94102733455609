import React, { useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useCheckIn } from '../../hooks/CheckInContext'
import { FormNewCustomerInfo } from '../form'
import { BodyContainer, Title, BtnBack } from './Step2Items'

function Step2() {
  const { setStep } = useCheckIn()

  const [windowWidth] = useWindowSize()
  const [titleHeight, setTitleHeight] = useState(0)

  useEffect(() => {
    setTitleHeight(24 +
      document.getElementById('md-title-welcome')?.offsetHeight
    )
  }, [windowWidth])

  return (
    <BodyContainer>
      <Title id='md-title-welcome'>
        <BtnBack onClick={() => setStep(1)}>
          <BiArrowBack />
        </BtnBack>
        Welcome Newcomers
      </Title>

      <FormNewCustomerInfo
        style={{ height: `calc(100% - ${titleHeight}px)` }}
      />
    </BodyContainer>
  )
}

export default Step2