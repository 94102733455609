import styled from 'styled-components'
import { Timeline } from 'primereact/timeline'
import { Card } from 'primereact/card'

export const HistoryContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  & > h3 {
    font-size: 22px;
    margin-bottom: 28px;
  }
`

export const HistoryWrapper = styled.div`
  width: 82.5%;
  overflow-x: auto;
  text-align: center;

  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
    background-color: #cacaca;
  }

  & > p {
    margin-bottom: 0.1rem;
    color: #848484;
    font-size: 1.05rem;
    line-height: 2rem;
    font-style: italic;
  }
  & > span {
    font-style: italic;
    font-size: 1.5rem;
    color: #FFC09F;
    font-weight: 600;
  }

  @media screen and (max-width: 1200px) {
    width: 84%;
  }
  @media screen and (max-width: 1100px) {
    width: 88%;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 960px) {
    width: 92%;
  }
  @media screen and (max-width: 900px) {
    width: 96%;
  }
  /* @media screen and (max-width: 850px) {
    width: 92%;
  } */
`

export const HistoryTimeline = styled(Timeline)`
  &.reward-timeline > .p-timeline-event {
    min-width: 130px;

    &:last-child {
      min-width: fit-content;
    }

    & > .p-timeline-event-content {
      padding: 0px 0 32px 24px;
      position: relative;
    }
    & > .p-timeline-event-opposite {
      flex: 0.2;
      width: 150px;
      margin: 8px 16px 0 0;
      padding: 0;
      text-align: right;
    }
    & .custom-marker {
      width: 2.25rem;
      height: 2.25rem;
      border: 0 none;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      & .pi-dollar {
        font-size: 18px;
      }
      & .pi-star-fill {
        color: white;
        font-size: 18px;
      }
    }

    @media screen and (max-width: 600px) {
      & > .p-timeline-event-opposite {
        display: none;
      }
    }
  }
`

export const HistoryCard = styled(Card)`
  min-width: 326px;
  max-width: 450px;
  padding: 0 8px;
  border: 2px solid;
  border-color: #F9C892;
  border-top-width: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #F9C892;

    position: absolute;
    top: 9px;
    left: 14px;
  }
  &:hover {
    background-color: rgba(249, 200, 146, .1);
  }

  &.redeem {
    border-color: #05E177;
  }
  &.redeem::after {
    border-right-color: #05E177;
  }
  &.redeem:hover {
    background-color: rgba(5, 225, 119, .1);
  }

  &.current {
    border-color: #02B4F5;
  }
  &.current::after {
    border-right-color: #02B4F5;
  }
  &.current:hover {
    background-color: rgba(2, 180, 245, .1);
  }

  & .p-card-content {
    padding: 0;
  }
`
export const Row = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-flow: row nowrap;
`
export const Title = styled.h4`
  min-width: 80px;
`
export const Content = styled.p`
  width: 100%;
  text-align: left;
`
