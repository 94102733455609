import React from 'react';
import { VideoNailPolish, GifNailPolish } from '../../../../assets/videos';
import {
    IntroContainer, IntroBg, VideoBg, GifBg,
    IntroContent, IntroH1, IntroP,
    IntroBtnWrapper, BtnCheckIn, BtnExplore, BtnBooking
} from './IntroSectionItems';
import { useApp } from '../../../../hooks/AppContext'

function IntroSection({ setShowModal, setShowMdCheckIn }) {
    const { isActive, setIsActive } = useApp()

    return (
        <section id="intro">
            <IntroContainer> {/** id="intro" */}
                <IntroBg>
                    <VideoBg autoPlay={true} muted={true} playsInline={true} loop controls={false}
                        type='video/mp4' src={VideoNailPolish} />
                    <GifBg src={GifNailPolish} alt='video background' />
                    {/* <div dangerouslySetInnerHTML={{
                    __html: `
                    <video
                    loop
                    muted
                    autoplay
                    playsinline
                    type='video/mp4' 
                    src="${VideoNailPolish}"
                    class="video"
                    />,
                ` }}></div> */}
                </IntroBg>
                <IntroContent>
                    <IntroH1 data-aos="zoom-in" data-aos-delay="50">
                        Bring out the Beauty in You!
                    </IntroH1>
                    <IntroP data-aos="zoom-in" data-aos-delay="50">
                        We are dedicatied to bring a unique custom nail design services to satisfy even the most demanding clients.
                        {/* With qualified technicians, we are dedicatied to bring a unique and
                    full range of nail design services to satisfy even the most demanding clients. */}
                        {/* unique and custom nail designs to all customers combined with expert techniques used in the nail salon industry.
                    Our qualified team provide a full range of nails design services to satisfy even the most demanding clients.
                    we sure that you will have the best appearance. */}
                    </IntroP>
                    {/* data-aos="fade-up" data-aos-delay="100" data-aos-anchor-placement="top-top" */}

                    <IntroBtnWrapper data-aos="fade-up" data-aos-delay="100">
                        <BtnExplore to='/services' onClick={() => setIsActive({ ...isActive, pageName: 'services' })}>
                            Explore
                        </BtnExplore>
                        <BtnBooking onClick={() => setShowModal(true)}>
                            Book now
                        </BtnBooking>
                        <BtnCheckIn onClick={() => setShowMdCheckIn(true)}>
                            Check in
                        </BtnCheckIn>
                    </IntroBtnWrapper>
                </IntroContent>
            </IntroContainer>
        </section>
    );
}

export default IntroSection;