import styled from 'styled-components';

export const FrameContainer = styled.div`
    width: 100%;
    max-width: 420px;
    height: 520px;
    /* overflow: hidden; */
    margin-top: 10px;
    padding: 36px;

    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fafafa;
    color: #171416;

    position: relative;
    z-index: 10;
    border-radius: 20px;
    transition: width 450ms ease-in-out, height 0.3s ease-in-out;

    @media screen and (max-width: 480px) {
        width: calc(100% - 0.8rem);
    }
`

export const DecoImg = styled.img`
    width: 100%;

    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 0;
    opacity: 0.2;
    border-radius: 20px;
`