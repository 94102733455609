import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import { FooterBg } from '../../assets/images/index'

export const Container = styled.footer`
    background-color: rgba(180, 180, 180, 0.3);  // #d0d0d0
    /* background-color: #101522f0;  // #d0d0d0 */
    /* height: 400px; */
    height: auto;
    margin-top: auto;   // thêm dòng này để khi boday content ngắn thì nó tự đẩy footer xuống cuối trang
    position: relative;
    color: #171416;
    
    &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.25;
        background-image: url('assets/images/footer-cover.jpg');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        /* filter: blur(2px);
        -webkit-filter: blur(2px); */
    }

    @media screen and (max-width: 1120px) {
        &::before {
            background-image: url('assets/images/footerbg-web.jpg');
        }
    }
    @media screen and (max-width: 737px) {
        /* padding-bottom: 2rem; */
        &::before {
            background-image: url('assets/images/footerbg-mobile-2.jpg');
        }
    }
`
export const DecoratedDivider = styled.footer`
    background-color: #101522f0;    //#a0a0a0
    height: 0.5rem;
`
export const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 3.5rem 2rem 2.5rem;
    /* padding: 48px 24px; */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1120px) {
        /* width: auto; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-end;
    }
    @media screen and (max-width: 737px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media screen and (max-width: 480px) {
        padding: 1.5rem 1rem;
    }
`
export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 320px;

    @media screen and (max-width: 820px) {
        padding-top: 1rem;
    }
    @media screen and (max-width: 768px) {
        order: 2;
        padding-top: 0.7rem;
        margin-left: 0.5rem;
        padding-left: 1.5rem;
    }
    @media screen and (max-width: 737px) {
        order: 3;
        width: 360px;
        margin-left: 0;
        padding-left: 0.2rem;
        padding-top: 2.5rem;
    }
    @media screen and (max-width: 400px) {
        width: 330px;
        padding-left: 1rem;
    }
`
export const LinksWrapper = styled.div`
    display: flex;
    padding-top: 0.8rem;

    @media screen and (max-width: 820px) {
        /* flex-direction: column; */
        padding-top: 0;
        /* display: grid;
        grid-template-columns: 2fr 1fr; */
    }
`
export const LinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem;
    /* margin: 1rem 1rem 0; */
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #171416d3;
                                //420 hay 480 nhỉ?
    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`
export const LinkTitle = styled.h2`
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`
export const FooterLink = styled(Link)`
    color: #171416;
    text-decoration: none;
    margin-top: 0.5rem;
    font-size: 1rem;

    &:hover {
        color: #171416d3;   // #FFC09F
        font-weight: 600;
        transition: 0.3s ease-out;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 360px;
    /* width: fit-content; */

    &.contact-info {
        padding: 2.1rem 1rem 0;
    }

    @media screen and (max-width: 768px) {
        /* padding-top: 1.2rem; */
        /* &.contact-info {
            padding: 1.25rem 1rem 0;
        } */

        &.salonBrief {
            order: 1;
        }
        &.contact-info {
            padding-top: 1.25rem;
            order: 3;
        }
    }
    @media screen and (max-width: 737px) {
        &.contact-info {
            /* padding-top: 1.25rem; */
            order: 2;
        }
    }
    @media screen and (max-width: 400px) {
        padding-left: 0;
        width: 330px;
    }
`
export const SalonName = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    
    span {
        color: #171416d3;
        font-family: 'Plaza', sans-serif;
    }
    .logo-deluxe {
        font-size: 4.5rem;
    }
    .logo-nails {
        font-size: 3rem;
        margin-top: 0.9rem;
        margin-left: 0.5rem;
    }
    .logo-and {
        font-size: 2rem;
        margin: 0 0.25rem;
    }
    @media screen and (max-width: 820px) {
        /* margin: 0 auto; */
        .logo-nails {
            margin-top: 0.4rem;
        }
    }
`
export const SalonDescription = styled.div`
    display: flex;
    flex-direction: column;
`
export const DescriptionRow = styled.p`
    margin: 0.5rem 0 0 1.5rem;

    @media screen and (max-width: 400px) {
        margin-left: 1rem;
    }
`
export const SalonInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 8fr;
`
export const InfoIcon = styled.span`
    color: #171416d3;
    margin-bottom: 0.8rem;
    text-align: left;

    &.phone {
        font-size: 1.05rem;
    }
    &.email {
        font-size: 1.3rem;
        margin-bottom: 0.7rem;
    }
    &.address {
        font-size: 1.3rem;
        margin-bottom: 0.9rem;
    }
    &.hour {
        font-size: 1.3rem;
    }
`
export const InfoContent = styled.p`
    margin-bottom: 0.8rem;
    &.email {
        margin-bottom: 0.7rem;
    }
    &.address {
        margin-bottom: 0.9rem;
    }
`
export const OpeningHours = styled.div`
    display: flex;
    flex-direction: column;
`
export const OpeningHour = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-bottom: 0.2rem;
`

export const SocialMediaWrap = styled.div`
    width: 100%;
    margin: 1.5rem auto 0 auto;
    padding: 0 1.5rem ;
    
    display: flex;
    justify-content: space-between;    //center
    align-items: center;    

    /* border: 1px solid blue; */

    /* h4 {
        color: #171416d3;
    } */

    @media screen and (max-width: 737px) {
        justify-content: center;
    }
`
export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 190px;
`
export const SocialIconLink = styled.a`
    color: #171416d3;
    font-size: 2rem;
    
    &:hover {
        color: #171416;
        transform: scale(1.15);       
        transition: 0.1s;
    }
`

export const CopyrightContainer = styled.section`
    max-width: 1200px;
    width: 100%;
    margin-top: 2.4rem;
    padding-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    position: relative;

    /* border: 1px solid red; */

    &::after {
        content: "";
        background: #333;
        border-color: #333;
        position: absolute;
        top: 0;
        left: 50;
        height: 1px;
        width: 60%;
    }

    @media screen and (max-width: 1120px) {
        width: 385px;
        order: 4;
        padding-bottom: 1rem;

        &::after {
        height: 0px;
        }
    }
    @media screen and (max-width: 820px) {
        width: auto;
    }
    @media screen and (max-width: 768px) {
        align-items: flex-end;
        padding-bottom: 0.2rem;
        padding-right: 1.8rem;
    }
    @media screen and (max-width: 737px) {
        margin-top: 2rem;
    }
    @media screen and (max-width: 480px) {
        padding-right: 0;
    }
    @media screen and (max-width: 400px) {
        margin-left: 0.5rem;
    }
`
export const Copyright = styled.small`
    color: #171416d3;
    font-weight: 600;

    @media screen and (max-width: 1120px) {
        .tab-space {
            display: none;
        }
        .dev-by {
            display: block;
            text-align: center;
            margin-top: 0.2rem;
        }
    }
    @media screen and (max-width: 835px) {
        .reserved, .dev-by {
            display: block;
            text-align: center;
            margin-top: 0.2rem;
        }
    }
    @media screen and (max-width: 737px) {
        .reserved {
            display: inline;
        } 
        .dev-by {
            display: block;
            text-align: center;
            margin-top: 0.2rem;
        }
    }
    @media screen and (max-width: 465px) {
        .reserved, .dev-by {
            display: block;
            text-align: center;
            margin-top: 0.2rem;
        }
    }
`

// export const SocialLogo = styled.div`
//     color: whitesmoke;
//     justify-self: start;
//     cursor: pointer;
//     text-decoration: none;
//     font-size: 1.5rem;
//     display: flex;
//     align-items: center;
//     margin-bottom: 16px;
//     font-weight: bold;
// `