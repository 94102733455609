import { AboutUs, Services, Gallery, ContactUs, Rewards } from '../pages'

export const routerData = [
    {
        path: '/about',
        name: 'About Us',
        component: () => <AboutUs />
    },
    {
        path: '/services',
        name: 'Services',
        component: () => <Services />
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => <Gallery />
    },
    {
        path: '/rewards',
        name: 'Rewards',
        component: () => <Rewards />
    },
    {
        path: '/contact',
        name: 'Contact Us',
        component: () => <ContactUs />
    },
]