import React, { useEffect, useState, useRef } from 'react';
import { Wrapper, DatePicker, TimePicker, TimeCard, MsgText } from './Step1DateTimeItems';
import { Messages } from 'primereact/messages';
// import { weekdaySlots, saturdaySlots, sundaySlots } from './data';   // trước làm mock, giờ call từ API
import { getSlotsByDay } from '../../../services/BookingApi';
import { useBooking } from '../BookingContext';
// import { convetHourStringToNumber } from '../../../utils/DateTime';
import { sortArrByNumber } from '../../../utils/Arrays'
import { WereClosedIcon } from '../../../assets/images';
import moment from 'moment-timezone'

const Step1DateTime = () => {
    const { appointment, setAppointment } = useBooking()
    const message = useRef(null);
    const isEndOfSlot = useRef(null)
    const salonNow = moment.tz(new Date(), 'America/Chicago');

    const [checkedTime, setCheckedTime] = useState({
        id: -1,
        checked: false
    });
    const [timeSlots, setTimeSlots] = useState([])


    let loading = true
    const loadSlotsByDay = (day) => {
        getSlotsByDay(day).then(res => {
            if (loading) {
                const listSlots = sortArrByNumber(res)
                setTimeSlots(listSlots)
            }
        }).catch(error => {
            console.log('Error at loadSlotsByDay(): ', error);
        })
    }

    const pickADate = (e) => {
        // const pickedDate = moment.tz(e.value, 'America/Chicago')
        setAppointment({ ...appointment, date: e.value })    // pickedDate
    }

    useEffect(() => {
        loadSlotsByDay(moment(appointment?.date).format('YYYY-MM-DD'));

        message.current?.show({
            severity: 'error', sticky: true, content: (
                <React.Fragment>
                    <img alt="we're closed icon" src={WereClosedIcon} width="100" />
                    <MsgText>
                        We're sorry that there is no more time slot for today.
                        <br />
                        Please book another day!
                    </MsgText>
                </React.Fragment>
            )
        });

        if (isEndOfSlot.current) {
            // console.log('Hết slot rồi nè = ', isEndOfSlot.current);
            setAppointment({ ...appointment, dateTime: null })
        }

        return () => {
            loading = false
        }
    }, [appointment?.date]);

    const pickATime = (e, slot, index) => {
        isEndOfSlot.current = true;

        // console.log('slot: ', slot);

        setCheckedTime({
            id: index,
            checked: e.value
        })
        setAppointment({ ...appointment, dateTime: slot })
    }

    // console.log('Texas time nè: ', moment.tz(new Date(), 'America/Chicago').format('DD/MM/YYYY HH:mm z'));
    // console.log('Texas time nè: ', moment.tz(new Date(), 'America/Chicago').toString());
    // console.log('SG time nè: ', new Date());
    // console.log('Picked time nè: ', appointment?.date);
    // console.log('So sanh: ', moment(appointment?.date).isSameOrAfter(moment.tz(new Date(), 'America/Chicago')));

    // console.log('Step 1: appointment = ', appointment);

    // Chú ý giờ mở cửa mỗi ngày khác nhau, cộng thêm ngày lễ này nọ cũng sẽ khác nữa
    const getAvailableTime = () => {
        let availableTime = []
        let availableSlots = [...timeSlots]
        // let firstSlot = 0   // firstSlot để làm gì thế??? 
        let lastSlot = availableSlots[availableSlots?.length - 1]?.value    // 0

        const selectedDate = appointment?.date;
        // const selectedDateAtSalon = moment.tz(selectedDate, 'America/Chicago')
        // currentTime in Texas (always in Texas, dymanic change according to seasons)

        // console.log('guest selectedDate = ', selectedDate);
        // console.log('selectedDateAtSalon = ', selectedDateAtSalon.toString());
        // console.log('salon now = ', salonNow.toString());
        // console.log('my time now = ', new Date());

        const current = salonNow.minute() < 10     // 1 chữ số
            ? Number(`${salonNow.hour()}0${salonNow.minute()}`)
            : Number(`${salonNow.hour()}${salonNow.minute()}`);

        /*
            if (selectedDate?.getDay() === 0) {         // Sunday
                availableSlots = [...sundaySlots]
                // firstSlot = 1129    // 11:30
                lastSlot = 1731     // 17:30
            } else if (selectedDate?.getDay() === 6) {  // Saturday
                availableSlots = [...saturdaySlots]
                // firstSlot = 859    // 09:00
                lastSlot = 1901     // 19:00
            } else {                                    // Mon - Fri
                availableSlots = [...weekdaySlots]
                // firstSlot = 929    // 09:30
                lastSlot = 1931     // 19:30
            }
        */

        // console.log('selected', moment(selectedDate).format('YYYY-MM-DD'));
        // console.log('salonNow', salonNow.format('YYYY-MM-DD'));

        // Vì trên lịch tui đã chặn minDate là hôm nay nên selectedDate sẽ luôn ko phải ngày trong QK.
        // Kq so sánh ra < hơn là do time < hơn (tính theo miliseconds).
        // ==>
        // Case 1: Same day   => So điều kiện để loại khung giờ ko phù hợp
        // Case 2: Future day => Tất cả khung giờ đều available

        // Chuyển selectedDate từ timezone của guest qua timezone của salon và so sánh vs currentTime tại salon
        // để tính toán xem lúc này salon còn mở cửa ko, còn những slots nào
        if (moment(moment(selectedDate).format('YYYY-MM-DD')).isSame(salonNow.format('YYYY-MM-DD'))) {  //selectedDateAtSalon
            // console.log('aaaaaaaa', availableSlots);
            availableSlots.forEach(slot => {
                let timeSlot = slot?.value

                // Thời gian cuộc hẹn sớm hơn hiện tại tối thiểu 30p && pickedSlot < lastSlot
                if (((timeSlot - current === 30) || (timeSlot - current >= 70)) && (current < lastSlot)) {
                    availableTime.push(slot?.name)
                }
            });
        }
        else {
            availableSlots.map(slot => {
                availableTime.push(slot?.name)
            })
            // availableTime = [...availableSlots]
        }

        if (availableTime?.length === 0) {
            isEndOfSlot.current = false
        }

        return availableTime;
    }
    const availableTime = getAvailableTime()

    // console.log('now: ', salonNow.format('YYYY-MM-DD'));
    // console.log('14 days after: ', moment().add(14, 'days').format('YYYY-MM-DD'));

    return (
        <Wrapper>
            <DatePicker value={appointment?.date} onChange={pickADate}
                // minDate={new Date()}
                minDate={new Date(salonNow.format('YYYY-MM-DD'))}
                maxDate={new Date(moment().add(14, 'days').format('YYYY-MM-DD'))}
                inline
                selectOtherMonths
                panelStyle={{ padding: '0 0.857rem' }}
            />
            {appointment?.date && (
                <>
                    {availableTime?.length > 0 ? (
                        <TimePicker>
                            {availableTime?.map((slot, index) => {
                                return (
                                    <TimeCard
                                        key={index}
                                        onLabel={slot}
                                        offLabel={slot}
                                        onChange={(e) => pickATime(e, slot, index)}
                                        className="p-button-outlined"
                                        checked={!appointment?.dateTime
                                            ? null // (checkedTime?.id === index ? true : false)
                                            : (appointment?.dateTime === slot ? true : false)}
                                    />
                                )
                            })}
                        </TimePicker>
                    ) : (
                        <Messages ref={message} />
                    )}
                </>
            )}
        </Wrapper>
    );
}

export default Step1DateTime;