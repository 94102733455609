import styled from 'styled-components'

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
`
export const Row = styled.div`
    width: 100%;
    /* min-height: 570px; */
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1100px) {
        grid-template-columns: ${({mainInLeft}) => mainInLeft ? '6fr 5fr' : '5fr 6fr'};
    }
    @media screen and (max-width: 980px) {
        grid-template-columns: ${({mainInLeft}) => mainInLeft ? '4fr 3fr' : '3fr 4fr'};
    }
    @media screen and (max-width: 640px) {
        grid-template-columns: ${({mainInLeft}) => mainInLeft ? '1fr 0' : '0 1fr'};
    }
`
export const Col = styled.div`
    width: 100%;
    /* height: 100%; */
    position: relative;
`
export const Title = styled.h2`
    font-style: italic;
    color: #333;
    font-size: 2.4rem;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: left;
    
    padding-top: 6rem;
    padding-bottom: 0.75rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        width: 120px;
    }

    span {
        font-style: normal;
        /* color: #171416d3; */
        font-family: 'Plaza', sans-serif;
        font-weight: 900;
    }
    .logo-deluxe {
        font-size: 3.5rem;
        margin-left: 0.2rem;
    }
    .logo-nails {
        font-size: 2.4rem;
        margin-top: 0.9rem;
        margin-left: 0.5rem;
    }
    .logo-and {
        font-size: 1.8rem;
        margin: 0 0.25rem;
    }

    @media screen and (max-width: 1100px) {
        padding-top: ${({isFirstRow}) => isFirstRow ? '6rem' : '5rem'};
    }
    @media screen and (max-width: 1024px) {
        padding-top: ${({isFirstRow}) => isFirstRow ? '5rem' : '4rem'};
    }
    @media screen and (max-width: 780px) {
        padding-top: ${({isFirstRow}) => isFirstRow ? '2.5rem' : '4rem'};
    }
    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }
    @media screen and (max-width: 640px) {
        padding-top: 2.5rem;
    }
    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`
export const Content = styled.div`
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    font-size: 1rem;
    line-height: 1.6rem;

    & div + div {
        margin: 1rem 0 0;
        padding: 0;
    }

    @media screen and (max-width: 960px) {
        & div {
            width: 175%;
        }
    }
    @media screen and (max-width: 640px) {
        & div {
            width: 100%;
        }
    }
`
export const Img = styled.img`
    display: none;

    @media screen and (max-width: 640px) {
        display: block;
        width: 85%;
        margin: 1rem auto;
        padding: 0;
        border-radius: 8px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`
export const Img1 = styled.img`
    width: 85%;
    padding: 0;
    /* border: 1.2rem solid white; */
    /* border: 3px solid lightskyblue; */
    border-radius: 50px 50px 50px 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

    position: absolute;
    top: 65px;
    left: 50px;
    z-index: 1;

    @media screen and (max-width: 1100px) {
        left: 40px;
    }
    @media screen and (max-width: 980px) {
        left: 35px;
    }
    @media screen and (max-width: 780px) {
        width: 91%;
        left: 10px;
        top: 55px;
    }
    @media screen and (max-width: 640px) {
        display: none;
    }
`
export const Img2 = styled.img`
    width: 85%;
    padding: 0;
    /* border: 1.2rem solid white; */
    /* border: 3px solid lightskyblue; */
    border-radius: 50px 0 50px 50px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1100px) {
        /* left: 40px; */
    }
    @media screen and (max-width: 980px) {
        /* left: 30px; */
    }
    @media screen and (max-width: 640px) {
        display: none;
    }
`
export const Background1 = styled.div`
    width: 52%;
    height: 66%;
    /* border: 0.75rem solid #FFC09F; */
    background: url(/assets/images/squares-bg.png) repeat;
    
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 0;

    @media screen and (max-width: 1100px) {
        top: 38px;
        height: 50%;
    }
    @media screen and (max-width: 940px) {
        height: 36%;
    }
    @media screen and (max-width: 640px) {
        display: none;
    }
`
export const Background2 = styled.div`
    width: 50%;
    height: 66%;
    /* border: 0.75rem solid #FFC09F; */
    background: url(/assets/images/squares-bg.png) repeat;
    
    position: absolute;
    top: 15px;
    right: 50px;
    z-index: 0;

    @media screen and (max-width: 1100px) {
        /* left: 40px; */
    }
    @media screen and (max-width: 980px) {
        /* left: 30px; */
    }
    @media screen and (max-width: 640px) {
        display: none;
    }
`

export const LastRow = styled.div`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    text-align: center;

    @media screen and (max-width: 1200px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
`
export const LastContent = styled.p`
    font-size: 1.05rem;
    font-style: italic;
    line-height: 2rem;
    
    span {
        font-size: 1.5rem;
        color: #FFC09F;
        /* color: #825142; */
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        font-size: 1rem;
        
        span {
            font-size: 1.4rem;
        }
    }
`