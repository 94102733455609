import React, { useEffect, useRef } from 'react';
// import { useSpring, animated } from 'react-spring';
import { useApp } from '../../../hooks/AppContext';
import { useBooking } from '../BookingContext';
import { Wrapper, Header, StepEmpty, StepFilled, Body, StepIcon, StepPurpose, StepDescription, Footer, Hotline, HotlineNumber } from './InfoPanelItems';
import { DateTimeIcon, ServicesIcon, NoteIcon, ContactIcon, ConfirmIcon, } from '../../../assets/images';
import { Link } from 'react-router-dom';

let renderTime = 0

const InfoPanel = () => {
    const { isActive, setIsActive } = useApp()
    const { step, setStep, preStep, setPreStep } = useBooking()
    const currentStep = useRef(step)
    // const [isBack, setIsBack] = useState(null);

    useEffect(() => {
        // console.log('currentStep = ', currentStep);
        // console.log('step = ', step);
        if (currentStep.current !== step) {
            renderTime++;
            currentStep.current = step
        }
        // console.log('InfoPanel ----- renderTime = ', renderTime);
        // if (preStep > step) {
        //     setIsBack(true)
        // } else {
        //     setIsBack(false)
        // }
    }, [step]);
    // console.log('isBack = ', isBack);

    // const animation = useSpring({
    //     config: { duration: 2500 },
    //     opacity: preStep < step ? 1 : 0,
    //     transform: preStep < step ? 'translateX(0%)' : 'translateX(-200%)'
    // })

    const handleChangeStep = (toStep) => {
        // console.log('from step ', step, 'to step ', toStep);
        setPreStep(step)
        setStep(toStep)

        // Sau này update sau, validStep sẽ là step max mà đã chạm tới,
        // để ko chỉ click lùi lại đc mà còn click tiến nhảy cóc step cũng đc nữa, mà để sau này đi
        // console.log('validStep = ', validStep);
    }


    const renderStepFlag = () => {
        switch (step) {
            case 1:
                return [<StepFilled key="s1" />, <StepEmpty key="s2" />, <StepEmpty key="s3" />, <StepEmpty key="s4" />, <StepEmpty key="s5" />];
            case 2:
                return [<StepFilled key="s1" onClick={() => handleChangeStep(1)} />,
                <StepFilled key="s2" onClick={() => handleChangeStep(2)} />,
                <StepEmpty key="s3" />,
                <StepEmpty key="s4" />,
                <StepEmpty key="s5" />];
            case 3:
                return [<StepFilled key="s1" onClick={() => handleChangeStep(1)} />,
                <StepFilled key="s2" onClick={() => handleChangeStep(2)} />,
                <StepFilled key="s3" onClick={() => handleChangeStep(3)} />,
                <StepEmpty key="s4" />,
                <StepEmpty key="s5" />];
            case 4:
                return [<StepFilled key="s1" onClick={() => handleChangeStep(1)} />,
                <StepFilled key="s2" onClick={() => handleChangeStep(2)} />,
                <StepFilled key="s3" onClick={() => handleChangeStep(3)} />,
                <StepFilled key="s4" onClick={() => handleChangeStep(4)} />,
                <StepEmpty key="s5" />];
            case 5:
                return [<StepFilled key="s1" onClick={() => handleChangeStep(1)} />,
                <StepFilled key="s2" onClick={() => handleChangeStep(2)} />,
                <StepFilled key="s3" onClick={() => handleChangeStep(3)} />,
                <StepFilled key="s4" onClick={() => handleChangeStep(4)} />,
                <StepFilled key="s5" onClick={() => handleChangeStep(5)} />];
            case 6:
                return [<StepFilled key="s1" />, <StepFilled key="s2" />, <StepFilled key="s3" />, <StepFilled key="s4" />, <StepFilled key="s5" />];
            default:
                return [<StepEmpty key="s1" />, <StepEmpty key="s2" />, <StepEmpty key="s3" />, <StepEmpty key="s4" />, <StepEmpty key="s5" />];
        }
    }

    const getStepPurpose = () => {
        switch (step) {
            case 1:
                return (
                    // <animated.div style={animation}>
                    <>
                        <StepIcon src={DateTimeIcon} alt="date time icon" />
                        <StepPurpose>Date and Time</StepPurpose>
                        <StepDescription>
                            Please book at least 30 minutes before you come, so we can prepare the best service for you.
                        </StepDescription>
                    </>
                    // </animated.div>
                )
            case 2:
                return (
                    <>
                        <StepIcon src={ServicesIcon} alt="services icon" />
                        <StepPurpose>Services</StepPurpose>
                        <StepDescription>
                            You can book multi services for the appointment.
                        </StepDescription>
                    </>
                )
            case 3:
                return (
                    <>
                        <StepIcon src={NoteIcon} alt="additional requirements icon" />
                        <StepPurpose>Additional Requirements</StepPurpose>
                        <StepDescription>
                            If you have any further requirements, please leave the note here for us to prepare.
                        </StepDescription>
                    </>
                )
            case 4:
                return (
                    <>
                        <StepIcon src={ContactIcon} alt="contact info icon" />
                        <StepPurpose>Customer Information</StepPurpose>
                        <StepDescription>
                            Help us to contact you through these information.
                        </StepDescription>
                    </>
                )
            case 5:
                return (
                    <>
                        <StepIcon src={ConfirmIcon} alt="confirm icon" />
                        <StepPurpose>Confirm Appointment</StepPurpose>
                        <StepDescription>
                            Please re-check your selected services in the right panels.
                        </StepDescription>
                    </>
                )
            case 6:
                return (
                    <>
                        <StepIcon src={ConfirmIcon} alt="booked successfully icon" />
                        <StepPurpose>Success</StepPurpose>
                        <StepDescription>
                            In meantime, you can explore more about our services at <Link to='/services' onClick={() => setIsActive({ ...isActive, pageName: 'services' })}>here</Link>.
                            <br /><br />
                            <em>Serving you is our pleasure!</em>
                        </StepDescription>
                    </>
                )

            default:
                break;
        }
    }

    // console.log(preStep, `${preStep > step ? '>' : '<'}`, step);
    // console.log('isBack = ', preStep > step);
    // renderTime++

    return (
        <Wrapper>
            <Header>
                {renderStepFlag()}
            </Header>
            <Body key={renderTime} isBack={preStep > step}>
                {getStepPurpose()}
            </Body>
            <Footer>
                <Hotline>Hotline</Hotline>
                <HotlineNumber>Please call us at <a href="tel:+1 (972) 304-1199">(972) 304-1199</a></HotlineNumber>
            </Footer>
        </Wrapper>
    );
}

export default InfoPanel;