import React from 'react'
import { Container, Wrapper, Heading, Row, FeedbackCard, CurveBg, QuoteSymbol, Note, CustomerInfo, Avatar, Name, FeedbackContent, Rating } from './TestimonialSectionItems'
import { FiveStars, FourStars, QuoteIcon } from '../../../../assets/images'
import { FaGooglePlus, FaYelp } from 'react-icons/fa'
import { feedbacks } from '../data'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles (of the library)
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "./slider.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from 'swiper'

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

function TestimonialSection() {
    return (
        <section id='testimonial'>
            <Container>
                <Wrapper>
                    <Heading data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">
                        What our customers say
                        <span style={{ fontFamily: 'Destain, sans-serif', fontSize: '2.1rem' }}>?</span>
                    </Heading>
                    <Row>
                        <Swiper
                            loop={true} pagination={{ clickable: true }}
                            slidesPerView={1} spaceBetween={25}
                            // slidesPerView={3} spaceBetween={40}
                            grabCursor={true} centeredSlides={true}
                            // navigation={true} slidesPerView={'auto'}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false
                            // }}
                            breakpoints={{
                                1160: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                1024: { // min-width
                                    slidesPerView: 3,
                                    spaceBetween: 20
                                },
                                960: { // min-width
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },
                                780: {
                                    slidesPerView: 2,
                                    spaceBetween: 30
                                },
                                680: {
                                    slidesPerView: 2,
                                    spaceBetween: 25
                                },
                                660: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                                }
                            }}
                            className="feedback-swiper"
                        >
                            {feedbacks?.map(feedback => (
                                <SwiperSlide key={feedback?.id}>
                                    <FeedbackCard key={feedbacks?.id}>
                                        <CurveBg />
                                        <QuoteSymbol src={QuoteIcon} />
                                        <Note>Review taken from
                                            <a href={feedback?.source} target='_blank' aria-label={`review from ${feedback?.reviewFrom}`}>
                                                {feedback?.reviewFrom === 'Yelp' ? <FaYelp /> : <FaGooglePlus />}
                                            </a>
                                        </Note>
                                        <CustomerInfo>
                                            <Avatar src={feedback?.avatar} alt={`${feedback?.cusName} avatar`} />
                                            <Name>{feedback?.cusName}</Name>
                                        </CustomerInfo>
                                        <FeedbackContent>{feedback?.feedback}</FeedbackContent>
                                        <Rating src={feedback?.stars === 5 ? `${FiveStars}` : `${FourStars}`} />
                                    </FeedbackCard>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </Wrapper>
            </Container>
        </section>
    )
}

export default TestimonialSection
