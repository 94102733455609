import React from 'react';
import { HeaderContainer, HeaderWrapper, PhoneWrapper, PhoneIcon, PhoneNumber, OpeningHoursWrapper, ClockIcon, SocialWrapper, SocialIconLink } from './HeaderItems'
import { FaPhoneSquareAlt, FaClock, FaFacebook, FaGooglePlus, FaYelp } from 'react-icons/fa'
import Typed from "react-typed";

const Header = () => {
    return (
        <HeaderContainer>
            <HeaderWrapper>
                <PhoneWrapper>
                    <PhoneIcon><FaPhoneSquareAlt /></PhoneIcon>
                    <PhoneNumber href="tel:+1 (972) 304-1199">(972) 304-1199</PhoneNumber>
                </PhoneWrapper>
                <OpeningHoursWrapper>
                    <strong>Opening Hours:</strong>
                    <ClockIcon><FaClock /></ClockIcon>
                    <Typed typeSpeed={50} backSpeed={10} startDelay={1500} backDelay={2000} loop
                        strings={[
                            '9:30 AM - 7:30 PM (Mon-Fri)',
                            '9:00 AM - 7:00 PM (Sat)',
                            '11:30 AM - 5:30 PM (Sun)'
                        ]}
                    />
                </OpeningHoursWrapper>
                <SocialWrapper>
                    <span>Follow us at</span>
                    <SocialIconLink href='//www.facebook.com/Deluxe-Nails-Spa-Coppell-537454632943839/' target='_blank' aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href='//www.google.com/search?q=Deluxe+Nails+%26+Spa+Coppell&rlz=1C1ONGR_enVN952VN952&biw=1536&bih=731&ei=CsbuYISSAtSRoATnxZrwBQ&oq=Deluxe+Nails+%26+Spa+Coppell&gs_lcp=Cgdnd3Mtd2l6EAMyCwguEMcBEK8BEJMCMgYIABAWEB46BwgAEEcQsAM6EQgAELADEIoDELcDENQDEOUCSgQIQRgAUJIdWOgeYN8iaAFwAngAgAFpiAG6ApIBAzAuM5gBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwiEv9qqtuLxAhXUCIgKHeeiBl4Q4dUDCA4&uact=5' target='_blank' aria-label="Google">
                        <FaGooglePlus />
                    </SocialIconLink>
                    {/* <SocialIconLink href='#' aria-label="Instagram">
                                    <FaInstagram />
                                </SocialIconLink> */}
                    <SocialIconLink href='//www.yelp.com/biz/deluxe-nails-and-spa-coppell' target='_blank' aria-label="Yelp">
                        <FaYelp />
                    </SocialIconLink>
                </SocialWrapper>
            </HeaderWrapper>
        </HeaderContainer>
    );
}

export default Header;
