import React from 'react'
import { Container, CoverImg, Title } from './PageCoverItems'

function PageCover({ title, coverImgSrc }) {
    return (
        <Container>
            <CoverImg src={coverImgSrc} alt={`Page ${title} cover`} />
            <Title>{title}</Title>
        </Container>
    )
}

export default PageCover