import React from 'react'
import {
    Container, Wrapper, Heading, ListServices, ServiceCard,
    ImgWrapper, Img, TextWrapper, Name, Description,
    ButtonWrapper, BtnExplore,
    DecorationImg1, DecorationImg2, DecorationImg3, DecorationImg4
} from './ServicesSectionItems'
import { DecoImg1, DecoImg2, DecoImg3, DecoImg4 } from '../../../../assets/images'
import { listServiceGroups } from '../data'
import { Link } from 'react-router-dom';
import { ImArrowRight2 } from 'react-icons/im';
import { useApp } from '../../../../hooks/AppContext'
import { trimStr } from '../../../../utils/String';

function ServicesSection() {
    const { isActive, setIsActive } = useApp()

    return (
        <section id='services'>
            <Container>
                <DecorationImg1 src={DecoImg1} alt="nail polish gel, butterfly, background image for decoration" />
                <DecorationImg2 src={DecoImg2} alt="nail polish gel, butterfly, background image for decoration" />
                <DecorationImg3 src={DecoImg3} alt="nail polish gel, butterfly, background image for decoration" />
                <DecorationImg4 src={DecoImg4} alt="nail polish gel, butterfly, background image for decoration" />

                <Wrapper>
                    <Heading data-aos="zoom-in" data-aos-anchor-placement="bottom-bottom">
                        Our Services
                    </Heading>
                    {listServiceGroups &&
                        <ListServices>
                            {listServiceGroups.map(group => {
                                const serviceName = group?.name?.includes('Kids')
                                    ? 'KidsMenu'
                                    : trimStr(group?.name)

                                return (
                                    <Link to={`/services#${serviceName}`} style={{ textDecoration: 'none' }} key={group?.id}
                                        onClick={() => setIsActive({ ...isActive, pageName: 'services' })}
                                    >
                                        <ServiceCard
                                            data-aos="fade-up"
                                            // data-aos-delay="50" 
                                            data-aos-anchor-placement="center-bottom"
                                            id={`service-${group?.id}`}
                                        >
                                            <ImgWrapper>
                                                <Img src={group?.imgSrc} alt={group?.name} />
                                            </ImgWrapper>
                                            <TextWrapper>
                                                <Name>{group?.name}</Name>
                                                <Description>{group?.description}</Description>
                                            </TextWrapper>
                                        </ServiceCard>
                                    </Link>
                                )
                            })}
                        </ListServices>
                    }
                    <ButtonWrapper data-aos="zoom-out-up" data-aos-anchor-placement="center-bottom">
                        <Link to='/services' onClick={() => setIsActive({ ...isActive, pageName: 'services' })}>
                            <BtnExplore>
                                Explore now <ImArrowRight2 id="arrow-icon" style={{ marginLeft: '0.2rem' }} />
                            </BtnExplore>
                        </Link>
                    </ButtonWrapper>
                </Wrapper>
            </Container>
        </section>
    )
}

export default ServicesSection
