import React, { useState, useContext, createContext } from 'react';
import { BlankAvatar } from '../../assets/images';

const BookingContext = createContext()

export function useBooking() {
    return useContext(BookingContext)
}

function useBookingProvider() {
    const [step, setStep] = useState(1);
    const [preStep, setPreStep] = useState(1);
    const [validStep, setValidStep] = useState(1);
    const [appointment, setAppointment] = useState({});

    // list services in step 2  | list technicians in step 3
    const [servciesData, setServciesData] = useState(null)
    const [technicians, setTechnicians] = useState([{ id: 0, name: 'Anyone', avatar: BlankAvatar }])

    // console.log('context: ', appointment);

    return {
        step, setStep, preStep, setPreStep,
        validStep, setValidStep,
        appointment, setAppointment,
        servciesData, setServciesData,
        technicians, setTechnicians
    }
}

function BookingProvider(props) {
    const { children } = props
    const booking = useBookingProvider()

    return (
        <BookingContext.Provider value={booking}>
            {children}
        </BookingContext.Provider>
    )
}

export default BookingProvider;