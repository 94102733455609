import styled from 'styled-components';

export const HeaderContainer = styled.div`
    width: 100%;
    height: 32px;
    background-color: #FFDECF;  // #FFC09F
    z-index: 10;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
`
export const HeaderWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        /* max-width: 1120px; */
        padding: 0 1.2rem;
    }
    @media screen and (max-width: 576px) {
        padding: 0 0.3rem;
    }
    @media screen and (max-width: 480px) {
        padding-left: 0.4rem;
    }
    /* @media screen and (max-width: 1120px) {
        max-width: 960px;
    } */
    /* @media screen and (max-width: 960px) {
        width: 100%;
    } */
`

export const PhoneWrapper = styled.div`
    min-width: 200px;
    height: 32px;
    display: flex;
    flex-shrink: 1;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        height: 23px;
        position: absolute;
        border-right: 2px solid #333333;
        top: 50%;
        right: 33px;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 780px) {
        min-width: max-content;
        /* padding-left: 1rem; */
        padding-right: 1.2rem;
        
        &::before {
            display: none;
        }
    }
`
export const PhoneIcon = styled.div`
    color: #333333;
    font-size: 1.4rem;
    margin-top: 0.3rem;
    margin-right: 0.5rem;
    
    @media screen and (max-width: 576px) {
        margin-right: 0.25rem;
    }
    /* @media screen and (max-width: 495px) {
        display: none;
    } */
`
export const PhoneNumber = styled.a`
    color: rgb(0, 0, 238);  /* color: #333333; */
    font-size: 0.945rem;
    line-height: 32px;
    /* text-decoration: none; */
    font-weight: 400;

    &:hover {
        font-weight: 600;
    }
`

export const OpeningHoursWrapper = styled.div`
    min-width: 360px;
    display: flex;
    flex-grow: 1;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;

    color: #333333;
    font-size: 0.945rem;
    line-height: 32px;

    strong {
        margin-right: 0.5rem;
    }

    @media screen and (max-width: 780px) {
        min-width: max-content;
        padding: 0;
        justify-content: end;
    }
    @media screen and (max-width: 576px) {
        strong {
            margin-right: 0.25rem;
        }
    }
    @media screen and (max-width: 495px) {
        strong {
            display: none;
        }
    }
`
export const ClockIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 495px) {
        display: block;
        color: #333333;
        font-size: 1.2rem;
        margin-top: 0.3rem;
        margin-right: 0.25rem;
    }
`

export const SocialWrapper = styled.div`
    min-width: 200px;
    height: 32px;
    margin-left: auto;
    padding: 0;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #333333;
    span {
        font-weight: 600;
        font-size: 0.945rem;
        line-height: 32px;
    }

    @media screen and (max-width: 780px) {
        display: none;
    }
`
export const SocialIconLink = styled.a`
    color: #171416d3;
    font-size: 1.25rem;
    
    &:hover {
        color: #171416;
        transform: scale(1.15);       
        transition: 0.1s;
    }
`