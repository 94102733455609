// import moment from "moment";
// import { moment as momentTz } from "moment-timezone";
import moment from "moment-timezone";

export const parseDateToString = (date, format) => {
    return moment(date).format(format);
}

export const parseDateToUTC = (date, format) => {
    // console.log('......', moment(date).utc().format(format));
    return moment(date).utc(true).format(format);
}

export const getUTCTimeZone = (date) => {
    return moment(date).tz('America/Chicago').format('z')
    // return String(date).substr(String(date).indexOf('GMT'), 6);
}

// export const convetHourStringToNumber = (hourStr) => {
//     const tmp = hourStr?.split(':');
//     tmp[1] = tmp[1]?.split(' ')[0]
//     console.log('tmp = ', tmp);
//     return Number(tmp.join(''));
// }