import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import Layout from '../../layout/Layout';
import { BodyWrapper, CollectionWrapper, CollectionSelector, ImgWrapper, } from './GalleryItems'
import { SelectButton } from 'primereact/selectbutton'
import { GalleryCoverImg } from '../../assets/images'
import { Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12 } from '../../assets/images/gallery'

function Gallery() {
    // const [collection, setCollection] = useState(1)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const _collections = [
    //     { name: 'Nails Design', value: 1 },
    //     { name: 'Nails Salon', value: 2 }
    // ]

    const _gallery = [
        // { id: 1, src: Img1, collectionId: 1 },
        { id: 2, src: Img2, collectionId: 1 },
        { id: 3, src: Img3, collectionId: 1 },
        { id: 4, src: Img4, collectionId: 1 },
        { id: 5, src: Img5, collectionId: 1 },
        { id: 6, src: Img6, collectionId: 1 },
        { id: 7, src: Img7, collectionId: 1 },
        { id: 8, src: Img8, collectionId: 1 },
        { id: 9, src: Img9, collectionId: 1 },
        { id: 10, src: Img10, collectionId: 2 },
        { id: 11, src: Img11, collectionId: 2 },
        // { id: 12, src: Img12, collectionId: 1 }
    ]

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>Gallery | Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="Gallery images of Deluxe Nails & Spa | (972) 304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019 | Services: Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and more!" />
                <meta name="keywords" content="Deluxe, Nails, Spa, Beauty, Nails Salon, Coppell, TX 75019, Texas, gallery" />
                <link rel="canonical" href="https://deluxenailsspacoppell.com/" />
            </Helmet>

            <Layout title="Gallery" coverImgSrc={GalleryCoverImg} isNotFound={false}>
                <BodyWrapper>
                    <CollectionWrapper>
                        <CollectionSelector>
                            {/* <SelectButton value={collection} options={_collections} onChange={(e) => {
                                // setCollection(e.value)
                                console.log(e.value);
                            }} /> */}
                        </CollectionSelector>
                    </CollectionWrapper>
                    <ImgWrapper>
                        {_gallery?.map(img => <img src={img?.src} key={img?.id} alt="nice nails" />)}
                    </ImgWrapper>
                </BodyWrapper>
            </Layout>
        </>
    )
}

export default Gallery
