import React, { useState } from 'react'
import {
    Container, Wrapper, Row, Col1, Col2, TextWrapper, TopLine, Heading, SubTitle,
    BtnWrapper, Button, ImgWrapper, Img
} from './InfoSectionItems'
import { Link } from 'react-router-dom'
import { uploadImg } from './TestApi'

import FormData from 'form-data'

function InfoSection(props) {
    const { id, lightBg, imgStart, topLine, headLine, lightText, darkText, description, buttonLabel, img, alt, primary, dark, dark2 } = props

    const [fileImg, setFileImg] = useState(null)
    const [fileName, setFileName] = useState(null)

    const handleChangeFile = (event) => {
        const file = event.target.files[0]
        // console.log('file info = ', event);
        // console.log('file = ', file);
        setFileImg(file)
    }
    const uploadImage = () => {
        if (fileImg) {
            // console.log('vô ', fileImg);
            // const data = {
            //     imageFile: fileImg
            // }
            let data = new FormData();
            data.append('imageFile', fileImg, fileImg.name);
            uploadImg(data).then(res => {
                // console.log('Uploaded file ', res);
                setFileName(fileImg?.name)
            }).catch(error => {
                console.error('Error at Uploading file', error)
            })
        }
        // console.log('raa');
    }

    return (
        <>
            <Container id={id} lightBg={lightBg}>
                <Wrapper>
                    <Row imgStart={imgStart}>
                        <Col1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <SubTitle darkText={darkText}>{description}</SubTitle>
                                <BtnWrapper>
                                    {/* <Link> */}
                                    <Button to="aboutUs" smooth={true} duration={500} spy={true}
                                        exact="true" offset={-80} primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0} dark2={dark2 ? 1 : 0}>
                                        {buttonLabel}
                                    </Button>
                                    {/* </Link> */}
                                </BtnWrapper>
                            </TextWrapper>
                        </Col1>
                        <Col2>
                            <ImgWrapper>
                                {/* <Img src={img} alt={alt} /> */}
                            </ImgWrapper>
                            <input type="file" onChange={handleChangeFile} />
                            <button type="button" onClick={uploadImage}>Upload</button>
                            <br />
                            {fileName &&
                                <img src={`https://api.deluxenailsspacoppell.com/deluxenails-be-0.0.1-SNAPSHOT/photos/${fileName}`} />
                            }
                        </Col2>
                    </Row>
                </Wrapper>
            </Container>
        </>
    )
}

export default InfoSection
