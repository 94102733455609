import React, { useEffect, useState } from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { FormCheckIn } from '../form'
import { BodyContainer, Title } from './Step1Items'

function Step1() {
  const [windowWidth] = useWindowSize()
  const [titleHeight, setTitleHeight] = useState(0)

  useEffect(() => {
    setTitleHeight(24 +
      document.getElementById('md-title-welcome')?.offsetHeight
    )
  }, [windowWidth])

  return (
    <BodyContainer>
      <Title id='md-title-welcome'>
        Welcome to
        <br />
        <span>
          Deluxe Nails & Spa
        </span>
      </Title>

      <FormCheckIn
        style={{ height: `calc(100% - ${titleHeight}px)` }}
      />
    </BodyContainer>
  )
}

export default Step1