import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 0.5rem 0.5rem 0;
    display: flex;
    align-items: center;
    /* border: 1px solid black; */

    @media screen and (max-width: 768px) {
        height: auto;
        padding-top: 1rem;
    }
`
export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    /* border: 1px solid red; */
`