import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll'
import { FaBars } from 'react-icons/fa';
import { useApp } from '../../hooks/AppContext';
import { BookingModal } from '..';
import BookingProvider from '../BookingModal/BookingContext';
import {
    Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem,
    NavLinks, BtnRewardsWrapper, BtnRewards, BookingZone, BookingBtn
} from './HomeNavbarItems';

function HomeNavbar(props) {
    const { showModal, setShowModal, setShowSidebar } = props
    const [scrollNav, setScrollNav] = useState(false)

    const { isActive, setIsActive } = useApp()

    const handleChangeNav = () => {
        if (window.scrollY >= 95) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleChangeNav)
    }, [])

    const backToTop = () => {
        scroll.scrollToTop();
    }

    const handleMenuClick = (pageName) => {
        setIsActive({ ...isActive, pageName: pageName })
    }

    return (
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={backToTop}>
                    <span className="logo-deluxe">dELUXE</span>
                    <span className="logo-nails">
                        nAILS
                        <span className="logo-and">&amp;</span>
                        sPA
                    </span>
                </NavLogo>
                <div style={{ flexGrow: 1 }}></div>
                <MobileIcon onClick={() => setShowSidebar(true)}><FaBars /></MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="about" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>
                            About Us
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="services" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>
                            Services
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="technicians" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>
                            Technicians
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="gallery" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>
                            Gallery
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="testimonial" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>Testimonial</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="contact" smooth={true} duration={500} spy hashSpy
                            exact="true" offset={-60}>
                            Contact Us
                        </NavLinks>
                    </NavItem>
                </NavMenu>

                <BtnRewardsWrapper>
                    <BtnRewards to="/rewards"
                        onClick={() => handleMenuClick('rewards')}
                        className={(isActive?.isClicked && isActive?.pageName === 'rewards') ? 'active' : ''}
                    >
                        Rewards
                    </BtnRewards>
                </BtnRewardsWrapper>

                <BookingZone>
                    <BookingBtn onClick={() => setShowModal(true)}>
                        Book now
                    </BookingBtn>  {/**to="/services" */}
                </BookingZone>
                <BookingProvider>
                    <BookingModal showModal={showModal} setShowModal={setShowModal} />
                </BookingProvider>
            </NavbarContainer>
        </Nav>
    );
}

export default HomeNavbar;
