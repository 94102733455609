import styled from 'styled-components';
import { Button } from 'primereact/button';
import { MdClose } from 'react-icons/md';

export const Wrapper = styled.div`
    /* width: 100%; */
    /* height: 100%; */
    position: relative;

    /* border: 1px solid black; */

    @media screen and (max-width: 480px) {
        width: calc(100vw - 0.3rem);
        height: ${({ step }) => step === 6 ? '300px' : '78vh'};

        /* width: 418px; */
        /* width: 470px; */
    }
`

export const Header = styled.div`
    /* width: 100%; */
    height: 40px;
    padding: 8px 16px;
    /* border-bottom: 1px solid #EAEAEA; */
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.05);

    /* border: 1px solid black; */
`
export const Title = styled.h3`
    font-size: 1rem;
    animation: ${({ isChanged }) => isChanged ? 'appear 750ms cubic-bezier(0.05, -0.28, 0.87, 1.68)' : ''};
    
    @keyframes appear {
        0% {
            opacity: 1;
            color: inherit;
            transform: translateX(0%);
        }
        50% {
            opacity: 0;
            color: #fff;
            transform: translateX(-30%);
        }
        100% {
            opacity: 1;
            color: inherit;
            transform: translateX(0%);
        }
    }
`
export const CloseButton = styled(MdClose)`
    width: 1.35rem;
    height: 1.35rem;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    z-index: 10;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
`

export const Body = styled.div`
    /* width: 100%; */
    height: ${({ dateTime }) => dateTime ? '522px' : '570px'};
    overflow-y: auto;
    padding: 12px 16px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    /* border: 1px solid red; */

    @media screen and (max-width: 480px) {
        height: calc(78vh - 40px - 48px);
        /* height: calc(100% - 40px - 48px);  // cái này đúng ko nhỉ??? */
        // Ko biết vì lý do gì đó mà ngay cả khi mở full large screen (on laptop) (tức là max-width > 480px)
        // thì cái này nó vẫn tính toán lại height => dẫn tới việc cái footer nó cứ nhảy lên hoài
    }
`

export const Footer = styled.div`
    /* width: 100%; */
    height: 48px;
    padding: 0 8px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* border-top: 1px solid #EAEAEA; */
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);

    /* border: 1px solid blueviolet; */
`
// export const BackButton = styled(Button)`
//     display: flex;
//     align-items: center;
//     /* justify-content: center; */
//     padding-left: 1rem;
//     /* background-color: #9d14dc; */
// `
export const NextButton = styled(Button)`
    display: flex;
    align-items: center;
    padding-left: 1rem;
    margin-right: 0.5rem;

    /* background-color: #50ffbc; */
    background-color: #FFC09F;
    color: #171416;
    border: none;

    &:hover {
        background: #ffcbaf !important;     //#f5b3a2
        color: #171416 !important;
    }
`
export const ConfirmButton = styled(Button)`
    align-items: center;
    color: #171416;
    background-color: #FFC09F;
    border: none;
    margin-right: 1rem;

    &:hover {        
        background: #ffcbaf !important;     //#f5b3a2
        color: #171416 !important;
        transition: none !important;
        /* background-color: #56A641 !important; */
    }
`
// export const BtnLabel = styled.p`
//     font-size: 1rem;
//     font-weight: 500;
//     margin-top: -0.1rem;
// `