import React from 'react';
import {
    Container, Wrapper, Row, ColImage, PrimaryImgWrapper, PrimaryImg, Background, SecondaryImg,
    ColContent, TextWrapper, Heading, SubTitle, Content,
    ButtonWrapper, BtnReadMore, DecorationImg
} from './AboutUsSectionItems';
import { FlowerBg, DecoImg5, GelNailsImg } from '../../../../assets/images'
import { ImArrowRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import Typed from "react-typed";
import { useApp } from '../../../../hooks/AppContext'

const AboutUsSection = () => {
    const { isActive, setIsActive } = useApp()

    return (
        <Container>
            <Wrapper>
                <Row>
                    <ColImage data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
                        <Background />
                        <PrimaryImgWrapper>
                            <PrimaryImg src={GelNailsImg} alt="nails polish" />
                        </PrimaryImgWrapper>
                        <SecondaryImg src={FlowerBg} alt="orchid flower, background image for decoration" />
                    </ColImage>

                    <ColContent data-aos="zoom-out" data-aos-anchor-placement="center-bottom">
                        <TextWrapper>
                            <Heading>Welcome to {' '}
                                <span className="logo-deluxe">dELUXE</span>
                                <span className="logo-nails">
                                    nAILS
                                    <span className="logo-and">&amp;</span>
                                    sPA
                                </span>
                            </Heading>
                            <SubTitle>
                                <span style={{ fontSize: '1.6rem', color: '#FFC09F' }}>❝ </span>
                                <Typed typeSpeed={50} backSpeed={10} startDelay={1500} backDelay={2000} loop
                                    strings={['Every year we help thousands of clients feel and look even more stylish.', 'Get your nails ready for your next big event with a mani and pedi from our salon.']}
                                />
                                <span style={{ fontSize: '1.6rem', color: '#FFC09F' }}>❞</span>
                            </SubTitle>
                            <Content>
                                Located at a beautiful corner in <b>Coppell, Texas 75019</b>, <strong>Deluxe Nails &amp; Spa</strong> has been received so many compliments and recommendations from customers.
                                <br />
                                This is because our Nail Salon always puts all of our heart into job.
                                <span className='p-d-block' style={{ marginBottom: '0.6rem' }}></span>
                                <strong>Deluxe Nails &amp; Spa</strong> won a <em><a href="https://nextdoor.com/pages/delux-nails-spa-coppell-tx/">Neighborhood Favorite</a></em> in 4 continuous years (2017, 2018, 2019, 2020) in Coppell, TX.
                            </Content>

                            <ButtonWrapper>
                                <Link to="/about" onClick={() => setIsActive({ ...isActive, pageName: 'about' })}>
                                    <BtnReadMore>
                                        Learn more about us <ImArrowRight2 id="arrow-icon" style={{ marginLeft: '0.2rem' }} />
                                    </BtnReadMore>
                                </Link>
                            </ButtonWrapper>
                        </TextWrapper>
                    </ColContent>
                </Row>
            </Wrapper>
            <DecorationImg src={DecoImg5} alt="nail polish gel, background image for decoration" />
            {/* <DecorationImg src={OrchidFlower2} /> */}
        </Container>
    );
}

export default AboutUsSection;
