import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { FaCalendarCheck } from 'react-icons/fa';

export const IntroContainer = styled.div`
    /* background: #0c0c0c; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20% 3% 0;  //top - right & left - bottom
    /* height: 800px; */
    height: 100vh;
    margin-top: -60px;  // nhích lên để che đủ cái navbar
    /* margin-top: -92px;  // nhích lên để che đủ cái header & navbar // => ko đc r, nó che luôn header */
    position: relative;
    z-index: 0;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.35) 100%),
                    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }

    @media screen and (max-width: 768px) {
        padding: 36% 4% 0;
    }
`

export const IntroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* height: 100vh; */
    /* height: calc(100vh - 30px); */
    overflow: hidden;
`

// export const ImageBg = styled.img`
//     width: 100%;
//     height: 90%;
//     -o-object-fit: cover;
//     object-fit: cover;
//     background: #232a34;
// `

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    /* height: 95%; */
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;   // 232a34

    // Vs màn hình nhỏ thì cho height của video ngắn lại để đỡ scroll
    @media screen and (max-width: 480px) {
        display: none;
    }
    @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
    }
    /* @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
    } */
`
export const GifBg = styled.img`
    display: none;
    
    @media screen and (max-width: 480px) {
        display: block;
        -o-object-fit: fill;
        object-fit: fill;
        background: #fff;   // 232a34
    }

    @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
    }
    @media (max-aspect-ratio: 16/9) {
        width: 165%;
        height: 97%;
    }
`

export const IntroContent = styled.div`
    z-index: 3;
    max-width: 700px;
    position: absolute;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const IntroH1 = styled.h1`
    color: #fff;
    font-size: 45px;
    text-align: left;

    padding-bottom: 1.4rem;
    position: relative;
    /* border-bottom: 5px solid #F64A5F;
    border-image: linear-gradient(to right, #F64A5F 50%, rgba(0,0,0,0) 50%); */

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        width: 25%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        max-width: 480px;
    }
    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`

export const IntroP = styled.p`
    margin-top: 20px;
    margin-left: 0.2rem;
    color: #fff;
    font-size: 20px;
    text-align: left;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        max-width: 480px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
        max-width: 480px;
    }
`

export const IntroBtnWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
`
export const BtnExplore = styled(Link)`
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    color: #171416;
    /* text-transform: uppercase; */
    
    /* color: #fff; */
    background: #FFC09F;    // #F64A5F
    padding: 14px 40px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
        color: #171416;
        /* letter-spacing: 1px; */
    }

    @media screen and (max-width: 820px) {
        display: none;
    }
`
export const BtnBooking = styled.button`
    font-size: 22px;
    font-weight: 600;
    color: #171416;
    background: #FFC09F;
    padding: 14px 40px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* text-transform: uppercase; */
    display: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
        color: #171416;
    }
    
    @media screen and (max-width: 820px) {
        padding: 12px 25px;
        display: block;
    }
    @media screen and (max-width: 480px) {
        font-size: 20px;
        padding: 10px 20px;
    }
`
export const BtnCheckIn = styled.button`
    margin-left: 20px;
    padding: 12px 40px;

    font-size: 22px;
    font-weight: 600;
    color: #FFC09F;
    /* background: #ffe4d679; */
    background: none;
    border-radius: 50px;
    outline: none;
    border: 3px solid #FFC09F;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* text-transform: uppercase; */

    box-shadow: 0px 5px 12px rgb(255, 192, 159, 0.6);

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
        color: #171416;
    }
    
    @media screen and (max-width: 820px) {
        padding: 12px 25px;
    }
    @media screen and (max-width: 480px) {
        font-size: 20px;
        padding: 10px 20px;
    }
`