import styled from 'styled-components';
import { IconBg } from '../../../assets/images';

export const Wrapper = styled.div`
    width: 100%;
    padding: 0.25rem 0.5rem 0;
    display: flex;
    flex-direction: column;

    /* border: 1px solid black; */
`

export const LoadingIcon = styled.i`
    font-size: 4rem;
    color: gray;
    margin-top: 1.5rem;
`

// export const ServicePanel = styled(Panel)`
/* div {
    transition: all 7s ease-out 0.5s;
    --webkit-transition: all 7s ease-out 0.5s;
    opacity: 0;
    height: 0;
} */
/* div.show {
    opacity: 1;
} */
// `
export const CardServiceGroup = styled.div`
    width: 100%;
    height: 75px;
    padding: 0 0.75rem 0 0.3rem;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: 2px solid #d0d0d0;
    border-radius: 3px;
    cursor: pointer;

    background-color: ${({ collapsed }) => !collapsed && '#FFC09F50'};
    border: ${({ collapsed }) => !collapsed && '3px solid #FFC09F'};

    /* &.slide-fade-show {
        opacity: 1;
        height: 75px;
    } */

    &:hover {
        /* background-color: #f4f4f4; */
        background-color: #FFC09F50;
        border-color: #FFC09F;

        h3 { margin-left: 0.75rem}
        img { transform: scale(1.2) }
    }
`
// export const GroupIconBg = styled.div`
//     width: 50px;
//     height: 50px;
//     margin: 0 0.5rem;
//     padding: 0;
//     background: url('../../../assets/images/bg.jpg');
// `
export const GroupIcon = styled.img`
    width: 50px;
    height: 50px;
    margin: 0 0.5rem;
`
export const GroupName = styled.h3`
    margin: 0 0.5rem;
    font-weight: 600;
`
export const GroupQuantity = styled.p`
    color: gray;
    font-size: 0.9rem;
    margin-right: 1rem;
`

export const CardServicesWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ groupId }) => [5, 6].includes(groupId) ? '7fr 5fr' : ''};

    /* height: 200px; */
`
export const CardService = styled.div`
    width: 100%;
`
export const ServiceName = styled.h4`
    /* margin-bottom: 0.5rem; */
`
export const FlavorWrapper = styled.div`
    width: 100%;    // 40%
    margin:  0.5rem 1rem;
    font-size: 0.9rem;

    display: grid;
    grid-template-columns: ${({ length }) => length > 1 ? '7fr 5fr' : ''};
    /* grid-gap: 5px; */
`