import React from 'react'
import { DecoImg7 } from '../../../assets/images'
import { useCheckIn } from '../hooks/CheckInContext'
import { Step1, Step2, Step3 } from '.'
import { FrameContainer, DecoImg } from './CheckInFrameItems'

function CheckInFrame() {
  const { step, stepContent, isNewCustomer } = useCheckIn()

  return (
    <FrameContainer isNewCustomer={isNewCustomer}>
      {step === stepContent.CHECK_IN ?
        <Step1 />
        : step === stepContent.CUS_INFO ?
          <Step2 />
          : step === stepContent.SUCCESS &&
          <Step3 />
      }

      <DecoImg src={DecoImg7} alt='' />
    </FrameContainer>
  )
}

export default CheckInFrame