import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    /* min-height: 870px; */
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1200px) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
`

export const DecorationImg1 = styled.img`
    width: auto;
    height: 300px;
    position: absolute;
    bottom: 10px;
    right: 25px;
    opacity: 0.6;

    @media screen and (max-width: 1100px) {
        opacity: 0.2;
    }
    @media screen and (max-width: 528px) {
        bottom: 800px;
    }
`
export const DecorationImg2 = styled.img`
    width: auto;
    height: 320px;
    position: absolute;
    top: 15px;
    left: 15px;
    opacity: 0.6;

    @media screen and (max-width: 528px) {
        opacity: 0.25;
        top: 330px;
    }
`
export const DecorationImg3 = styled.img`
    width: auto;
    height: 300px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    opacity: 0.2;

    @media screen and (max-width: 528px) {
        bottom: 265px;
    }
`
export const DecorationImg4 = styled.img`
    width: auto;
    height: 300px;
    position: absolute;
    top: -80px;
    right: 15px;

    @media screen and (max-width: 1200px) {
        opacity: 0.6;
    }
    @media screen and (max-width: 1100px) {
        opacity: 0.2;
    }
    @media screen and (max-width: 528px) {
        opacity: 0.5;
        top: -130px;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    /* min-height: 870px; */
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 3.375rem;
    padding-bottom: 3.75rem;
    position: relative;
`

export const Heading = styled.h1`
    color: #333;
    font-size: 45px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 10%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`

export const ListServices = styled.div`
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
`
export const ServiceCard = styled.div`
    width: 550px;
    display: flex;
    flex-flow: row nowrap;
    margin: 1rem 0;

    background-color: #FFC09F50;
    border-radius: 0.25rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    /* border: 1px solid lightskyblue; */
    color: #333;
    transition: all 0.3s ease-in-out;

    :hover {
        img {
            transform: scale(1.1);
            /* transform: translateX(-5%); */
            opacity: 0.8;
        }
        h2 {
            margin-left: 1.05rem;
            transform: scale(1.05);
        }
    }

    @media screen and (max-width: 1200px) {
        width: 500px;
    }
    @media screen and (max-width: 1100px) {
        width: 475px;
    }
    @media screen and (max-width: 1024px) {
        width: 440px;
    }
    @media screen and (max-width: 960px) {
        width: 240px;
        height: 270px;
        display: flex;
        flex-flow: column nowrap;
    }
    @media screen and (max-width: 768px) {
        &#service-3, &#service-4 {
            height: min-content;
        }
    }
`
export const ImgWrapper = styled.div`
    width: 250px;
    height: 155px;
    clip-path: polygon(0% 0%, 100% 0, 85% 100%, 0% 100%);
    /* overflow: hidden; */

    /* background: url(${props => props?.src}) no-repeat;
    background-size: cover;
    background-position: center; */

    /* border: 1px solid violet; */

    @media screen and (max-width: 960px) {
        width: 240px;
        overflow: hidden;
        clip-path: none;
    }
`
export const Img = styled.img`
    width: 250px;
    height: 155px;
    object-fit: cover;
    border-radius: 4px;
    /* border-radius: 16px 4px 4px 16px; */

    transform: scale(1);
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 960px) {
        width: 240px;
    }
`
export const TextWrapper = styled.div`
    margin: 1rem;
`
export const Name = styled.h2`
    margin-bottom: 0.6rem;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 960px) {
        font-size: 1.3rem;
    }
`
export const Description = styled.p`
    font-size: 1rem;
    color: black;
    font-weight: 500;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const BtnExplore = styled.button`
    color: #171416;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 30px;
    
    background: #FFC09F;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    overflow-x: hidden;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffcbaf;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        max-width: 480px;
    }
`