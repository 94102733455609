import styled from 'styled-components';
import { Button } from 'primereact/button';

export const Wrapper = styled.div`
    height: 100%;
    background-color: #F7F7F7;
    border-radius: 0px 6px 6px 0px;
`

export const Header = styled.div`
    height: 40px;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 0px 6px 0px 0px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.05);
`
export const Title = styled.h3`
    font-size: 0.9rem;
    text-transform: uppercase;
    text-align: right;
`

export const Body = styled.div`
    height: 550px;
    overflow-y: auto;
    padding: 12px 16px;
    /* border: 1px solid black; */
    
    display: flex;
    flex-direction: column;
`
export const Row = styled.div`
    width: 100%;
    padding: 0.5rem 0.5rem 0 0.5rem;
    display: grid;
    grid-template-columns: 7fr 5fr;

    /* border: 1px solid black; */
`
export const SubHeader = styled.h6`
    text-transform: uppercase;
    color: gray;
`
export const Content = styled.p`
    margin: 0.3rem 0 0.25rem;
`
export const ServicesTable = styled.div`
    width: 100%;
    height: 100%;
    padding: 0.5rem;

    /* border: 1px solid yellow; */
`
export const ServiceRow = styled.div`
    padding: 0.25rem 0 0.5rem 0.5rem;
    border-bottom: 1px solid lightgray;
`
export const ServiceInfo = styled.div`
    display: grid;
    grid-template-columns: 11fr 1fr;
`
export const ServiceName = styled.p`
    margin-top: ${({ serviceId, standAlone }) => (![23, 24].includes(serviceId) && standAlone) ? '0.9rem' : '0.5rem'};
`
export const ServiceFlavor = styled.p`
    /* font-weight: 600; */
    font-style: italic;
    font-size: 0.8rem;
    margin-top: 3px;
`
export const RemoveBtn = styled(Button)`
    margin-top: 0.5rem;
`