import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Header, HomeNavbar, Sidebar, Footer, CheckInModal } from '../../components';
import { IntroSection, AboutUsSection, WhyChooseUsSection, ServicesSection, TestimonialSection, GallerySection, TechniciansSection, ContactSection } from './components';  // InfoSection
import { ScrollTop } from 'primereact/scrolltop';
// import { aboutSection, serviceSection, contactSection } from './components/InfoSection/Data';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useQuery } from '../../hooks/useQuery'

const Home = () => {
    const [query, setQuery] = useQuery()

    const [showSidebar, setShowSidebar] = useState(false);
    const [showMdBooking, setShowMdBooking] = useState(false);
    const [showMdCheckIn, setShowMdCheckIn] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 1200 })
    }, [])

    useEffect(() => {
        if (query.get('checkin') === 'true' && !showMdCheckIn)
            setShowMdCheckIn(true)
    }, [query])

    useEffect(() => {
        if (showMdCheckIn)
            setQuery({ 'checkin': true })
        else
            setQuery({})
    }, [showMdCheckIn])

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="Best nails spa in Coppell, Texas | Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and many more services! Contact us at (972)304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019" />
                <meta name="keywords" content="Deluxe, Nails, Spa, Beauty, Nails Salon, Coppell, TX 75019, Texas, Pedicures, Manicure, Acrylic, Gel, Waxing, Neighborhood Favorite, qualified technicians" />
                <link rel="canonical" href="https://deluxenailsspacoppell.com/" />
            </Helmet>

            <Header />
            <HomeNavbar
                showModal={showMdBooking}
                setShowModal={setShowMdBooking}
                setShowSidebar={setShowSidebar}
            />
            <Sidebar
                setShowModal={setShowMdBooking}
                setShowMdCheckIn={setShowMdCheckIn}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />

            <IntroSection
                setShowModal={setShowMdBooking}
                setShowMdCheckIn={setShowMdCheckIn}
            />
            {/* <InfoSection {...aboutSection} />
            <InfoSection {...serviceSection} />
            <InfoSection {...contactSection} /> */}
            <main>
                <section id='about'>
                    <AboutUsSection />
                    <WhyChooseUsSection />
                </section>
                <ServicesSection />
                <TechniciansSection />
                <GallerySection />
                <TestimonialSection />
                <ContactSection />
            </main>
            <Footer />

            <ScrollTop />

            <CheckInModal open={showMdCheckIn} setOpen={setShowMdCheckIn} />
        </>
    );
}

export default Home;
