import React, { useEffect } from 'react'
import Layout from '../../layout/Layout';
import { BodyWrapper, Img, ErrorMessage } from './NotFoundItems'
import { NotFoundImg } from '../../assets/images'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NotFound() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>404 Not Found | Deluxe Nails & Spa | Coppell, TX 75019</title>
                <meta name="description" content="404 Page Not Found | Deluxe Nails & Spa | (972) 304-1199 | 143 S Denton Tap Rd Ste 170, Coppell, TX 75019 | Services: Pedicures, Manicure, Nails-Acrylic, Gel, Waxing, Kids and more!" />
                {/* <link rel="canonical" href="https://deluxenailsspacoppell.com/" /> */}
            </Helmet>

            <Layout isNotFound={true}>
                <BodyWrapper>
                    <Img src={NotFoundImg} alt="404 not found" />
                    <div style={{ textAlign: 'center' }}>
                        <ErrorMessage>Oops! <br />This page can not be found...</ErrorMessage>
                        <Link to='/'>Back to Home page?</Link>
                    </div>
                </BodyWrapper>
            </Layout>
        </>
    )
}

export default NotFound
