import styled from 'styled-components'

export const BodyWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 0;

    @media screen and (max-width: 1200px) {
        padding-right: 1.5rem;
        /* padding-left: 1.5rem; */
    }
    @media screen and (max-width: 900px) {
        padding-left: 1.5rem;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
`