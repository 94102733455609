import axios from 'axios';
import queryString from 'query-string'

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,  // Sao nó ko lấy đc cái này nhỉ???
    // baseURL: 'https://api.deluxenailsspacoppell.com/deluxenails-be-0.0.1-SNAPSHOT',
    // 'http://160.251.76.80:8080/deluxenails-be-0.0.1-SNAPSHOT/',
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    paramsSerializer: params => queryString.stringify(params)
    // VD như khi search/filters có những params thì nó sẽ do thằng queryString này handle 
    // để tránh 1 số issues nho nhỏ khi null hoặc empty.
    // Cái này nó sẽ tự đẩy params của mình lên URL.
});

axiosClient.interceptors.request.use(async (config) => {
    // Handle token here (if any)
    return config;
});

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        // console.log('response.data -- ', response.data);
        return response.data
    }
    // console.log('nodata -- ', response);
    return response;
}, (error) => {
    // Handle errors
    throw error;
});

export default axiosClient;