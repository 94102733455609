import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ showSidebar }) => (showSidebar ? '99%' : '0')};
    top: ${({ showSidebar }) => (showSidebar ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;

    &:hover {
        color: #FFC09F;
    }
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color: #FFC09F;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 66px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(7, 55px);
    }
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #FFC09F;     // đây sẽ là chỗ mình đổi mã màu
        transition: 0.2s ease-in-out;
        font-size: 1.75rem;
        font-weight: 600;
    }

    @media screen and (max-width: 576px) {
        font-size: 1.35rem;
        &:hover {
            font-size: 1.6rem;
        }
    }
    @media screen and (max-width: 440px) {
        font-size: 1.2rem;
        &:hover {
            font-size: 1.45rem;
        }
    }
`

export const SidebarBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // font-stretch: 200%;  // ko chạy ta?

    @media screen and (max-width: 400px) {
        flex-flow: column nowrap;
    }
`

export const CheckInBtn = styled.button`
    border: 2px solid #FFC09F;
    border-radius: 50px;
    color: #FFC09F;
    background: none;
    /* background: #F64A5F;  // #01bf71 */
    white-space: nowrap;
    padding: 16px 48px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    outline: none;
    /* border: none; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #171416;
        background: #ffb994df;
        /* border: 2px solid #fff; */
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        /* box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px; */
    }

    @media screen and (max-width: 576px) {
        font-size: 18px;
        padding: 14px 40px;
    }
    @media screen and (max-width: 440px) {
        font-size: 16px;
        padding: 12px 30px;
    }
    @media screen and (max-width: 400px) {
        margin-bottom: 20px;
    }
`

export const BookingBtn = styled.button`
    margin-left: 2rem;

    border: 2px solid #FFC09F;
    border-radius: 50px;
    color: #171416;
    background: #FFC09F;
    /* background: #F64A5F;  // #01bf71 */
    white-space: nowrap;
    padding: 16px 48px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    outline: none;
    /* border: none; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffb994df;
        color: #171416;
        opacity: 0.8;
        /* border: 2px solid #fff; */
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        /* box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px; */
    }

    @media screen and (max-width: 576px) {
        font-size: 18px;
        padding: 14px 40px;
    }
    @media screen and (max-width: 440px) {
        font-size: 16px;
        padding: 12px 30px;
    }
    @media screen and (max-width: 400px) {
        margin-left: 0;
    }
`