import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 620px;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        background: url(/assets/images/technician-bg.svg) no-repeat;
        background-size: cover;
        background-position: center;
        /* background-attachment: fixed; */

        position: absolute;
        top: 0;
        left: 0;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    min-height: 620px;
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 3.375rem;
    position: relative;
`

export const Heading = styled.h1`
    color: #333;
    font-size: 45px;
    font-family: 'BlackberryJam', 'Destain', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;

    padding-bottom: 1rem;
    position: relative;

    &::after {
        content: "";
        background: #FFC09F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 10%;
    }

    @media screen and (max-width: 768px) {
        font-size: 38px;
        /* max-width: 480px; */
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        /* max-width: 480px; */
        /* border: 1px solid black; */
    }
`

export const Row = styled.div`
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    overflow: hidden;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1200px) {
        padding: 0 1rem;
    }
`
export const TechnicianCard = styled.div`
    width: 300px;
    height: 330px;
    position: relative;
    /* border: 1px solid lightseagreen; */
`
export const InfoWrapper = styled.div`
    width: 300px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    left: 0;
`